import { FC } from 'react'
import { Field } from '../components/Field'
import { Layout } from '../components/Layout'
import { Selection } from '../components/Selection'
import { useSettingsOutletContext } from './Settings'
import { useTranslation } from 'react-i18next'
import { Select } from 'components/form/select'
import { SUPPORTED_LANGUAGES } from '@utils/language'

// TODO: Re-enable browser-defined language
// import { determineBrowserLanguage } from 'utils/language'

type LanguageValues = typeof SUPPORTED_LANGUAGES[number] | 'browser-defined'
type LanguageOption = [LanguageValues, string]

export const Global: FC = () => {
  const { changeValues, dirty, pending, values, save } =
    useSettingsOutletContext()
  const { t } = useTranslation()

  const getLabelForLanguage = (language: LanguageValues) => {
    if (language === 'fr') return t('features.settings.global.canadianFrench')
    if (language === 'en') return t('features.settings.global.english')
    if (language === 'ko') return t('features.settings.global.korean')
    if (language === 'de') return t('features.settings.global.german')

    throw new Error(
      `Unreachable.  Language can only be one of ${SUPPORTED_LANGUAGES.join(
        ', ',
      )}`,
    )
  }

  const languageOptions: LanguageOption[] = [
    // TODO: Re-enable browser-defined language for blank option once browser setting support is reenabled in useLanguage
    // [
    //   'browser-defined',
    //   t('features.settings.global.browserDefined', {
    //     translatedLanguageName: getLabelForLanguage(
    //       determineBrowserLanguage() as LanguageValues,
    //     ),
    //   }),
    // ],
    ['browser-defined', ''],
    ...SUPPORTED_LANGUAGES.map<LanguageOption>((language) => [
      language,
      getLabelForLanguage(language),
    ]),
  ]

  return (
    <Layout
      title={t('features.settings.global.globalPreferences')}
      subtitle={t(
        'features.settings.global.theseSettingsAreAppliedAcrossAllPagesAndTools',
      )}
      onSaveButtonClick={save}
      saveButtonDisabled={!dirty || pending}
    >
      <Field
        description={t(
          'features.settings.global.selectHowYouWouldLikeTimeToBeFormatted',
        )}
        label={t('features.settings.global.timeFormat')}
      >
        <Selection
          checked={values.timeFormat === '12_hour'}
          disabled={pending}
          label={t('features.settings.global.12Hour')}
          onChange={() => changeValues({ timeFormat: '12_hour' })}
        />
        <Selection
          checked={values.timeFormat === '24_hour'}
          disabled={pending}
          label={t('features.settings.global.24Hour')}
          onChange={() => changeValues({ timeFormat: '24_hour' })}
        />
      </Field>
      <Field
        description={t('features.settings.global.selectYourLanguage')}
        label={t('features.settings.global.language')}
      >
        <Select
          className="max-w-[200px]"
          value={values.languageCode ?? 'browser-defined'}
          onChange={(event) => {
            const value = event.currentTarget.value as LanguageValues
            const languageCode = value === 'browser-defined' ? null : value
            changeValues({ languageCode })
          }}
        >
          {languageOptions.map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Field>
    </Layout>
  )
}
