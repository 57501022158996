import classNames from 'classnames'

interface Props {
  name: string | undefined
  pipelineStepName: string | undefined
  shotgridEntityName: string | undefined
}

function TaskCell(props: Props) {
  return (
    <div className="grow grid grid-cols-3 3xl:grid-cols-4 gap-2 break-words">
      {[props.shotgridEntityName, props.pipelineStepName, props.name].map(
        (label, index) => (
          <div
            className={classNames(
              'text-xs 3xl:text-sm leading-none text-neutral-900 last:font-bold',
              { '3xl:col-span-2': index === 2 },
            )}
            key={`${props.name || ''}-${index}`}
          >
            {label}
          </div>
        ),
      )}
    </div>
  )
}

export default TaskCell
