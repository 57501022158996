import { FC, useState } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row,
  Getter,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowUpAZ,
  faChain,
  faChainBroken,
} from '@fortawesome/pro-regular-svg-icons'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { Label } from 'components/indicators/label'
import classNames from 'classnames'
import { secondsToHHMM } from '@utils/time'
import { AutoApprovalToggle } from '../components/AutoApprovalToggle'
import { IconButton } from 'components/buttons'
import { useAssignApprovers } from '../hooks/useAssignApprovers'
import { TableFilterField } from 'components/tables/TableFilterField'
import { useTranslation } from 'react-i18next'
import { DownloadCsvButton } from 'components/tables'

interface Props {
  projects: AdminProject[]
}

const columnHelper = createColumnHelper<AdminProject>()

export const Table: FC<Props> = ({ projects }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { assignApprovers } = useAssignApprovers()

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.projectsAdmin.projectName'),
      filterFn: 'includesString',
    }),
    columnHelper.accessor(
      (row) => (row.workdayProject ? 'linked' : 'unlinked'),
      {
        id: 'status',
        header: t('features.projectsAdmin.status'),
        filterFn: 'equalsString',
        cell: ({ getValue }) =>
          getValue() === 'linked' ? (
            <Label color="success" icon={faChain} className="w-24">
              {t('features.projectsAdmin.linked')}
            </Label>
          ) : (
            <Label
              color="error"
              icon={faChainBroken}
              className="w-24"
              borderShade={200}
            >
              {t('features.projectsAdmin.unlinked')}
            </Label>
          ),
      },
    ),
    columnHelper.accessor((row) => row.id.toString(), {
      header: t('features.projectsAdmin.shotgridId'),
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.workdayProject?.projectid, {
      id: 'workdayId',
      header: t('features.projectsAdmin.workdayId'),
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.workdayProject?.startDate, {
      id: 'workdayStartDate',
      header: t('features.projectsAdmin.workdayStartDate'),
      cell: ({ getValue }) => getValue()?.toLocaleString(),
    }),
    columnHelper.accessor((row) => row.workdayProject?.endDate, {
      id: 'workdayEndDate',
      header: t('features.projectsAdmin.workdayEndDate'),
      cell: ({ getValue }) => getValue()?.toLocaleString(),
    }),
    columnHelper.accessor('totalSecondsLogged', {
      header: t('features.projectsAdmin.totalTimeLogged'),
      cell: ({ getValue }) => secondsToHHMM(getValue()),
    }),
    {
      id: 'approvers',
      header: t('common.approvers'),
      accessorFn: (row: AdminProject) =>
        row.approverUsers.map((user) => user.name).join(', '),
      enableColumnFilter: true,
      filterFn: 'includesString',
      cell: ({
        row,
        getValue,
      }: {
        row: Row<AdminProject>
        getValue: Getter<string>
      }) => (
        <div className="flex items-center justify-between">
          {getValue()}
          <IconButton
            title={
              t('features.projectsAdmin.manageApprovers', {
                name: row.original.name,
              }) ?? ''
            }
            icon={faEdit}
            className="invisible w-8 h-8 ml-auto border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => assignApprovers(row.original)}
          />
        </div>
      ),
    } as ColumnDef<AdminProject>,
    {
      id: 'archived',
      header: t('features.projectsAdmin.shotgridStatus'),
      accessorFn: (row: AdminProject) =>
        row.archived
          ? t('features.projectsAdmin.archived')
          : t('features.projectsAdmin.active'),
      filterFn: 'equalsString',
    } as ColumnDef<AdminProject>,
    {
      id: 'timeLoggerActive',
      header: t('features.projectsAdmin.shotgridUseTimeLogger'),
      accessorFn: (row: AdminProject) =>
        row.timeLoggerActive ? t('common.yes') : t('common.no'),
      filterFn: 'equalsString',
    } as ColumnDef<AdminProject>,
    {
      id: 'autoApproval',
      header: t('features.projectsAdmin.autoApproval'),
      accessorFn: (row: AdminProject) =>
        row.autoApproval
          ? t('features.projectsAdmin.enabled')
          : t('features.projectsAdmin.disabled'),
      cell: ({ row }: { row: Row<AdminProject> }) => (
        <AutoApprovalToggle project={row.original} />
      ),
      filterFn: 'equalsString',
    } as ColumnDef<AdminProject>,
  ]

  const table = useReactTable({
    data: projects,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  const unlinkedProjects = table
    .getRowModel()
    .rows.filter((row) => row.original.workdayProject === undefined)

  return (
    <div className="px-8 pt-6 flex flex-col flex-grow">
      <div className="flex justify-between mb-2">
        <div>
          <div className="text-lg">
            {t('features.projectsAdmin.projectCountAndTotal', {
              count: table.getRowModel().rows.length,
              total: projects.length,
            })}
          </div>
          <div className="my-1 text-xs text-neutral-600">
            {t('features.projectsAdmin.projectsWithWorkdayLinkingIssues', {
              count: unlinkedProjects.length,
            })}
          </div>
        </div>

        <div>
          <DownloadCsvButton table={table} filename={t('common.projects')} />
        </div>
      </div>

      <div className="relative flex-grow">
        <div className="absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <table className="text-sm min-w-full">
            <thead className="sticky top-0 shadow z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 px-4 py-6 font-semibold text-left border border-t-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300 hover:bg-neutral-200 first-of-type:border-l-0 last-of-type:border-r-0"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex justify-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.getIsSorted() && (
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'asc'
                                ? faArrowDownAZ
                                : faArrowUpAZ
                            }
                          />
                        )}
                      </div>

                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField
                            table={table}
                            column={header.column}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={classNames('group/row', {
                    'bg-error-50': row.getValue('status') === 'unlinked',
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-5 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
