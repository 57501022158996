import { FC } from 'react'
import { secondsToHHMM } from '@utils/time'
import { twMerge } from 'tailwind-merge'

interface Props {
  seconds: number
  className?: string
}

export const Total: FC<Props> = ({ seconds, className }) => {
  const total = seconds > 0 ? secondsToHHMM(seconds) : '-'

  return (
    <span className={twMerge('font-semibold text-neutral-900 grow', className)}>
      {total}
    </span>
  )
}
