import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  onClick: () => void
  expanded: boolean | (() => void)
  label: string
}

export function ToggleHeading(props: Props) {
  const textColor = props.expanded ? 'neutral-900' : 'neutral-500'

  return (
    <button
      className={`absolute flex flex-row items-center gap-2 pl-1.5 mb-3 text-${textColor} h-11`}
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={props.expanded ? faChevronDown : faChevronRight}
        className="w-3 text-sm"
      />
      <h2 className={`text-lg leading-none text-${textColor}`}>
        {props.label}
      </h2>
    </button>
  )
}
