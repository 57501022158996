import { dashedBorders } from '@utils/styles'
import { twMerge } from 'tailwind-merge'

interface SideCellProps {
  className?: string
  children?: React.ReactNode
  rowIndex: number
  highlight?: boolean
}

function SideCell(props: SideCellProps) {
  const highlight = props.highlight ? 'bg-primary-600' : ''
  const style = {
    gridRowStart: props.rowIndex,
  }

  return (
    <div
      className={twMerge(
        `flex flex-row items-center ${dashedBorders}`,
        props.className,
      )}
      style={style}
    >
      <div className={`h-full mr-1 w-[4px] ${highlight}`}>&nbsp;</div>
      {props.children}
    </div>
  )
}

export default SideCell
