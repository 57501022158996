import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface TabProps {
  active: boolean
  label: string
  onClick: () => void
  className?: string
}

const activeClassName = 'font-semibold border-primary-600'
const inactiveClassName = 'border-transparent'

export const Tab: FC<TabProps> = ({ active, label, onClick, className }) => (
  <button
    className={twMerge(
      'px-3 py-2 border-b-2 text-sm text-neutral-800',
      active ? activeClassName : inactiveClassName,
      className,
    )}
    onClick={onClick}
  >
    {label}
  </button>
)

interface TabType {
  id: string
  label: string
}

export interface Props<T extends TabType> {
  className?: string
  tabs: readonly T[]
  activeTab: Props<T>['tabs'][number]['id']
  onTabClick: (tab: Props<T>['activeTab']) => void
  variant?: 'text-width-tabs' | 'full-width-tabs'
}

export const Tabs = <T extends TabType>({
  className,
  tabs,
  activeTab,
  onTabClick,
  variant = 'text-width-tabs',
}: Props<T>) => {
  return (
    <div
      className={twMerge(
        'flex flex-row border-b border-neutral-300',
        variant === 'text-width-tabs' && 'px-8',
        className,
      )}
    >
      {tabs.map(({ id, label }) => (
        <Tab
          key={id}
          active={activeTab === id}
          label={label}
          onClick={() => onTabClick(id)}
          className={variant === 'full-width-tabs' ? 'flex-grow' : ''}
        />
      ))}
    </div>
  )
}
