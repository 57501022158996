import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Item, SkeletonItem } from '../../components/Item'
import { Column } from './Column'
import { ColumnFilter } from './types'
import { pathForItem } from './utils/pathForItem'

interface Props {
  disabledTaskIds: Set<number>
  filter: ColumnFilter
  hidden: boolean
  onTaskClick: (id: number, checked: boolean) => void
  selectedTaskIds: Set<number>
  showPaths: boolean
}

export const TaskColumn: FC<Props> = ({
  disabledTaskIds,
  filter,
  hidden,
  onTaskClick,
  selectedTaskIds,
  showPaths,
}) => {
  const { t } = useTranslation()
  return (
    <Column
      filter={filter}
      hidden={hidden}
      hideLabel={false}
      label={t('features.timeLogging.taskSelection.tasks')}
      renderItem={(item) => {
        if (item.id === undefined) return null

        return (
          <Item
            className="mx-9"
            key={`${item.id ?? ''}-${item.label}`}
            item={{
              checked: selectedTaskIds.has(item.id),
              disabled: disabledTaskIds.has(item.id),
              id: item.id,
              label: item.label,
              secondaryLabel: item.id?.toString(),
            }}
            onItemClick={onTaskClick}
            path={showPaths ? pathForItem(item) : undefined}
          />
        )
      }}
      skeletonItem={<SkeletonItem />}
      expectedItemHeight={44}
      showing="task"
    />
  )
}
