import { FC, useState, useRef, ReactNode } from 'react'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from './Button'
import { twMerge } from 'tailwind-merge'
import { useOnClickOutside } from '@hooks/useOnClickOutside'

interface Props {
  label: string
  children: ReactNode
}

export const InfoDropdownButton: FC<Props> = ({ label, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const ref = useRef(null)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  useOnClickOutside(ref, closeMenu)

  return (
    <div ref={ref} className="relative inline-block">
      <Button
        variant="outlined"
        className={twMerge(
          'group text-xs py-0.5 px-2.5 h-auto border-[1.5px] border-neutral-600 font-bold',
          isMenuOpen && 'border-primary-700 bg-primary-200 text-primary-600',
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {label}
        <FontAwesomeIcon
          icon={faChevronDown}
          className={twMerge('group-hover:inline', !isMenuOpen && 'hidden')}
        />
      </Button>

      {isMenuOpen && (
        <div className="absolute z-10 right-0 top-[120%] bg-white border rounded-lg overflow-clip drop-shadow-lg border-neutral-300">
          {children}
        </div>
      )}
    </div>
  )
}
