import { generateTaskLabels } from '../allocation-tables/taskLabels'
import SideCell from '../table/cells/SideCell'
import SideColumn from '../table/SideColumn'
import TaskCell from './cells/TaskCell'

interface Props {
  hoverY: number
  tasks: Task[]
}

export function TaskNameColumn(props: Props) {
  const tasks = props.tasks
  const taskLabels = generateTaskLabels(props.tasks)
  const findTaskLabel = (taskId: number) => {
    return taskLabels.find((t) => t.taskId === taskId)
  }

  return (
    <SideColumn side="left">
      {tasks.map((task, index) => (
        <SideCell
          key={`LeftColumnCell${task.id}`}
          rowIndex={index + 1}
          highlight={index + 1 == props.hoverY}
        >
          <TaskCell
            key={`task_cell_${task.id}`}
            shotgridEntityName={findTaskLabel(task.id)?.shotgridEntityName}
            pipelineStepName={findTaskLabel(task.id)?.pipelineStepName}
            name={findTaskLabel(task.id)?.name}
          />
        </SideCell>
      ))}
    </SideColumn>
  )
}
