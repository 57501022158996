import { faCalendarClock } from '@fortawesome/pro-regular-svg-icons'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table'
import { coerceIntoArray } from '@utils/coerceIntoArray'
import {
  ContextMenu,
  ImperativeHandle as ContextMenuImperativeHandle,
} from 'components/menus'
import {
  ScrollableTable,
  Td,
  Th,
  Tr,
  DayHeaderCell,
  TimeStampCell,
  EmployeeCell,
} from 'components/tables'
import { SearchQuery, ValuePicker } from 'components/tables/column-filters'
import { DateTime } from 'luxon'
import {
  forwardRef,
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { Status } from 'types'
import { isSelected } from '../hooks/useSelectedCells'
import {
  DayCellValue,
  LocksDictionary,
  SelectedById,
  SelectedEmployeeValues,
  SelectedPayloadItem,
  TimeCardTimeTotals,
  WorkerAndTimeCards,
} from '../types'
import { getAllEmployeeIdsAndTimeCardsForDate } from '../utils/getAllEmployeeIdsAndTimeCardsForDate'
import { getTimeCardsWithinDateRange } from '../utils/getTimeCardsWithinDateRange'
import { getTotalsFromTimeCards } from '../utils/getTotalsFromTimeCards'
import { DayCell } from './DayCell'
import { timeLoggerRouteBuilder } from '@utils/timeLoggerRouteBuilder'
import { Spinner } from 'components/loaders'
import { EmployeeCountFooterCell } from './EmployeeCountFooterCell'
import {
  useKeyboardForGrid,
  NULL_CELL,
  isSameCell,
} from '@hooks/useKeyboardForGrid'

export interface ImperativeHandle {
  select: (status: Status) => void
  unSelect: (status: Status) => void
}

const columnHelper = createColumnHelper<WorkerAndTimeCards>()

interface Props {
  employeesAndTimeCards: WorkerAndTimeCards[]
  hiddenDays: Set<number>
  locks: LocksDictionary
  onHideDays: (weekDays: number | number[]) => void
  onSelect: (items: SelectedPayloadItem[]) => void
  onShowDays: (weekDays: number | number[]) => void
  onUnSelect: (items: SelectedPayloadItem[]) => void
  onUnSelectAll: () => void
  selectedCells: SelectedById
  weekRange: DateTime[]
  onEmployeeNameFilterChange: (value: string) => void
  employeeNameFilter: string
  onFacilityFilterChange: (values: Set<number>) => void
  filteredFacilityIds: Set<number>
  onApprovalGroupFilterChange: (values: Set<number>) => void
  filteredApprovalGroupIds: Set<number>
  employeesAndTimeCardsBeforeTableFiltering: WorkerAndTimeCards[]
  loading?: boolean
  employeeSidebarOpened: boolean
  onCellFocusChange: (
    workerAndTimeCards?: WorkerAndTimeCards,
    date?: DateTime,
  ) => void
}

export const Table = memo(
  forwardRef<ImperativeHandle, Props>(function Table(
    {
      employeesAndTimeCards,
      hiddenDays,
      locks,
      onHideDays,
      onSelect,
      onShowDays,
      onUnSelect,
      onUnSelectAll,
      selectedCells,
      weekRange,
      onEmployeeNameFilterChange,
      employeeNameFilter,
      onFacilityFilterChange,
      filteredFacilityIds,
      onApprovalGroupFilterChange,
      filteredApprovalGroupIds,
      employeesAndTimeCardsBeforeTableFiltering,
      loading,
      employeeSidebarOpened,
      onCellFocusChange,
    },
    ref,
  ) {
    const { t } = useTranslation()
    const contextMenuRef = useRef<ContextMenuImperativeHandle>(null)
    const [sorting, setSorting] = useState<SortingState>([
      {
        id: 'employeeName',
        desc: false,
      },
    ])

    const openContextMenu = (pos: MousePosition, cb: () => void) => {
      contextMenuRef.current?.open({
        pos,
        item: {
          label: t('common.viewDetails'),
          icon: faCalendarClock,
          onClick: cb,
        },
      })
    }

    const selectionCountsByDay = Object.values(selectedCells).reduce<{
      [dayMillis: number]: number
    }>((acc, selected: SelectedEmployeeValues) => {
      const { dates: selectedDates } = selected

      const counts = { ...acc }
      weekRange.forEach((day) => {
        const dayEpoch = day.toMillis()
        if (!selectedDates.has(dayEpoch)) return

        const count = counts[dayEpoch] ?? 0
        counts[dayEpoch] = count + 1
      })

      return counts
    }, {})

    const columns = [
      columnHelper.accessor((row) => row.worker.fullName, {
        id: 'employeeName',
        header: t('common.employeeName'),
        cell: ({ row }) => {
          const employeeIsSelected = isSelected(
            selectedCells,
            row.original.worker.workdayWorkerId,
          )

          const someSelected = weekRange.some((day) => employeeIsSelected(day))
          const allSelected = weekRange.every((day) => employeeIsSelected(day))

          const handleChange = () => {
            const timeCards = getTimeCardsWithinDateRange(
              row.original.timeCards,
              weekRange,
            )

            const operation = allSelected ? onUnSelect : onSelect
            operation([
              {
                employeeId: row.original.worker.workdayWorkerId,
                dates: weekRange,
                timeCards,
              },
            ])
          }

          return (
            <EmployeeCell
              checked={allSelected}
              contigentWorkerType={row.original.worker.contingentWorkerType}
              indeterminate={someSelected}
              linkPath={timeLoggerRouteBuilder(
                row.original.worker.user.id,
                weekRange[0],
              )}
              name={row.original.worker.fullName}
              onChange={handleChange}
              payType={row.original.worker.payType}
              title={row.original.worker.jobTitle}
            />
          )
        },
        footer: () => {
          const count = employeesAndTimeCards.length ?? 0
          const total = employeesAndTimeCardsBeforeTableFiltering.length
          return <EmployeeCountFooterCell count={count} total={total} />
        },
      }),
      columnHelper.accessor('worker.facility.name', {
        id: 'facilityName',
        header: t('common.facility'),
        cell: ({ getValue }) => <span className="px-4 py-3">{getValue()}</span>,
        footer: () => {
          return (
            <span className="px-3 whitespace-nowrap">
              {t('common.facilityCount', {
                count: facilitiesAfterFiltering.length,
              })}
            </span>
          )
        },
      }),
      columnHelper.accessor('worker.approvalGroup.name', {
        id: 'approvalGroupName',
        header: t('common.approvalGroup'),
        cell: ({ getValue }) => <span className="px-4 py-3">{getValue()}</span>,
        footer: () => {
          return (
            <span className="px-3 whitespace-nowrap">
              {t('features.admin.approvalGroupCount', {
                count: approvalGroupsAfterFiltering.length,
              })}
            </span>
          )
        },
      }),
      ...weekRange.map((day) =>
        columnHelper.accessor(
          (row): DayCellValue => {
            const timeCard = row.timeCards.find((timeCard) =>
              timeCard.date.hasSame(day, 'day'),
            )

            return { day, timeCard }
          },
          {
            id: `date-${day.toString()}`,
            header: () => {
              return (
                <DayHeaderCell
                  allSelected={(date) =>
                    selectionCountsByDay[date.toMillis()] ===
                    employeesAndTimeCards.length
                  }
                  date={day}
                  hiddenDays={hiddenDays}
                  onHide={(weekday) => onHideDays(weekday)}
                  onSelectAll={(date) => {
                    const items = getAllEmployeeIdsAndTimeCardsForDate(
                      employeesAndTimeCards,
                      date,
                    ).map(([employeeId, timeCard]) => ({
                      employeeId,
                      dates: [date],
                      timeCards: coerceIntoArray(timeCard),
                    }))
                    onSelect(items)
                  }}
                  onShow={(weekday) => onShowDays(weekday)}
                  onUnSelectAll={(date) => {
                    const items = getAllEmployeeIdsAndTimeCardsForDate(
                      employeesAndTimeCards,
                      date,
                    ).map(([employeeId, timeCard]) => ({
                      employeeId,
                      dates: [date],
                      timeCards: coerceIntoArray(timeCard),
                    }))
                    onUnSelect(items)
                  }}
                />
              )
            },
            enableSorting: false,
            cell: ({ getValue, row }) => {
              const { day, timeCard } = getValue()

              return (
                <DayCell
                  date={day}
                  holidayCalendarId={
                    row.original.worker.holidayCalendarId || undefined
                  }
                  lockType={
                    locks[row.original.worker.workdayWorkerId]?.[day.toMillis()]
                  }
                  timeCard={timeCard}
                  userId={row.original.worker.user.id}
                >
                  {timeCard && (
                    <TimeStampCell
                      seconds={timeCard.totalSecondsLogged}
                      subSeconds={timeCard.totalSecondsTagged}
                      zeroMode="dash"
                    />
                  )}
                </DayCell>
              )
            },
            footer: ({ table, column }) => {
              const timeCards = table
                .getRowModel()
                .flatRows.map(
                  (row) => row.getValue<DayCellValue>(column.id).timeCard,
                )

              const { total, tagged } = getTotalsFromTimeCards(timeCards)

              return (
                <TimeStampCell
                  bolden={true}
                  seconds={total}
                  subSeconds={tagged}
                />
              )
            },
          },
        ),
      ),
      columnHelper.accessor((row) => getTotalsFromTimeCards(row.timeCards), {
        id: 'rowTotal',
        header: t('common.total'),
        cell: ({ getValue }) => {
          const { total, tagged } = getValue()

          return (
            <TimeStampCell
              className="bg-neutral-100"
              bolden={true}
              seconds={total}
              subSeconds={tagged}
            />
          )
        },
        footer: ({ table, column }) => {
          const { total, tagged } = table
            .getRowModel()
            .flatRows.reduce<TimeCardTimeTotals>(
              (totals, row) => {
                const { total, tagged } = row.getValue<TimeCardTimeTotals>(
                  column.id,
                )
                return {
                  total: totals.total + total,
                  tagged: totals.tagged + tagged,
                }
              },
              { total: 0, tagged: 0 },
            )

          return (
            <TimeStampCell bolden={true} seconds={total} subSeconds={tagged} />
          )
        },
        sortingFn: (rowA, rowB, columnId) => {
          const aTotals = rowA.getValue<TimeCardTimeTotals>(columnId)
          const bTotals = rowB.getValue<TimeCardTimeTotals>(columnId)
          const a = aTotals.total + aTotals.tagged
          const b = bTotals.total + bTotals.tagged

          if (a < b) return -1
          if (a > b) return 1

          return 0
        },
      }),
    ]

    const table = useReactTable({
      debugAll: false,
      data: employeesAndTimeCards,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: { sorting },
      onSortingChange: setSorting,
    })

    const selectAllCells = () => {
      const items: SelectedPayloadItem[] = employeesAndTimeCards.map(
        ({ worker, timeCards }) => {
          return {
            employeeId: worker.workdayWorkerId,
            dates: weekRange,
            timeCards,
          }
        },
      )

      onSelect(items)
    }

    const { selectAllTimeCardsWithStatus, unSelectAllTimeCardsWithStatus } =
      useMemo(() => {
        const generateSelectionFn =
          (cb: (items: SelectedPayloadItem[]) => void) => (status: Status) => {
            const operations = employeesAndTimeCards.reduce<
              SelectedPayloadItem[]
            >((acc, { worker, timeCards }) => {
              const timeCardsWithStatus = timeCards.filter(
                (timeCard) => timeCard.status === status,
              )

              if (timeCardsWithStatus.length === 0) return acc

              return [
                ...acc,
                {
                  employeeId: worker.workdayWorkerId,
                  dates: timeCardsWithStatus.map((timeCard) => timeCard.date),
                  timeCards: timeCardsWithStatus,
                },
              ]
            }, [])

            cb(operations)
          }

        return {
          selectAllTimeCardsWithStatus: generateSelectionFn(onSelect),
          unSelectAllTimeCardsWithStatus: generateSelectionFn(onUnSelect),
        }
      }, [employeesAndTimeCards, onSelect, onUnSelect])

    useImperativeHandle(
      ref,
      () => ({
        select: (status: Status) => selectAllTimeCardsWithStatus(status),
        unSelect: (status: Status) => unSelectAllTimeCardsWithStatus(status),
      }),
      [selectAllTimeCardsWithStatus, unSelectAllTimeCardsWithStatus],
    )

    const allEmployeesSelected =
      employeesAndTimeCards.length > 0 &&
      employeesAndTimeCards.length === Object.keys(selectedCells).length

    const someEmployeesSelected = Object.keys(selectedCells).length > 0

    const sortedFacilitiesBeforeFiltering = useMemo(
      () =>
        [
          ...new Map(
            employeesAndTimeCardsBeforeTableFiltering.map(({ worker }) => [
              worker.facility.id,
              worker.facility,
            ]),
          ).values(),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      [employeesAndTimeCardsBeforeTableFiltering],
    )

    const facilitiesAfterFiltering = useMemo(
      () => [
        ...new Map(
          employeesAndTimeCards.map(({ worker }) => [
            worker.facility.id,
            worker.facility,
          ]),
        ).values(),
      ],
      [employeesAndTimeCards],
    )

    const sortedApprovalGroupsBeforeFiltering = useMemo(
      () =>
        [
          ...new Map(
            employeesAndTimeCardsBeforeTableFiltering.map(({ worker }) => [
              worker.approvalGroup.id,
              worker.approvalGroup,
            ]),
          ).values(),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      [employeesAndTimeCardsBeforeTableFiltering],
    )

    const approvalGroupsAfterFiltering = useMemo(
      () => [
        ...new Map(
          employeesAndTimeCards.map(({ worker }) => [
            worker.approvalGroup.id,
            worker.approvalGroup,
          ]),
        ).values(),
      ],
      [employeesAndTimeCards],
    )

    const {
      focusCell,
      focusedCell,
      clearFocus: clearFocusedCell,
    } = useKeyboardForGrid(
      employeesAndTimeCards.length,
      weekRange.length,
      useMemo(
        () => ({
          enabled: employeeSidebarOpened,
        }),
        [employeeSidebarOpened],
      ),
    )

    const focusedRow = useMemo(() => {
      if (isSameCell(focusedCell, NULL_CELL)) return

      return table.getRowModel().rows[focusedCell.y]
    }, [focusedCell, table])

    const focusedDate = useMemo(() => {
      if (isSameCell(focusedCell, NULL_CELL)) return

      return weekRange[focusedCell.x]
    }, [focusedCell, weekRange])

    const getCellFromWorkerAndDate = useCallback(
      (worker: TWorker, date: DateTime): Cell => {
        const y = table
          .getRowModel()
          .rows.findIndex(
            (row) =>
              row.original.worker.workdayWorkerId === worker.workdayWorkerId,
          )

        const x = weekRange.findIndex((d) => d.hasSame(date, 'day'))

        if (y < 0 || x < 0) return NULL_CELL

        return { x, y }
      },
      [table, weekRange],
    )

    // When focused row/date changes, invoke onCellFocusChange with details of focused cell
    useEffect(() => {
      onCellFocusChange(focusedRow?.original, focusedDate)
    }, [focusedDate, focusedRow, onCellFocusChange])

    // Clear the focused cell when the sidebar is closed
    useEffect(() => {
      if (!employeeSidebarOpened) clearFocusedCell()
    }, [employeeSidebarOpened, clearFocusedCell])

    return (
      <>
        <ScrollableTable
          header={table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    wrapperClassName={twMerge(
                      header.id === 'employeeName' && 'w-60 2xl:w-72',
                    )}
                    key={header.id}
                    clickable={header.column.getCanSort()}
                    label={flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    onSelect={(selected) => {
                      if (!selected) {
                        onUnSelectAll()
                      } else {
                        selectAllCells()
                      }
                    }}
                    sortDir={header.column.getIsSorted()}
                    selectable={header.id === 'employeeName'}
                    selected={
                      allEmployeesSelected
                        ? true
                        : someEmployeesSelected
                        ? 'indeterminate'
                        : false
                    }
                  >
                    {header.id === 'employeeName' && (
                      <SearchQuery
                        columnNameTranslationKey="common.employeeName"
                        onChange={(value) =>
                          onEmployeeNameFilterChange(value || '')
                        }
                        query={employeeNameFilter}
                      />
                    )}
                    {header.id === 'facilityName' && (
                      <ValuePicker
                        accessor={(facility) => facility.id}
                        columnNameTranslationKey="common.facilityName"
                        onChange={onFacilityFilterChange}
                        values={sortedFacilitiesBeforeFiltering}
                        renderLabel={(facility) => facility.name}
                        selected={filteredFacilityIds}
                      />
                    )}
                    {header.id === 'approvalGroupName' && (
                      <ValuePicker
                        accessor={(approvalGroup) => approvalGroup.id}
                        columnNameTranslationKey="common.approvalGroup"
                        onChange={onApprovalGroupFilterChange}
                        renderLabel={(approvalGroup) => approvalGroup.name}
                        selected={filteredApprovalGroupIds}
                        values={sortedApprovalGroupsBeforeFiltering}
                        searchable={true}
                      />
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
          body={
            loading ? (
              <tr>
                <td colSpan={columns.length} className="p-6 text-center">
                  <Spinner className="text-2xl" />
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                const worker = row.original.worker
                const employeeId = row.original.worker.workdayWorkerId
                const employeeHasSelected = isSelected(
                  selectedCells,
                  employeeId,
                )

                return (
                  <Tr virtualized={true} estimatedHeight={64} key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      let onLeftClick:
                          | MouseEventHandler<HTMLTableCellElement>
                          | undefined,
                        onRightClick,
                        selected = false,
                        isFocused = false

                      const isDateColumn = cell.column.id.startsWith('date')

                      if (isDateColumn) {
                        const { day, timeCard } = cell.getValue<DayCellValue>()
                        selected = employeeHasSelected(day, timeCard?.id)

                        onLeftClick = (event) => {
                          if (event.getModifierState('Alt')) {
                            focusCell(getCellFromWorkerAndDate(worker, day))
                          } else {
                            const operation = selected ? onUnSelect : onSelect
                            operation([
                              {
                                employeeId,
                                dates: [day],
                                timeCards: coerceIntoArray(timeCard),
                              },
                            ])
                          }
                        }
                        onRightClick = (pos: MousePosition) => {
                          openContextMenu(pos, () => {
                            focusCell(getCellFromWorkerAndDate(worker, day))
                          })
                        }

                        isFocused =
                          employeeSidebarOpened &&
                          focusedRow !== undefined &&
                          focusedDate !== undefined &&
                          cell.row.id === focusedRow.id &&
                          day.hasSame(focusedDate, 'day')
                      }

                      return (
                        <Td
                          className={twMerge(
                            'h-16',
                            isDateColumn && 'p-0',

                            // Scroll margin top/bottom set to height of fixed header/footer
                            isFocused && 'scroll-mt-[86px] scroll-mb-[63px]',
                          )}
                          key={cell.id}
                          onClick={onLeftClick}
                          onRightClick={onRightClick}
                          selected={selected}
                          focused={isFocused}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })
            )
          }
          footer={table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <td
                  key={header.id}
                  className="bottom-0 font-normal text-left border-t border-r bg-neutral-100 border-neutral-300"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </td>
              ))}
            </Tr>
          ))}
        />
        <ContextMenu variant="imperative" ref={contextMenuRef} />
      </>
    )
  }),
)
