import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { noop } from '@utils/noop'
import { useDebouncedState } from '@hooks/useDebouncedState'
import { Column } from '../types'
import { validateSearch } from '../utils/validateSearch'
import { useTranslation } from 'react-i18next'

interface SearchState {
  current: { [key in Column]: string }
  debounced: { [key in Column]: string }
  errors: { [key in Column]: string | undefined }
  setSearch: (column: Column, search: string) => void
}

/* eslint camelcase: "off" */
const initialValue: SearchState = {
  current: {
    root: '',
    project: '',
    sequence: '',
    entity_type: '',
    shot: '',
    entity: '',
    step: '',
    task: '',
  },
  debounced: {
    root: '',
    project: '',
    sequence: '',
    entity_type: '',
    shot: '',
    entity: '',
    step: '',
    task: '',
  },
  errors: {
    root: undefined,
    project: undefined,
    sequence: undefined,
    entity_type: undefined,
    shot: undefined,
    entity: undefined,
    step: undefined,
    task: undefined,
  },
  setSearch: noop,
}
/* eslint-enable */

export const SearchStateContext = createContext(initialValue)

export const SearchStateProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<SearchState['errors']>(
    initialValue.errors,
  )

  const {
    state: current,
    debounced,
    setState,
  } = useDebouncedState(initialValue.current)

  const setSearch = useCallback(
    (column: Column, search: string) => {
      setState((state) => ({ ...state, [column]: search }))
      setErrors((errors) => ({
        ...errors,
        [column]: validateSearch(
          search,
          t(
            'features.timeLogging.taskSelection.enterCharacterMinimumToBeginSearch',
          ),
        ),
      }))
    },
    [setState, setErrors, t],
  )

  const value = useMemo(
    () => ({
      current,
      debounced,
      setSearch,
      errors,
    }),
    [current, debounced, setSearch, errors],
  )

  return (
    <SearchStateContext.Provider value={value}>
      {children}
    </SearchStateContext.Provider>
  )
}
