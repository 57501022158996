import { FC, useMemo, useState } from 'react'
import { Props as ItemProps } from '../../components/Item'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { RootColumn } from './root'
import { SequencesAndEntityTypesColumns } from './sequences-and-entity-types'
import {
  ColumnFilter,
  Column,
  StackItem,
  TaskSearchResultItemWithType,
} from './types'
import { NavColumn } from './NavColumn'
import { TaskColumn } from './TaskColumn'
import { SearchStateProvider } from './providers/SearchStateProvider'
import {
  annotateWithType,
  generateStackFromItem,
  getNextColumnFromItemType,
  popFromStack,
  pushToStack,
} from './utils/stack'
import { Crumb } from '../../types'
import { useTranslation } from 'react-i18next'

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: ItemProps['onItemClick']
  selectedTaskIds: Set<number>
}

export const AllTasks: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const [stack, setStack] = useState<StackItem[]>([])

  const popStack = (column?: Column) => setStack(popFromStack(column))
  const pushStack = (newItem: StackItem) => setStack(pushToStack(newItem))

  const setLocation = (
    column: Column,
    { viewingAll } = { viewingAll: false },
  ) => {
    popStack(column)
    pushStack({ column, viewingAll })
  }

  const pushItemToStack = (item: TaskSearchResultItemWithType) => {
    const column = item.type === 'project' ? 'root' : item.type
    pushStack({ label: item.label, id: item.id, column, type: item.entityType })
    pushStack({ column: getNextColumnFromItemType(item.type) })
  }

  const handleItemClick = (
    item: TaskSearchResultItemWithType,
    viewingAll?: boolean,
  ) => {
    const operation = viewingAll ? setStackFromItem : pushItemToStack
    operation(item)
  }

  const setStackFromItem = (item: TaskSearchResultItemWithType) => {
    setStack(generateStackFromItem(item))
  }

  const activeColumn = useMemo<Column>(
    () => stack.at(-1)?.column ?? 'root',
    [stack],
  )
  const viewingAllColumn = useMemo(
    () => stack.find((item) => item.viewingAll)?.column,
    [stack],
  )

  const filter: ColumnFilter = useMemo(() => {
    return stack.reduce<ColumnFilter>(
      (filter, { column, id, type, viewingAll }) => {
        const f = { ...filter }
        switch (column) {
          case 'root':
            f.projectId = id
            break
          case 'project':
            f.projectId = id
            break
          case 'sequence':
            f.sequenceId = id
            break
          case 'entity_type':
            f.entityType = type
            break
          case 'shot':
            f.entityType = 'Shot'
            f.entityId = id
            break
          case 'entity':
            f.entityId = id
            if (viewingAll) f.entityType = 'Asset'
            break
          case 'step':
            f.stepId = id
            break
        }
        return f
      },
      {},
    )
  }, [stack])

  const clearStack = () => setStack([])

  const crumbs = stack.reduce<Crumb[]>((acc, stackItem, index, stack) => {
    if (stackItem.label === undefined) return acc // anything without a label (the active item) shouldn't be displayed

    const type = stackItem.column === 'root' ? 'project' : stackItem.column
    const tooltip = annotateWithType(type, stackItem.label)

    const nextItem = stack.at(index + 1)
    const onClick =
      nextItem?.column && (nextItem?.label || nextItem.skipped) // items without labels signifies the active column, a little smelly, I know
        ? () => setLocation(nextItem.column)
        : undefined

    return [...acc, { label: stackItem.label, onClick, tooltip }]
  }, [])

  return (
    <SearchStateProvider>
      {!hidden && (
        <div className="flex flex-col h-full">
          <Breadcrumbs
            hidden={activeColumn === 'root'}
            crumbs={crumbs}
            onBackClick={popStack}
            onHomeClick={clearStack}
          />
          <RootColumn
            disabledTaskIds={disabledTaskIds}
            hidden={activeColumn !== 'root'}
            onTaskClick={onTaskClick}
            onProjectClick={pushItemToStack}
            onUnscopedResultItemClick={setStackFromItem}
            onViewAllClick={(column) =>
              setLocation(column, { viewingAll: true })
            }
            selectedTaskIds={selectedTaskIds}
          />
          <NavColumn
            filter={filter}
            hidden={activeColumn !== 'project'}
            label="projects"
            onItemClick={handleItemClick}
            showing="project"
          />
          <SequencesAndEntityTypesColumns
            activeTab={activeColumn === 'sequence' ? 'sequence' : 'entity_type'}
            filter={filter}
            hidden={
              !(activeColumn === 'sequence' || activeColumn === 'entity_type')
            }
            onItemClick={handleItemClick}
            onTabClick={(tab) => {
              const tabToRemove: Extract<Column, 'entity_type' | 'sequence'> =
                tab === 'sequence' ? 'entity_type' : 'sequence'

              setStack((prevStack) => {
                const stack = prevStack.filter(
                  (item) => item.column !== tabToRemove,
                )
                return pushToStack({ column: tab })(stack)
              })
            }}
            viewingAllTab={
              viewingAllColumn === 'sequence'
                ? 'sequence'
                : viewingAllColumn === 'entity_type'
                ? 'entity_type'
                : undefined
            }
          />
          <NavColumn
            filter={filter}
            hidden={activeColumn !== 'shot'}
            label={t('features.timeLogging.taskSelection.shots')}
            onItemClick={handleItemClick}
            viewingAll={viewingAllColumn === 'shot'}
            showing="shot"
          />
          <NavColumn
            filter={filter}
            hidden={activeColumn !== 'entity'}
            label={
              stack.find((i) => i.column === 'entity_type')?.label ?? 'entities'
            }
            onItemClick={handleItemClick}
            viewingAll={viewingAllColumn === 'entity'}
            showing="entity"
          />
          <NavColumn
            filter={filter}
            hidden={activeColumn !== 'step'}
            label={t('common.pipelineStep')}
            onItemClick={handleItemClick}
            showing="step"
          />
          <TaskColumn
            disabledTaskIds={disabledTaskIds}
            filter={filter}
            hidden={activeColumn !== 'task'}
            onTaskClick={onTaskClick}
            selectedTaskIds={selectedTaskIds}
            showPaths={viewingAllColumn === 'task'}
          />
        </div>
      )}
    </SearchStateProvider>
  )
}
