import SideColumn from '../table/SideColumn'
import TaskTotalCell from './cells/TaskTotalCell'

interface Props {
  tasks: Task[]
}

export function TaskTotalColumn(props: Props) {
  const tasks = props.tasks

  return (
    <SideColumn side="right">
      {tasks.map((task, index) => (
        <TaskTotalCell
          key={`TotalByTask${task.id}`}
          task={task}
          row={index + 1}
        />
      ))}
    </SideColumn>
  )
}
