import { useEmployees } from '@hooks/useEmployee'
import {
  useSearchDayLocks,
  useUpsertDayLocks,
  useDeleteDayLocks,
} from '../hooks/useDayLocks'
import { useSendToPayroll } from '../hooks/useSendToPayroll'
import { useSet } from '@hooks/useSet'
import { useAdminTimeCards } from '@hooks/useTimeCards'
import { useWeekDates } from '@hooks/useWeekDates'
import {
  Button,
  FilterWorkersWithVisibleItems,
  FilterWorkerssWithVisibleItemsCounts,
  FilterWorkerWithVisibleItemsButtonGroup,
  StatusMiniSilos,
} from 'components/buttons'
import { FC, useMemo, useRef, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DayLockType, Status, WorkdaySendStatus } from 'types'
import { AlertButtons } from '../components/AlertButtons'
import { Header } from '../components/Header'
import { Sidebar } from '../components/sidebar'
import { ImperativeHandle, Table } from '../components/Table'
import { useSelectedCells } from '../hooks/useSelectedCells'
import {
  FilterAlertType,
  FilterAlertTypeCount,
  LocksDictionary,
  SelectedEmployeeValues,
  StatusCounts,
  ViewingDetails,
  WorkerAndTimeCards,
} from '../types'
import { buildLockPayloadData } from '../utils/buildLockPayloadData'
import { timeCardsFromSelected } from '../utils/timeCardsFromSelected'
import { getStatusCount } from '../utils/getStatusCount'
import { isEmployee } from '@utils/workers'
import { useModal, usePendingModal } from '@hooks/useModal'
import { toast } from '@lib/toasts'
import { DayLockPayload } from '@lib/api'
import { useDebouncedState } from '@hooks/useDebouncedState'
import { useFiltering } from '../hooks/useFiltering'
import { WeekSelectorSubHeader } from 'components/page-header'
import { useSearchParams } from 'react-router-dom'
import { deserializeParams } from '../utils/paramSerialization'

const massageLocks = (locks: DayLock[] = []): LocksDictionary => {
  return locks.reduce<LocksDictionary>((acc, lock) => {
    return {
      ...acc,
      [lock.workdayWorkerId]: {
        ...acc[lock.workdayWorkerId],
        [lock.date.toMillis()]: lock.type,
      },
    }
  }, {})
}

export const SubmissionsAdmin: FC = () => {
  const { t } = useTranslation()
  const modal = useModal()
  const { open: openPendingModal } = usePendingModal()
  const [
    searchParams,
    // setSearchParams
  ] = useSearchParams()

  const {
    hiddenStatuses: initialHiddenStatuses,
    facilityIds: initialFacilityIds,
    approvalGroupIds: initialApprovalGroupIds,
    hiddenDays: initialHiddenDays,
    workerNameQuery: initialWorkerNameQuery,
    filterEmployeesWithVisibleTimeCards:
      initialFilterEmployeesWithVisibleTimeCards,
  } = deserializeParams(searchParams)

  const {
    start: selectedWeek,
    set: setSelectedWeek,
    range,
    end: endOfWeek,
  } = useWeekDates()
  const {
    clear: clearSelected,
    selected,
    select,
    unSelect,
  } = useSelectedCells()
  const {
    set: hiddenDays,
    add: hideDays,
    remove: showDays,
    clear: showAllDays,
  } = useSet<number>(initialHiddenDays)
  const {
    set: hiddenStatuses,
    add: hideStatus,
    remove: showStatus,
    clear: showAllStatuses,
  } = useSet<Status>(initialHiddenStatuses)
  const [employeeSidebarOpened, setEmployeeSidebarOpened] = useState(false)
  const [viewingDetails, setViewDetails] = useState<ViewingDetails>()
  const [
    filterEmployeesWithVisibleTimeCards,
    setFilterEmployeesWithVisibleTimeCards,
  ] = useState<FilterWorkersWithVisibleItems>(
    initialFilterEmployeesWithVisibleTimeCards ?? 'all',
  )
  const [filteredAlertTypes, setFilteredAlertTypes] = useState<
    Set<FilterAlertType>
  >(new Set())
  const {
    state: employeeNameFilter,
    debounced: debouncedEmployeeNameFilter,
    setState: setEmployeeNameFilter,
  } = useDebouncedState<string>(initialWorkerNameQuery)

  const {
    state: filteredFacilityIds,
    debounced: debouncedFilteredFacilityIds,
    setState: setFilteredFacilityIds,
  } = useDebouncedState<Set<number>>(initialFacilityIds)

  const [filteredApprovalGroupIds, setFilteredApprovalGroupIds] = useState<
    Set<number>
  >(initialApprovalGroupIds)

  const { filters, applyFilters, filtersWithout } = useFiltering({
    hiddenDays,
    filteredAlertTypes,
    hiddenStatuses,
    filterEmployeesWithVisibleTimeCards,
    employeeNameFilter: debouncedEmployeeNameFilter,
    filteredFacilityIds: debouncedFilteredFacilityIds,
    filteredApprovalGroupIds,
  })

  const {
    data: timeCards,
    refetch: refetchTimeCards,
    isLoading: isLoadingTimeCards,
  } = useAdminTimeCards(selectedWeek, endOfWeek)
  const { data: employees, isLoading: isLoadingEmployees } = useEmployees(
    selectedWeek,
    endOfWeek,
  )
  const locksQuery = useSearchDayLocks(selectedWeek, endOfWeek)

  const isLoading = useMemo(
    () => isLoadingTimeCards || isLoadingEmployees,
    [isLoadingEmployees, isLoadingTimeCards],
  )

  const upsertLocks = useUpsertDayLocks()
  const deleteLocks = useDeleteDayLocks()
  const sendTimeCardsToPayroll = useSendToPayroll()

  // This is used as a convenient way to:
  // 1. Identify when *any* filter has changed
  // 2. Identify if *any* filters are enabled (or even how many)
  const enabledFilters = useMemo(
    () => ({
      hiddenDays: hiddenDays.size > 0 ? hiddenDays : false,
      hiddenStatuses: hiddenStatuses.size > 0 ? hiddenStatuses : false,
      filteredAlertTypes: filteredAlertTypes.size > 0 ? hiddenStatuses : false,
      employeeNameFilter:
        employeeNameFilter !== '' ? employeeNameFilter : false,
      filteredFacilityIds:
        filteredFacilityIds.size > 0 ? filteredFacilityIds : false,
      filteredApprovalGroupIds:
        filteredApprovalGroupIds.size > 0 ? filteredApprovalGroupIds : false,
      filterEmployeesWithVisibleTimeCards:
        filterEmployeesWithVisibleTimeCards === 'all'
          ? false
          : filterEmployeesWithVisibleTimeCards,
    }),
    [
      filteredAlertTypes,
      employeeNameFilter,
      filteredFacilityIds,
      filteredApprovalGroupIds,
      hiddenDays,
      hiddenStatuses,
      filterEmployeesWithVisibleTimeCards,
    ],
  )

  // Clear selection when any filters change
  useEffect(() => clearSelected(), [enabledFilters, clearSelected])

  // Update search params when filters change
  // NOTE: This can be uncommented to enable state persistence via search params,
  //       it is currently disabled due to the significance of the change
  //       and the potential for breaking changes — it requires additional QA to release.
  // useEffect(() => {
  //   setSearchParams(
  //     (params) => {
  //       const {
  //         hiddenStatuses: _a,
  //         hiddenDays: _b,
  //         facilityIds: _c,
  //         approvalGroupIds: _d,
  //         workerNameQuery: _e,
  //         ...rest
  //       } = Object.fromEntries(params.entries())

  //       return {
  //         ...rest,
  //         ...serializeParams({
  //           hiddenStatuses,
  //           hiddenDays,
  //           facilityIds: debouncedFilteredFacilityIds,
  //           approvalGroupIds: filteredApprovalGroupIds,
  //           workerNameQuery: debouncedEmployeeNameFilter,
  //         }),
  //       }
  //     },
  //     {
  //       replace: true,
  //     },
  //   )
  // }, [
  //   setSearchParams,
  //   debouncedFilteredFacilityIds,
  //   filteredApprovalGroupIds,
  //   hiddenStatuses,
  //   hiddenDays,
  //   debouncedEmployeeNameFilter,
  // ])

  const employeesAndTimeCards = useMemo<WorkerAndTimeCards[]>(() => {
    if (!employees || !timeCards) return []

    const map: { [key: number]: WorkerAndTimeCards } = {}

    employees.forEach((worker) => {
      map[worker.workdayWorkerId] = {
        worker,
        timeCards: [],
      }
    })

    timeCards.forEach((timeCard) => {
      if (!map[timeCard.workdayWorkerId]) {
        throw new Error(
          `Time Card (${timeCard.id}) found for worker not returned by search (${timeCard.workdayWorkerId})`,
        )
      }

      map[timeCard.workdayWorkerId].timeCards.push(timeCard)
    })

    return Object.values(map)
  }, [employees, timeCards])

  const filteredEmployeesAndTimeCards = useMemo(
    () => applyFilters(employeesAndTimeCards, filters),
    [employeesAndTimeCards, filters, applyFilters],
  )

  const employeesWithVisibleTimeCardsFilterCounts =
    useMemo<FilterWorkerssWithVisibleItemsCounts>(() => {
      const filteredWithoutVisibleTimeCardFilter = applyFilters(
        employeesAndTimeCards,
        filtersWithout('visibleTimeCardFilter'),
      )

      const all = filteredWithoutVisibleTimeCardFilter.length
      const none = filteredWithoutVisibleTimeCardFilter.filter(
        ({ timeCards }) => timeCards.length === 0,
      ).length
      const atLeastOne = all - none

      return { all, atLeastOne, none }
    }, [employeesAndTimeCards, applyFilters, filtersWithout])

  const filterAlertTypeCounts = useMemo<FilterAlertTypeCount>(() => {
    const counts = {
      issues: 0,
      pending: 0,
      workdayError: 0,
    }

    const filteredWithoutAlertFilter = applyFilters(
      employeesAndTimeCards,
      filtersWithout('alertFilter'),
    )

    // We use statusFilteredTimeCardsWithoutAlertFilters here because
    // we want the totals for each alert after applying
    // all other filters but without applying the alert filter itself
    // (so the alert totals remain visible even when filtered out by another alert filter)
    filteredWithoutAlertFilter.forEach(({ timeCards }) => {
      timeCards.forEach((timeCard) => {
        if (timeCard.issues.length > 0) counts.issues++
        if (timeCard.workdaySendStatus === WorkdaySendStatus.Pending)
          counts.pending++
        if (timeCard.workdaySendStatus === WorkdaySendStatus.Error)
          counts.workdayError++
      })
    })

    return counts
  }, [employeesAndTimeCards, filtersWithout, applyFilters])

  // We use alertFilteredTimeCards here because that's
  // the collection status filters filter down and we want pre-filtered
  // totals to be displayed for each status
  const statusCounts = useMemo<StatusCounts>(() => {
    const filteredWithoutStatusFilter = applyFilters(
      employeesAndTimeCards,
      filtersWithout('statusFilter'),
    )

    return getStatusCount(
      filteredWithoutStatusFilter.flatMap(({ timeCards }) => timeCards),
    )
  }, [applyFilters, employeesAndTimeCards, filtersWithout])

  const employeesAndTimeCardsBeforeTableFiltering = useMemo(
    () =>
      applyFilters(
        employeesAndTimeCards,
        filtersWithout('workerAttributeFilter'),
      ),
    [filtersWithout, applyFilters, employeesAndTimeCards],
  )

  const locks = useMemo(() => massageLocks(locksQuery.data), [locksQuery.data])

  const statusSelectedCounts = Object.values(selected).reduce<StatusCounts>(
    (acc, { dates: _dates, ...timeCards }: SelectedEmployeeValues) => {
      const count = { ...acc }
      Object.values(timeCards).forEach((timeCard) => {
        count[timeCard.status] = count[timeCard.status] + 1
      })
      return count
    },
    {
      [Status.Open]: 0,
      [Status.Approved]: 0,
      [Status.Sent]: 0,
      [Status.Submitted]: 0,
    },
  )

  const sendableSelectedTimeCards = useMemo(
    () =>
      timeCardsFromSelected(selected).filter(
        (timeCard) =>
          timeCard.status === Status.Approved &&
          !timeCard.correction &&
          timeCard.issues.length === 0,
      ),
    [selected],
  )

  // Refetch Time Cards every 5s if any are sent and pending a response from WD
  useEffect(() => {
    if (
      timeCards?.every(
        (timeCard) =>
          timeCard.status !== Status.Sent ||
          timeCard.workdaySendStatus !== WorkdaySendStatus.Pending,
      )
    )
      return

    const timeoutId = setTimeout(() => void refetchTimeCards(), 5000)
    return () => clearTimeout(timeoutId)
  }, [timeCards, refetchTimeCards])

  const processUpsertLocks = async ({
    days,
    type,
  }: {
    days: DayLockPayload[]
    type: DayLockType
  }) => {
    try {
      await upsertLocks.mutateAsync({ days, type })

      toast({
        title: t('features.admin.lockingSuccessful'),
        variant: 'success',
        content: t('features.admin.lockedDaysSuccessfully', {
          count: days.length,
        }),
      })
    } catch {
      modal.alert({
        content: t('features.admin.failedToLockDays'),
      })
    }
  }

  const handleLockOperation = (type: DayLockType) => () => {
    const days = buildLockPayloadData(selected)

    modal.confirm({
      title: t('features.admin.confirmLocks'),
      content: t('features.admin.confirmLockSelected', { count: days.length }),
      onConfirmAsync: async () => await processUpsertLocks({ days, type }),
    })
  }

  const processDeleteLocks = async (days: DayLockPayload[]) => {
    try {
      await deleteLocks.mutateAsync(days)

      toast({
        title: t('features.admin.unlockingSuccessful'),
        variant: 'success',
        content: t('features.admin.unlockedDaysSuccessfully', {
          count: days.length,
        }),
      })
    } catch {
      modal.alert({
        content: t('features.admin.failedToUnlockDays'),
      })
    }
  }

  const handleUnlock = () => {
    const days = buildLockPayloadData(selected)

    const dayLocks = locksQuery.data || []

    const unlockableDays = days.filter((day) =>
      dayLocks.find(
        (lock) =>
          lock.workdayWorkerId === day.workdayWorkerId &&
          lock.date.hasSame(day.date, 'day'),
      ),
    )

    modal.confirm({
      title: t('features.admin.confirmUnlock'),
      content: t('features.admin.confirmUnlockSelected', {
        count: unlockableDays.length,
      }),
      onConfirmAsync: async () => await processDeleteLocks(unlockableDays),
    })
  }

  const processSendToPayroll = async (timeCardIds: number[]) => {
    const id = openPendingModal(
      t('features.admin.sendingSelectedTimeCardsToPayroll'),
    )

    try {
      await sendTimeCardsToPayroll.mutateAsync(timeCardIds)

      // NOTE: This is critical for fixing a bug where a user is filtered
      //       on Approved, sends a TC to payroll, and then that TC
      //       is no longer visible, but is part of their selection
      clearSelected()

      toast({
        title: t('features.admin.sendSuccesful'),
        variant: 'success',
        content: t('features.admin.successfullySentTimeCardsToWorkday', {
          count: timeCardIds.length,
        }),
      })
    } catch {
      modal.alert({
        content: t('features.admin.failedToSendToPayroll'),
      })
    } finally {
      modal.close(id)
    }
  }

  const handleSendToPayroll = () => {
    modal.confirm({
      title: t('features.admin.confirmSendToPayroll'),
      content: t('features.admin.confirmSendSelectedTimeCardsToPayroll', {
        count: sendableSelectedTimeCards.length,
      }),
      onConfirm: () =>
        void processSendToPayroll(
          sendableSelectedTimeCards.map((timeCard) => timeCard.id),
        ),
    })
  }

  const reset = () => {
    clearSelected()
    showAllDays()
    showAllStatuses()
    setFilteredAlertTypes(new Set())
    setEmployeeNameFilter('')
    setFilteredFacilityIds(new Set())
    setFilteredApprovalGroupIds(new Set())
    setFilterEmployeesWithVisibleTimeCards('all')
  }

  const tableRef = useRef<ImperativeHandle>(null)

  const closeSidebar = useCallback(() => {
    setEmployeeSidebarOpened(false)
    setViewDetails(undefined)
  }, [])

  const onCellFocusChange = useCallback(
    (workerAndTimeCards?: WorkerAndTimeCards, date?: DateTime) => {
      if (workerAndTimeCards === undefined || date === undefined) {
        setViewDetails(undefined)
        setEmployeeSidebarOpened(false)
      } else {
        const { worker, timeCards } = workerAndTimeCards

        setViewDetails({
          worker,
          timeCard: timeCards?.find((timeCard) =>
            timeCard.date.hasSame(date, 'day'),
          ),
          date,
        })
        setEmployeeSidebarOpened(true)
      }
    },
    [],
  )

  return (
    <div className="flex flex-grow">
      <div className="flex flex-col flex-grow">
        <Header
          lockingEnabled={Object.values(selected).length > 0}
          onFullLock={handleLockOperation(DayLockType.Full)}
          onSendToWorkday={handleSendToPayroll}
          onSubmit={() => alert('onSubmit')}
          onUnapproveLock={handleLockOperation(DayLockType.Unapprove)}
          onUnsubmit={() => alert('onUnsubmit')}
          onUnsubmitLock={handleLockOperation(DayLockType.Unsubmit)}
          onUnlock={handleUnlock}
          selections={selected}
          sendToWorkdayEnabled={sendableSelectedTimeCards.length > 0}
        />
        <WeekSelectorSubHeader
          onWeekSelect={(startOfWeek) => {
            clearSelected()
            setSelectedWeek(startOfWeek)
            closeSidebar() // Close sidebar when changing weeks
          }}
          selectedWeek={selectedWeek}
        >
          <FilterWorkerWithVisibleItemsButtonGroup
            counts={employeesWithVisibleTimeCardsFilterCounts}
            onChange={setFilterEmployeesWithVisibleTimeCards}
            value={filterEmployeesWithVisibleTimeCards}
            loading={isLoading}
          />
          <AlertButtons
            counts={filterAlertTypeCounts}
            onChange={setFilteredAlertTypes}
            selected={filteredAlertTypes}
          />
          <StatusMiniSilos
            counts={statusCounts}
            hiddenStatuses={hiddenStatuses}
            onHide={hideStatus}
            onSelect={([status]) => tableRef.current?.select(status)}
            onShow={showStatus}
            onUnselect={([status]) => tableRef.current?.unSelect(status)}
            selectedCounts={statusSelectedCounts}
            loading={isLoading}
          />
          <Button
            className="px-4 ml-1 text-sm font-medium text-neutral-900"
            onClick={reset}
            disabled={
              Object.values(enabledFilters).every(
                (filter) => filter === false,
              ) && Object.keys(selected).length === 0
            }
            variant="outlined"
          >
            {t('common.reset')}
          </Button>
        </WeekSelectorSubHeader>
        <Table
          employeesAndTimeCards={filteredEmployeesAndTimeCards}
          hiddenDays={hiddenDays}
          locks={locks}
          onHideDays={hideDays}
          onSelect={select}
          onShowDays={showDays}
          onUnSelect={unSelect}
          onUnSelectAll={clearSelected}
          ref={tableRef}
          selectedCells={selected}
          weekRange={range}
          onEmployeeNameFilterChange={setEmployeeNameFilter}
          employeeNameFilter={employeeNameFilter}
          filteredFacilityIds={filteredFacilityIds}
          onFacilityFilterChange={setFilteredFacilityIds}
          filteredApprovalGroupIds={filteredApprovalGroupIds}
          onApprovalGroupFilterChange={setFilteredApprovalGroupIds}
          employeesAndTimeCardsBeforeTableFiltering={
            employeesAndTimeCardsBeforeTableFiltering
          }
          loading={isLoading}
          employeeSidebarOpened={employeeSidebarOpened}
          onCellFocusChange={onCellFocusChange}
        />
      </div>
      {employeeSidebarOpened && viewingDetails && (
        <Sidebar
          date={viewingDetails.date}
          employeeDetails={{
            department: viewingDetails.worker.department.name,
            type: isEmployee(viewingDetails.worker)
              ? t(`payTypes.${viewingDetails.worker.payType}`)
              : t(
                  `contingentWorkerTypes.${viewingDetails.worker.contingentWorkerType}`,
                ),
            fullName: viewingDetails.worker.fullName,
            title: viewingDetails.worker.jobTitle || undefined,
          }}
          employeeId={viewingDetails.worker.workdayWorkerId}
          onClose={closeSidebar}
          timeCard={viewingDetails.timeCard}
        />
      )}
    </div>
  )
}
