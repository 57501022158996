import { FC } from 'react'
import { Label } from '../../../components/Label'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { useSearchTasks } from '@hooks/useSearchTasks'
import { NavItem, NavItemSkeleton } from '../../../components/NavItem'
import { TaskSearchResultItemWithType } from '../types'
import { useTranslation } from 'react-i18next'

interface Props {
  hidden: boolean
  onProjectClick: (project: TaskSearchResultItemWithType) => void
  search?: string
}

export const Projects: FC<Props> = ({ hidden, onProjectClick, search }) => {
  const { t } = useTranslation()
  const projectsResult = useSearchTasks({ resourceType: 'project', q: search })

  const projects = projectsResult.data?.pages.flatMap((page) => page.data) ?? []
  const totalProjects = projectsResult.data?.pages.at(0)?.meta?.total ?? 0

  if (hidden) return null

  return (
    <>
      <Label
        content={t('common.projects')}
        count={totalProjects ?? 0}
        hideCount={totalProjects === undefined}
        className="mt-4 mb-2 mx-9"
      />
      <InfiniteScrollList
        totalItems={totalProjects}
        items={projects.map((project) => ({
          label: project.label,
          id: project.id,
          onClick: () => onProjectClick({ ...project, type: 'project' }),
        }))}
        loadMore={() => void projectsResult.fetchNextPage()}
        isInitiallyLoading={projectsResult.isLoading}
        isFetching={
          projectsResult.isFetchingNextPage || projectsResult.isLoading
        }
        renderItem={(item) => (
          <NavItem
            key={`${item.id ?? ''}=${item.label}`}
            label={item.label}
            onClick={item.onClick}
            showImage={true}
            skip={false}
          />
        )}
        expectedItemHeight={40}
        skeletonItem={<NavItemSkeleton />}
      />
    </>
  )
}
