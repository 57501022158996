import { toast as reactToast } from 'react-hot-toast'
import { Toast, ToastProps } from 'components/notifications'
import { noop } from 'utils/noop'

interface Options {
  dismissable?: boolean
  content?: ToastProps['content']
  onClose?: () => void
  onUndo?: () => void
  title: ToastProps['title']
  variant?: ToastProps['variant']
  sticky?: boolean
}

export const toast = ({
  content,
  dismissable = true,
  sticky = false,
  onClose = noop,
  onUndo,
  title,
  variant,
}: Options) => {
  const toastOptions: Parameters<typeof reactToast.custom>[1] = {}

  if (sticky) {
    toastOptions.duration = Infinity
  }

  const id = reactToast.custom(
    <Toast
      content={content}
      dismissable={dismissable}
      title={title}
      variant={variant}
      onUndoClick={onUndo}
      onCloseButtonClick={() => {
        reactToast.remove(id)
        onClose()
      }}
    />,
    toastOptions,
  )
  return id
}
