import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  children: ReactNode
  description: string
  label: string
}

export const Field: FC<Props> = ({
  className,
  label,
  description,
  children,
}) => (
  <div
    className={twMerge(
      'flex flex-row py-6 border-b first-of-type:pt-0 space-x-2 border-neutral-200 last-of-type:border-0',
      className,
    )}
  >
    <div className="flex flex-col basis-1/3 min-w-[256px]">
      <label className="text-lg font-medium text-neutral-900">{label}</label>
      <span className="text-xs text-neutral-600">{description}</span>
    </div>
    <div className="flex flex-row items-center basis-2/3">{children}</div>
  </div>
)
