import { FC, useState } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'

interface Props {
  onClick: () => void
  icon: IconDefinition
  boldIcon: IconDefinition
  disabled?: boolean
}

const IncrementDecrementButton: FC<Props> = ({
  onClick,
  icon,
  boldIcon,
  disabled,
}) => {
  const [pressed, setPressed] = useState<boolean>(false)

  return (
    <button
      className={twMerge(
        'cursor-pointer flex w-8 justify-center items-center',
        disabled && 'opacity-20',
      )}
      onClick={onClick}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      disabled={disabled}
    >
      <div
        className={twMerge(
          'w-6 h-6 rounded-primary',
          !disabled && 'hover:bg-neutral-300 active:bg-neutral-400',
        )}
      >
        <FontAwesomeIcon
          icon={pressed ? boldIcon : icon}
          className="text-neutral-800"
        />
      </div>
    </button>
  )
}

export default IncrementDecrementButton
