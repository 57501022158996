import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

export const ActiveDiamond: FC<{ active: boolean; className?: string }> = ({
  active,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'w-2 h-2 rotate-45',
        active && 'bg-success-500',
        !active && 'border border-neutral-400',
        className,
      )}
    ></div>
  )
}
