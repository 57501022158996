import { Column, StackItem, TaskSearchResultItemWithType } from '../types'

export const getNextColumnFromItemType = (
  type: TaskSearchResultItemWithType['type'],
): Column => {
  if (type === 'project') return 'sequence'
  if (type === 'sequence') return 'shot'
  if (type === 'entity_type') return 'entity'
  if (type === 'shot' || type === 'entity') return 'step'

  return 'task'
}

export const popFromStack =
  (column?: Column) =>
  (stack: StackItem[]): StackItem[] => {
    // This is a little smelly
    if (stack.length === 1 && stack[0].column !== column) return []

    const newStack = []
    for (let i = 0; i < stack.length; i++) {
      const currentItem = stack[i]
      if (currentItem.column !== column && i !== stack.length - 2) {
        newStack.push(currentItem)
      } else {
        newStack.push({
          column: currentItem.column,
          viewingAll: currentItem.viewingAll,
        })
        break
      }
    }
    return newStack
  }

/**
 * adds a new stack item to the stack, replacing any items that already have
 * the same column
 */
export const pushToStack = (newItem: StackItem) => (stack: StackItem[]) => {
  const itemIsInstack = !!stack.find(({ column }) => column === newItem.column)

  if (!itemIsInstack) return [...stack, newItem]

  return stack.reduce<StackItem[]>((acc, item) => {
    if (item.column === newItem.column)
      return [...acc, { ...newItem, viewingAll: item.viewingAll }]

    return [...acc, item]
  }, [])
}

const typeLabelMap: Record<TaskSearchResultItemWithType['type'], string> = {
  project: 'Project',
  sequence: 'Sequence',
  shot: 'Shot',
  entity_type: 'Entity Type', // eslint-disable-line
  entity: 'Entity',
  step: 'Step',
  task: 'Task',
}

export const annotateWithType = (
  type: TaskSearchResultItemWithType['type'],
  interpolate: string | number,
) => {
  return `${typeLabelMap[type]}: ${interpolate}`
}

export const generateStackFromItem = (
  item: TaskSearchResultItemWithType,
): StackItem[] => {
  const ancestorStackItems: StackItem[] = []

  if (item.project !== undefined) {
    ancestorStackItems.push({
      id: item.project.id,
      column: 'root',
      label: item.project.label,
    })
  }

  if (item.sequence !== undefined) {
    ancestorStackItems.push({
      id: item.sequence.id,
      column: 'sequence',
      label: item.sequence.label,
    })
  }

  if (item.entityType !== undefined) {
    ancestorStackItems.push({
      column: 'entity_type',
      label: item.entityType,
      type: item.entityType,
    })
  }

  if (item.linkedEntity !== undefined) {
    ancestorStackItems.push({
      id: item.linkedEntity.id,
      column: 'entity',
      label: item.linkedEntity.label,
    })
  }

  if (item.step !== undefined) {
    ancestorStackItems.push({
      id: item.step.id,
      column: 'step',
      label: item.step.label,
    })
  }

  const clickedStackItem: StackItem = {
    id: item.id,
    label: item.label,
    column: item.type === 'project' ? 'root' : item.type,
  }

  const currentLocation = { column: getNextColumnFromItemType(item.type) }

  return [...ancestorStackItems, clickedStackItem, currentLocation]
}
