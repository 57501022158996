import { FC, useState } from 'react'
import { Item as TItem } from '../types'
import { twMerge } from 'tailwind-merge'
import { ItemPath } from './ItemPath'
import { IconButton } from 'components/buttons'
import { faSquareMinus } from '@fortawesome/pro-solid-svg-icons'
import {
  faChevronDown,
  faChevronUp,
  faSquarePlus,
} from '@fortawesome/pro-regular-svg-icons'
import { ActiveDiamond } from './ActiveDiamond'
import { Tooltip } from '@lib/tooltip'
import { useTranslation } from 'react-i18next'

export interface Props {
  className?: string
  item: TItem
  onItemClick: (id: number, checked: boolean) => void
  path?: string[]
}

export const SkeletonItem = () => (
  <div className="h-11 grow my-2 mx-9 bg-neutral-100 animate-pulse"></div>
)

export const Item: FC<Props> = ({ className, item, onItemClick, path }) => {
  const { t } = useTranslation()
  const [expanded, setExpaned] = useState(false)

  return (
    <div className={twMerge('flex flex-col', className)}>
      {path && <ItemPath path={path} />}
      <div
        className={twMerge(
          'flex flex-col p-1 my-1 text-sm rounded bg-neutral-150',
          item.checked && 'bg-primary-100',
        )}
      >
        <div className={twMerge('flex flex-row items-center')}>
          <label
            data-tooltip-id={`tooltip-${item.id}`}
            key={item.id}
            className={twMerge(
              'flex flex-grow flex-row items-center text-sm font-semibold cursor-pointer select-none',
              item.disabled && 'cursor-not-allowed opacity-50',
            )}
          >
            <IconButton
              id={`item-${item.id}`}
              className={twMerge(
                'hover:bg-primary-300 group w-8 h-8 text-neutral-500 mr-2',
                item.checked && 'text-primary-600',
              )}
              disabled={item.disabled}
              icon={item.checked ? faSquareMinus : faSquarePlus}
              iconProps={{
                className: 'group-hover:text-primary-800',
                size: 'lg',
              }}
              onClick={() => onItemClick(item.id, !item.checked)}
              circle={true}
            />
            {item.label}
          </label>
          {item.active !== undefined && (
            <ActiveDiamond active={item.active} className="mr-1" />
          )}
          <IconButton
            id={`item-${item.id}`}
            className="w-8 h-8 text-neutral-600 ml-auto hover:bg-neutral-300"
            icon={expanded ? faChevronUp : faChevronDown}
            onClick={() => setExpaned((expanded) => !expanded)}
          />
        </div>
        {expanded && (
          <table className="my-2 table-auto font-medium">
            <tbody>
              <tr>
                <td className="px-2 text-xs text-neutral-500 whitespace-nowrap">
                  {t('features.timeLogging.taskSelection.id')}
                </td>
                <td className="px-2 text-xs text-neutral-900 w-full whitespace-nowrap">
                  {item.id}
                </td>
              </tr>
              {/*
              <tr>
                <td className="px-2 text-xs text-neutral-500 whitespace-nowrap w-1">
                  {t('features.timeLogging.taskSelection.flowPTStatus')}
                </td>
                <td className="px-2 text-xs text-neutral-900">{item.id}</td>
              </tr>
              */}
            </tbody>
          </table>
        )}
      </div>
      {item.disabled && (
        <Tooltip
          id={`tooltip-${item.id}`}
          place="bottom"
          content={t(
            'features.timeLogging.taskSelection.taskCannotBeRemovedWhenTimeIsAllocatedToIt',
          )}
        />
      )}
    </div>
  )
}
