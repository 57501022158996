import { useWeekDates } from './useWeekDates'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { notesKeys } from '@lib/keys'
import { deleteNote, getNotes, putNote } from '@lib/api'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useEmployee } from './useEmployee'

interface Options {
  userId: number
}

export const useNotes = ({ userId }: Options) => {
  const employeeQuery = useEmployee({ userId })
  const employeeId = employeeQuery.data?.workdayWorkerId ?? 0
  const { start, end } = useWeekDates()

  return useQuery(
    notesKeys.search(employeeId, start, end),
    () => getNotes(employeeId, start, end),
    { enabled: employeeId !== 0 },
  )
}

export const useNoteForDate = (date: DateTime, userId: number) => {
  const notesQuery = useNotes({ userId })

  return useMemo(() => {
    return notesQuery.data?.find((note) => note.date.hasSame(date, 'day'))
  }, [date, notesQuery.data])
}

export const usePostNote = ({ userId }: Options) => {
  const queryClient = useQueryClient()
  const employeeQuery = useEmployee({ userId })
  const employeeId = employeeQuery.data?.workdayWorkerId ?? 0
  const { start, end } = useWeekDates()

  return useMutation({
    mutationKey: notesKeys.search(employeeId, start, end),
    mutationFn: (options: { date: DateTime; note: string }) => {
      if (employeeId === 0)
        return Promise.reject('Unable to post note.  Invalid employee id of 0')

      return putNote(employeeId, options.date, options.note)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        notesKeys.search(employeeId, start, end),
      )
    },
  })
}

export const useDeleteNote = ({ userId }: Options) => {
  const queryClient = useQueryClient()
  const employeeQuery = useEmployee({ userId })
  const employeeId = employeeQuery.data?.workdayWorkerId ?? 0
  const { start, end } = useWeekDates()

  return useMutation({
    mutationKey: notesKeys.search(employeeId, start, end),
    mutationFn: (options: { date: DateTime; note: string }) => {
      if (employeeId === 0)
        return Promise.reject(
          'Unable to delete note.  Invalid employee id of 0',
        )

      return deleteNote(employeeId, options.date)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        notesKeys.search(employeeId, start, end),
      )
    },
  })
}
