import { FC, useState } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Getter,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowUpAZ } from '@fortawesome/pro-regular-svg-icons'
import { faChainBroken, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { TableFilterField } from 'components/tables/TableFilterField'
import { IconButton } from 'components/buttons'
import { useChangeApprovalGroup } from '../hooks/useChangeApprovalGroup'
import { timeLoggerRouteBuilder } from '@utils/timeLoggerRouteBuilder'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DownloadCsvButton } from 'components/tables'

interface Props {
  employees: BasicWorker[]
}

const columnHelper = createColumnHelper<BasicWorker>()

export const Table: FC<Props> = ({ employees }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { changeApprovalGroup } = useChangeApprovalGroup()

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.employeesAdmin.name'),
      filterFn: 'includesString',
      cell: ({ row, getValue }) => (
        <Link
          to={timeLoggerRouteBuilder(row.original.user.id)}
          className="text-hyperlink-blue"
        >
          {getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('user.email', {
      header: t('features.employeesAdmin.email'),
      filterFn: 'includesString',
    }),
    {
      id: 'WID',
      header: 'WID',
      accessorFn: (employee: BasicWorker) =>
        employee.workdayWorkerId.toString(),
      filterFn: 'includesString',
    } as ColumnDef<BasicWorker>,
    {
      id: 'shotgridUserId',
      header: t('features.employeesAdmin.shotgridUserId'),
      filterFn: 'includesString',
      accessorFn: (employee: BasicWorker) =>
        employee.user.shotgridUserId?.toString(),
      cell: ({ getValue }: { getValue: Getter<string> }) =>
        getValue() ?? (
          <span className="font-bold text-error-500">
            <FontAwesomeIcon icon={faChainBroken} className="mr-1" />{' '}
            {t('features.employeesAdmin.notLinked')}
          </span>
        ),
    } as ColumnDef<BasicWorker>,
    columnHelper.accessor('approvalGroup.name', {
      header: t('common.approvalGroup'),
      filterFn: 'includesString',
      cell: ({ row, getValue }) => (
        <div className="flex items-center justify-between">
          <Link
            to={`/approvals/${row.original.approvalGroup.approvalDomainId}`}
            className="text-hyperlink-blue"
          >
            {getValue()}
          </Link>
          <IconButton
            icon={faEdit}
            className="invisible w-8 h-8 border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => void changeApprovalGroup(row.original)}
          />
        </div>
      ),
    }),
  ]

  const table = useReactTable({
    data: employees,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <div className="px-8 pt-6 flex flex-col flex-grow">
      <div className="flex justify-between mb-2">
        <div className="text-lg">
          {t('common.employeesCountAndTotal', {
            count: table.getRowModel().rows.length,
            total: employees.length,
          })}
        </div>

        <DownloadCsvButton table={table} filename={t('common.employees')} />
      </div>

      <div className="relative flex-grow">
        <div className="absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <table className="text-sm min-w-full">
            <thead className="sticky top-0 shadow z-20">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 px-4 py-6 font-semibold text-left align-top border border-t-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300 hover:bg-neutral-200 first-of-type:border-l-0 last-of-type:border-r-0"
                      onClick={header.column.getToggleSortingHandler()}
                      colSpan={header.colSpan}
                    >
                      <div className="flex justify-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.getIsSorted() && (
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'asc'
                                ? faArrowDownAZ
                                : faArrowUpAZ
                            }
                          />
                        )}
                      </div>
                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField
                            table={table}
                            column={header.column}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-neutral-100 group/row">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-3 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
