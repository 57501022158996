import { FC, useState } from 'react'
import { EmployeeSidebar, HourAllocationTab } from '@features/admin'
import { ApprovalHistoryTab } from './approval-history-tab'
import { useTimeCardById } from '@hooks/useTimeCards'
import { rangeInTimeOfDay } from '@utils/time'
import { padDigit } from '@utils/padDigit'
import { useTranslation } from 'react-i18next'
import { StatusBadge } from 'components/indicators/status-badge'
import { getApprovalStatus } from '@features/approvals/helpers/getApprovalStatus'
import { Tabs } from 'components/buttons'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { DateTime } from 'luxon'

const getHourString = (timeCard: TimeCard | undefined) => {
  if (timeCard === undefined) return ''

  const { startAt, endAt, date, timeZone } = timeCard

  if (startAt == null) return ''
  if (endAt == null) return ''

  return rangeInTimeOfDay(startAt, endAt, date, timeZone)
}

type TabIds = 'hour-allocation' | 'approval-history'

export interface Details {
  approval: Omit<Approval, 'approvalDomain'>
  approvalApproved?: boolean
  date: DateTime
  employeeDetails: {
    department?: string
    email?: string
    fullName: string
    type: string
    phoneNumber?: string
    profileImageUrl?: string
    title?: string
  }
  timeCardId: number
  userId: number
}

interface Props extends Details {
  onClose: () => void
}

export const Sidebar: FC<Props> = ({
  approval,
  date,
  employeeDetails,
  onClose,
  timeCardId,
  userId,
}) => {
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const [activeTab, setActiveTab] = useState<TabIds>('hour-allocation')
  const timeCard = useTimeCardById(timeCardId, userId)

  const breaks = timeCard?.breaks?.reduce((acc, out, index) => {
    if (out.startAt === undefined) return acc
    if (out.endAt === undefined) return acc
    if (timeCard === undefined) return acc

    return {
      ...acc,
      [t('features.approvals.break', { number: padDigit(index + 1) })]:
        rangeInTimeOfDay(out.startAt, out.endAt, date, timeCard.timeZone),
    }
  }, {})

  const tabs = [
    {
      id: 'hour-allocation',
      label: t('common.hourAllocation'),
    },
    {
      id: 'approval-history',
      label: t('features.approvals.approvalHistory'),
    },
  ] as { id: TabIds; label: string }[]

  return (
    <EmployeeSidebar
      {...employeeDetails}
      hidden={false}
      info={[
        {
          [t('common.status')]: (
            <StatusBadge
              status={getApprovalStatus(approval)}
              className="inline-flex"
            />
          ),
        },
        {
          Date: toLocaleString(DateTime.DATE_SHORT)(date), // Temporary until we have date picker
          [t('features.approvals.hours')]: getHourString(timeCard),
          ...breaks,
        },
      ]}
      onClose={onClose}
      blockBreakpoint="2xl"
    >
      {<Tabs activeTab={activeTab} onTabClick={setActiveTab} tabs={tabs} />}
      {activeTab === 'hour-allocation' && (
        <HourAllocationTab date={date} timeCardId={timeCardId} />
      )}
      {activeTab === 'approval-history' && (
        <ApprovalHistoryTab approvalId={approval.id} />
      )}
    </EmployeeSidebar>
  )
}
