import { FC, useCallback, useState } from 'react'
import { StickiedPageHeader } from 'components/page-header/StickiedPageHeader'
import {
  AllocationTables,
  TimeCards,
  Header,
  WorkDayTable,
  TaskPickerSidebar,
} from '../components'
import { LeaveSidebar } from 'features/leave-sidebar'
import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { useWeekDates } from '@hooks/useWeekDates'

export const TimeLogging: FC = () => {
  const [hoverDate, setHoverDate] = useState<DateTime | null>(null)
  const [taskSidebarHidden, setTaskSidebarHidden] = useState(true)
  const [leaveSidebarHidden, setLeaveSidebarHidden] = useState(true)
  const { range } = useWeekDates()
  const { registerMenuClosed, registerMenuOpened } =
    useInhibitKeyPressOverride()

  const showTaskSidebar = () => {
    setTaskSidebarHidden(false)
    registerMenuOpened('task-sidebar')
  }

  const showLeaveSidebar = () => {
    setLeaveSidebarHidden(false)
    registerMenuOpened('leave-sidebar')
  }

  const hideTaskSidebar = useCallback(() => {
    setTaskSidebarHidden(true)
    registerMenuClosed('task-sidebar')
  }, [setTaskSidebarHidden, registerMenuClosed])

  const hideLeaveSidebar = useCallback(() => {
    setLeaveSidebarHidden(true)
    registerMenuClosed('leave-sidebar')
  }, [setLeaveSidebarHidden, registerMenuClosed])

  // TODO - bring in read only information from somewhere, likely from each time card
  // const readOnly = selectedPeriod.status != EmployeePayPeriodStatus.Open
  const readOnly = false

  return (
    <div id="main" className="relative flex flex-row no-wrap grow">
      <div className="grow">
        <TaskPickerSidebar
          hidden={taskSidebarHidden}
          onClose={hideTaskSidebar}
        />
        <LeaveSidebar hidden={leaveSidebarHidden} onClose={hideLeaveSidebar} />
        <StickiedPageHeader>
          <Header
            hoverDate={hoverDate}
            dateRange={range}
            onTaskButtonClick={showTaskSidebar}
            onLeaveButtonClick={showLeaveSidebar}
          />
          <TimeCards
            onTimeOffClick={() => setLeaveSidebarHidden(false)}
            readOnly={readOnly}
          />
        </StickiedPageHeader>
        <WorkDayTable onCellHover={setHoverDate} readOnly={readOnly} />
        <AllocationTables
          dateRange={range}
          headerHoverHandler={setHoverDate}
          onTaskButtonClick={() => setTaskSidebarHidden(false)}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}
