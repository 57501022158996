import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

const NavItem: FC<{ label: string; path: string }> = ({ label, path }) => (
  <NavLink
    to={path}
    className={({ isActive }) =>
      twMerge(
        'px-4 py-2 text-neutral-400 text-sm',
        isActive && 'text-white border-b-2 border-white font-semibold',
      )
    }
  >
    {label}
  </NavLink>
)

export const Navigation: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row">
      <NavItem
        label={t('features.settings.global.global')}
        path="/settings/global"
      />
      <NavItem label={t('common.timeLogging')} path="/settings/time-logging" />
    </div>
  )
}
