import useToggle from '@hooks/useToggle'
import classNames from 'classnames'
import { ToggleHeading } from '@features/time-logging/components'

interface Props {
  name: string
  children?: React.ReactNode
  initiallyOpen?: boolean
}

export function Toggle(props: Props) {
  const [expanded, toggleExpanded] = useToggle(props.initiallyOpen || false)

  return (
    <>
      <ToggleHeading
        label={props.name}
        onClick={() => toggleExpanded()}
        expanded={expanded}
      />
      <div className={classNames({ 'pt-11': expanded })}>
        {expanded && props.children}
      </div>
    </>
  )
}
