import { FC, useState } from 'react'
import { EmployeeSidebar, HourAllocationTab } from '@features/admin'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'components/buttons'
import { HistoryTab } from './components/history-tab'
import { StatusBadge } from 'components/indicators/status-badge'
import { SidebarError } from './components/SidebarError'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'
import { DateTime } from 'luxon'
import { faCalendarPen } from '@fortawesome/pro-solid-svg-icons'
import { Label } from 'components/indicators/label'
import { Status, WorkdaySendStatus } from 'types'
import { WorkdaySendError } from './components/WorkdaySendError'

type TabIds = 'hour-allocation' | 'history' // | 'task-order'

interface Props {
  date: DateTime
  employeeDetails: {
    department?: string
    email?: string
    fullName: string
    type: string
    phoneNumber?: string
    profileImageUrl?: string
    title?: string
  }
  employeeId: number
  onClose: () => void
  timeCard?: AdminTimeCard // Not every day has a Time Card
}

export const Sidebar: FC<Props> = ({
  date,
  employeeDetails,
  onClose,
  timeCard,
  employeeId,
}) => {
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const [activeTab, setActiveTab] = useState<TabIds>(
    timeCard ? 'hour-allocation' : 'history',
  )

  const tabs = [
    {
      id: 'hour-allocation',
      label: t('common.hourAllocation'),
    },
    {
      id: 'history',
      label: t('features.admin.history'),
    },
    // {
    //  id: 'task-order',
    //  label: t('features.timeLogging.taskOrder'),
    // },
  ] as const

  const info = []

  if (timeCard) {
    info.push({
      [t('common.status')]: timeCard.correction ? (
        // TODO: Consider considering Corrected to be a first-class status
        <Label color="orange" icon={faCalendarPen} className="inline-flex">
          {t('features.timeLogging.corrected')}
        </Label>
      ) : (
        <StatusBadge status={timeCard.status} className="inline-flex" />
      ),
    })
  }

  info.push({
    Date: toLocaleString(DateTime.DATE_SHORT)(date), // Temporary until we have date picker
  })

  return (
    <EmployeeSidebar
      contentSlot={
        <div>
          {timeCard?.issues.map((issue) => (
            <SidebarError
              key={issue}
              title={t(`features.admin.timeCardIssueTypes.${issue}`)}
              content={t(`features.admin.timeCardIssueTypes.${issue}`)}
            />
          ))}
          {timeCard?.status === Status.Sent &&
            timeCard?.workdaySendStatus === WorkdaySendStatus.Error && (
              <WorkdaySendError timeCard={timeCard} />
            )}
        </div>
      }
      fullName={employeeDetails.fullName}
      department={employeeDetails.department}
      title={employeeDetails.title}
      type={employeeDetails.type}
      hidden={false}
      info={info}
      onClose={onClose}
      blockBreakpoint="3xl"
    >
      {<Tabs activeTab={activeTab} onTabClick={setActiveTab} tabs={tabs} />}
      <div className="overflow-auto">
        {activeTab === 'hour-allocation' &&
          (timeCard ? (
            <HourAllocationTab date={date} timeCardId={timeCard.id} />
          ) : (
            <div className="px-8 py-4 italic text-center text-neutral-400">
              {t('features.admin.noTimeCard')}
            </div>
          ))}
        {activeTab === 'history' && (
          <HistoryTab date={date} employeeId={employeeId} />
        )}
      </div>
    </EmployeeSidebar>
  )
}
