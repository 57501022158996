import { FC, HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGripDotsVertical,
  faPlus,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'

interface ItemProps {
  onSelectDeselect: () => void
  className?: string
  children: ReactNode
  selected: boolean
  dragHandleProps?: HTMLAttributes<HTMLDivElement>
  draggingEnabled: boolean
}

export const Item: FC<ItemProps> = ({
  onSelectDeselect,
  className,
  selected,
  dragHandleProps,
  children,
  draggingEnabled,
}) => {
  return (
    <div
      className={twMerge(
        'flex items-center gap-2',
        !selected && 'text-neutral-400',
        className,
      )}
    >
      <FontAwesomeIcon
        icon={selected ? faXmark : faPlus}
        onClick={() => onSelectDeselect()}
        className={twMerge(
          'cursor-pointer',
          selected && 'text-error-500',
          !selected && 'text-primary-600',
        )}
      />

      <div
        className="flex items-center gap-2"
        {...(draggingEnabled && dragHandleProps)}
      >
        {draggingEnabled && (
          <FontAwesomeIcon
            icon={faGripDotsVertical}
            className="text-neutral-600 cursor-move"
          />
        )}

        {children}
      </div>
    </div>
  )
}
