import { faChevronLeft, faHome } from '@fortawesome/pro-regular-svg-icons'
import { IconButton } from 'components/buttons'
import { FC } from 'react'
import { Tooltip } from 'lib/tooltip'
import { twMerge } from 'tailwind-merge'
import { Crumb } from '../types'

interface Props {
  crumbs: Crumb[]
  hidden: boolean
  onBackClick: () => void
  onHomeClick: () => void
}

export const Breadcrumbs: FC<Props> = ({
  crumbs,
  hidden,
  onBackClick,
  onHomeClick,
}) => {
  if (hidden) return null

  return (
    <div className="flex flex-row items-center mb-2 text-neutral-500 px-9">
      <IconButton
        icon={faHome}
        onClick={() => onHomeClick()}
        iconProps={{ size: 'sm' }}
        className="shrink-0"
      />
      <IconButton
        icon={faChevronLeft}
        onClick={() => onBackClick()}
        iconProps={{ size: 'sm' }}
        className="shrink-0"
      />
      <div className="flex flex-row overflow-hidden">
        {crumbs.map((crumb, index) => {
          const id = `${crumb.label}-${index}`
          const isMiddleCrumb = index !== 0 && index !== crumbs.length - 1
          const isClickable = Boolean(crumb.onClick)
          return (
            <span
              key={id}
              className={twMerge(
                'flex flex-row overflow-hidden',
                isMiddleCrumb ? 'min-w-0' : 'min-w-fit',
              )}
            >
              <button
                data-tooltip-id={id}
                key={id}
                onClick={crumb.onClick}
                className={twMerge(
                  'overflow-hidden text-xs whitespace-nowrap',
                  isMiddleCrumb && 'text-ellipsis',
                  isClickable && 'hover:underline',
                )}
                disabled={!isClickable}
              >
                {crumb.label}
              </button>
              <Tooltip
                id={id}
                content={crumb.tooltip}
                place="bottom"
                delayShow={200}
              />
              <span className="mx-1">{index !== crumbs.length - 1 && '•'}</span>
            </span>
          )
        })}
      </div>
    </div>
  )
}
