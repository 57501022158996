import {
  faCloudCheck,
  faCloudExclamation,
  faCloudQuestion,
} from '@fortawesome/pro-regular-svg-icons'
import { faCalendarPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHolidaysOnDate } from '@hooks/useHolidays'
import { useAbsencesForDate } from '@hooks/useAbsences'
import { CellAnnotation } from 'components/tables'
import { FC, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { Status, DayLockType, WorkdaySendStatus, TimeCardIssue } from 'types'
import { isSentTimeCard } from '../utils/adminTimeCards'
import { LockIcon } from './LockIcon'

interface Props {
  className?: string
  children: ReactNode
  date: DateTime
  holidayCalendarId?: string
  lockType?: DayLockType
  userId: number
  timeCard?: AdminTimeCard
}

const sentStatusIcon = (sendStatus: WorkdaySendStatus) => {
  switch (sendStatus) {
    case WorkdaySendStatus.Error:
      return (
        <FontAwesomeIcon
          icon={faCloudExclamation}
          size="sm"
          className="text-error-500"
          fixedWidth={true}
        />
      )
    case WorkdaySendStatus.Pending:
      return (
        <FontAwesomeIcon icon={faCloudQuestion} size="sm" fixedWidth={true} />
      )
    case WorkdaySendStatus.Success:
      return <FontAwesomeIcon icon={faCloudCheck} size="sm" fixedWidth={true} />
  }
}

const statusClasses = (timeCard?: AdminTimeCard) => {
  if (!timeCard) return
  if (timeCard.status === Status.Sent || timeCard.correction)
    return 'bg-white text-neutral-400'
  if (timeCard.status === Status.Submitted) return 'bg-warning-100'
  if (timeCard.status === Status.Approved) return 'bg-success-100'
  if (timeCard.status === Status.Open) return 'bg-neutral-50'
}

export const DayCell: FC<Props> = ({
  className,
  children,
  date,
  holidayCalendarId,
  lockType,
  userId,
  timeCard,
}) => {
  const { t } = useTranslation()

  const holidays = useHolidaysOnDate(date, { holidayCalendarId })
  const leave = useAbsencesForDate(date, { userId })

  const hasProblems = useMemo(
    () =>
      timeCard &&
      (timeCard.issues.length > 0 ||
        timeCard.workdaySendStatus === WorkdaySendStatus.Error),
    [timeCard],
  )

  const tooltips = useMemo(() => {
    const tooltips = []
    if (lockType) {
      tooltips.push(t(`features.admin.lockType.${lockType}`))
    }

    if (timeCard && timeCard.issues.length > 0) {
      tooltips.push(
        ...timeCard.issues.map((issue: TimeCardIssue) =>
          t(`features.admin.timeCardIssueTypes.${issue}`),
        ),
      )
    }

    if (timeCard) {
      if (timeCard.correction) {
        tooltips.push(t('features.timeLogging.corrected'))
      } else if (isSentTimeCard(timeCard)) {
        tooltips.push(
          t(`features.admin.workdaySendStatuses.${timeCard.workdaySendStatus}`),
        )
      }
    }

    if (holidays.length > 0) {
      tooltips.push(...holidays.map(({ name }) => name))
    }

    return tooltips
  }, [t, lockType, timeCard, holidays])

  return (
    <CellAnnotation
      className={twMerge(
        statusClasses(timeCard),
        hasProblems && 'bg-error-50 text-error-700',
        className,
      )}
      leftAnnotations={
        <div className="flex gap-1">
          <LockIcon type={lockType} />
        </div>
      }
      rightAnnotations={
        <>
          {timeCard &&
            isSentTimeCard(timeCard) &&
            !timeCard.correction &&
            sentStatusIcon(timeCard.workdaySendStatus)}
          {timeCard && timeCard.correction && (
            <FontAwesomeIcon icon={faCalendarPen} size="sm" fixedWidth={true} />
          )}
        </>
      }
      showLeaveIcon={leave.length > 0}
      showHolidayIcon={holidays.length > 0}
      showIssuesIcon={(timeCard?.issues.length ?? 0) > 0}
      tooltipContent={
        tooltips.length === 0 ? undefined : (
          <div className="flex flex-col gap-1">
            {tooltips.map((tooltip, i) => (
              <span key={i}>{tooltip}</span>
            ))}
          </div>
        )
      }
    >
      {children}
    </CellAnnotation>
  )
}
