import { ReportTypeMap } from '../types'

// import { TFuncKey } from 'react-i18next'
// import { timeLogColumnGroups } from './columnGroups'

// type ColumnGroupLabelMap<T> = {
//   [key in keyof T]: TFuncKey
// }

// NOTE: Once we upgrade to TS 4.9+ we can use satisfies
// to ensure that these objects match ColumnGroupLabelMap<typeof timeLogColumnGroups>

const labelKeyByTimeLogColumnGroupId = {
  'approval-group': 'common.approvalGroup',
  'linked-entity': 'features.reporting.timeLog.linkedEntity',
  'pipeline-step': 'common.pipelineStep',
  'time-card': 'features.reporting.timeCard',
  project: 'common.project',
  task: 'features.reporting.timeLog.task',
  'time-log': 'features.reporting.timeLog.timeLog',
  user: 'features.reporting.user',
  department: 'common.department',
  facility: 'common.facility',
  absence: 'features.reporting.timeLog.absence',
  holiday: 'features.reporting.timeLog.holiday',
} as const

const labelKeyByAuditLogColumnGroupId = {
  event: 'features.reporting.auditLog.event',
  actor: 'features.reporting.auditLog.actor',
  'target-entity': 'features.reporting.auditLog.targetEntity',
  'target-user': 'features.reporting.auditLog.targetUser',
  target: 'features.reporting.auditLog.target',
  'field-edited': 'features.reporting.auditLog.fieldEdited',
  facility: 'common.facility',
} as const

export const labelKeyByColumnGroupId = <RT extends keyof ReportTypeMap>({
  reportType,
  groupId,
}: {
  reportType: RT
  groupId: ReportTypeMap[RT]['groupId']
}) => {
  if (reportType === 'time_log') {
    return labelKeyByTimeLogColumnGroupId[
      groupId as ReportTypeMap['time_log']['groupId']
    ]
  }

  if (reportType === 'audit_log') {
    return labelKeyByAuditLogColumnGroupId[
      groupId as ReportTypeMap['audit_log']['groupId']
    ]
  }

  // The eslint rule is smarter than TS in this instance :)
  // If we remove this throw, TS claims this function can return undefined,
  // which it absolutely cannot based on the restrictive reportType.
  // However, when we add this throw, eslint then (correctly)
  // complains that reportType is type never!
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Unsupported reportType: ${reportType}`)
}
