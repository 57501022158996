import { memo, MouseEventHandler } from 'react'
import { Tr, Td } from 'components/tables'
import { Row as TRow, flexRender } from '@tanstack/react-table'
import { WorkerApprovalsAndApprovalDomain } from '../../types'
import { DayCellValue } from '../types'
import { twMerge } from 'tailwind-merge'

interface Props {
  row: TRow<WorkerApprovalsAndApprovalDomain>
  onApprovalCellClick: (approval: Approval) => void
  onApprovalCellAltClick: (approval: Approval) => void
  onApprovalCellRightClick: (
    pos: MousePosition,
    { approval, worker }: { approval: Approval; worker: TWorker },
  ) => void
  selectedApprovals: Approval[]
  sidebarFocusedApprovalId?: number
}

// NOTE: This component is memoized so that we can carefully control re-renders
//       so that we do not re-render every row when the selectedApprovals change.
export const Row = memo<Props>(
  ({
    row,
    onApprovalCellClick,
    onApprovalCellAltClick,
    onApprovalCellRightClick,
    selectedApprovals,
    sidebarFocusedApprovalId,
  }) => (
    <Tr virtualized={true} estimatedHeight={64}>
      {row.getVisibleCells().map((cell) => {
        let onLeftClick: MouseEventHandler<HTMLTableCellElement> | undefined,
          onRightClick
        let selected = false
        let sidebarFocused = false

        const isDateColumn = cell.column.id.startsWith('date')

        if (isDateColumn) {
          const { approval, worker } = cell.getValue<DayCellValue>()

          if (approval !== undefined) {
            onLeftClick = (event) => {
              if (event.getModifierState('Alt')) {
                onApprovalCellAltClick(approval)
              } else {
                onApprovalCellClick(approval)
              }
            }
            onRightClick = (pos: MousePosition) =>
              onApprovalCellRightClick(pos, { approval, worker })
            selected =
              selectedApprovals.find(({ id }) => approval.id === id) !==
              undefined
            sidebarFocused = sidebarFocusedApprovalId === approval.id
          }
        }

        return (
          <Td
            className={twMerge(
              'h-16',
              isDateColumn && 'p-0',

              // Scroll margin top/bottom set to height of fixed header/footer
              sidebarFocused && 'scroll-mt-[86px] scroll-mb-[63px]',
            )}
            key={cell.id}
            onClick={onLeftClick}
            onRightClick={onRightClick}
            selected={selected}
            focused={sidebarFocused}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        )
      })}
    </Tr>
  ),
  (oldProps, newProps) => {
    // Default React prop comparison behavior for all props except selectedApprovals
    for (const key of Object.keys(oldProps)) {
      if (key === 'selectedApprovals') continue

      if (
        !Object.is(oldProps[key as keyof Props], newProps[key as keyof Props])
      )
        return false
    }

    // Custom prop comparison behavior for selectedApprovals,
    // consider props unchanged if the old and new props
    // have the same selectedApprovals by ID, regardless of order
    if (
      oldProps.selectedApprovals.length !== newProps.selectedApprovals.length ||
      oldProps.selectedApprovals.some(
        (a) =>
          newProps.selectedApprovals.find((b) => a.id === b.id) === undefined,
      )
    )
      return false

    return true
  },
)

Row.displayName = 'Row'
