import { useState } from 'react'

const useToggle = (
  initialState: boolean,
): [boolean, (state?: boolean) => void] => {
  const [toggleValue, setToggleValue] = useState(initialState)

  const toggler = (state?: boolean) => {
    setToggleValue((value) => (state === undefined ? !value : state))
  }
  return [toggleValue, toggler]
}

export default useToggle
