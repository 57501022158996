import { FC, useEffect, useMemo, useState } from 'react'
import { Group } from '../components/Group'
import { EnrichedTask, Group as IGroup, SubGroup as ISubGroup } from '../types'
import { SearchField } from 'components/form/search-field'
import { Props as ItemProps } from '../components/Item'
import { enrichTasks, includes, itemForTask } from '../helpers'
import { useAssignedTasksQuery } from '@features/time-logging/hooks/useTasks'
import { Spinner } from 'components/loaders'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useSet } from '@hooks/useSet'
import { IconButton, MenuButton } from 'components/buttons'
import { faSquareMinus, faSquarePlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const parseLocalStorageResult = (
  result: ReturnType<Storage['getItem']>,
): number[] => {
  if (result === null) return []

  try {
    const parsed = JSON.parse(result) as number[]
    return parsed.every((i) => typeof i === 'number') ? parsed : []
  } catch {
    return []
  }
}

type TasksByDisplayLabel = Record<string, EnrichedTask[]>

const groupByDisplayLabel = (
  groupedRef: TasksByDisplayLabel,
  task: EnrichedTask,
): TasksByDisplayLabel => {
  const grouped = { ...groupedRef }
  /**
   * The normalized display label is important for sorting, grouping, and
   * displaying the "display path".  I'm utilizing the "---" characters/delimeter
   * so that it can be easily split later in massageIntoSubGroups below.
   *
   * It's a bit smelly, I know, but it's a light touch to existing code.
   *
   * - AF 9/30/24
   */
  const normalizedDisplayLabel = task.displayLabel
    .slice(0, 2)
    .filter((s) => s)
    .join('---')
  const tasksByDisplayLabel = grouped[normalizedDisplayLabel] ?? []
  grouped[normalizedDisplayLabel] = [...tasksByDisplayLabel, task]
  return grouped
}

const massageIntoSubGroups = (
  tasksByDisplayLabel: TasksByDisplayLabel,
): ISubGroup[] => {
  return Object.entries(tasksByDisplayLabel).map(([displayLabel, tasks]) => ({
    displayPath: displayLabel.split('---'),
    items: tasks.map((t) => itemForTask(t)),
  }))
}

const grabTasksFromProjectAsSubGroup = (
  tasks: EnrichedTask[],
  projectId: number,
): ISubGroup[] => {
  const tasksByDisplayLabel = tasks
    .filter((task) => task.project.id === projectId)
    .reduce(groupByDisplayLabel, {})

  return massageIntoSubGroups(tasksByDisplayLabel)
}

const massageIntoGroups = (tasks: EnrichedTask[]): IGroup[] => {
  const projects = tasks.reduce<Task['project'][]>((acc, task) => {
    if (acc.find((project) => project.id === task.project.id)) return acc

    return [...acc, task.project]
  }, [])

  return projects
    .map((project) => ({
      id: project.id,
      name: project.name,
      subGroups: grabTasksFromProjectAsSubGroup(tasks, project.id).sort(
        (a, b) =>
          (a.displayPath?.at(0) ?? a.name ?? '') >
          (b.displayPath?.at(0) ?? b.name ?? '')
            ? 1
            : -1,
      ),
    }))
    .sort((projectA, projectB) =>
      projectA.name.toUpperCase() > projectB.name.toUpperCase() ? 1 : -1,
    )
}

const filterTasks = (tasks: EnrichedTask[] | undefined, search: string) => {
  if (tasks === undefined) return []

  const includesSearch = includes(search.toLowerCase().trim())

  return tasks.filter((task) => {
    if (includesSearch(task.name)) return true
    if (task.displayLabel.some(includesSearch)) return true
    if (includesSearch(task.project.name)) return true
    if (includesSearch(task.id.toString())) return true

    return false
  })
}

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: ItemProps['onItemClick']
  selectedTaskIds: Set<number>
}

export const MyTasks: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const tasksQuery = useAssignedTasksQuery({ enabled: !hidden })
  const userId = useUserId()

  const {
    set: collapsedProjects,
    add: collapseProject,
    remove: expandProject,
  } = useSet<number>()

  const localStorageKey = useMemo(
    () => `collapsed-projects-${userId}`,
    [userId],
  )

  // Initialize collapsed project ids
  useEffect(() => {
    const result = localStorage.getItem(localStorageKey)
    const collapsed = parseLocalStorageResult(result)
    collapsed.forEach(collapseProject)
  }, [collapseProject, localStorageKey])

  // Store updated collapsed project ids
  useEffect(() => {
    const collapsedStr = JSON.stringify(Array.from(collapsedProjects))
    localStorage.setItem(localStorageKey, collapsedStr)
  }, [collapsedProjects, localStorageKey])

  const tasks: EnrichedTask[] = useMemo(() => {
    if (tasksQuery.data === undefined) return []

    return enrichTasks(tasksQuery.data, disabledTaskIds, selectedTaskIds)
  }, [tasksQuery.data, disabledTaskIds, selectedTaskIds])

  const filteredTasks = useMemo(
    () => filterTasks(tasks, search),
    [tasks, search],
  )

  const groups = useMemo(() => {
    return massageIntoGroups(filteredTasks)
  }, [filteredTasks])

  const expandCollapseMenuItems = useMemo(() => {
    const someCollapsed = groups.some(({ id }) => collapsedProjects.has(id))
    const allCollapsed = groups.every(({ id }) => collapsedProjects.has(id))

    return [
      {
        content: (
          <>
            <FontAwesomeIcon icon={faSquarePlus} className="mr-1" />{' '}
            {t('features.timeLogging.taskSelection.expandAll')}
          </>
        ),
        onClick: () => groups.forEach(({ id }) => expandProject(id)),
        disabled: !someCollapsed,
      },
      {
        content: (
          <>
            <FontAwesomeIcon icon={faSquareMinus} className="mr-1" />{' '}
            {t('features.timeLogging.taskSelection.collapseAll')}
          </>
        ),
        onClick: () => groups.forEach(({ id }) => collapseProject(id)),
        disabled: allCollapsed,
      },
    ]
  }, [groups, collapsedProjects, expandProject, collapseProject, t])

  if (hidden) return null

  if (tasksQuery.data === undefined) {
    return (
      <div className="px-4 text-center">
        <Spinner className="text-3xl" />
      </div>
    )
  }

  return (
    <div className="px-9">
      <div className="flex flex-row items-center">
        <SearchField
          autoFocus={true}
          value={search}
          onChange={setSearch}
          placeholder={t('features.timeLogging.taskSelection.searchMyTasks')}
        />
        <MenuButton
          button={({ toggleMenuHidden }) => (
            <IconButton onClick={toggleMenuHidden} iconProps={{ size: 'lg' }} />
          )}
          className="ml-2"
          menuItems={expandCollapseMenuItems}
        />
      </div>
      {groups.map((group) => (
        <Group
          collapsed={collapsedProjects.has(group.id)}
          key={group.name}
          name={group.name}
          onCollapse={() => collapseProject(group.id)}
          onExpand={() => expandProject(group.id)}
          onItemClick={onTaskClick}
          subGroups={group.subGroups}
        />
      ))}
    </div>
  )
}
