import { useContext, useMemo } from 'react'
import { SearchStateContext } from '../providers/SearchStateProvider'
import { Column } from '../types'

export const useSearchState = (column: Column) => {
  const { current, debounced, setSearch, errors } =
    useContext(SearchStateContext)

  const columnSearch = useMemo(() => current[column], [current, column])
  const columnDebounced = useMemo(() => debounced[column], [debounced, column])
  const columnError = useMemo(() => errors[column], [errors, column])

  return useMemo(
    () => ({
      error: columnError,
      search: columnSearch,
      debounced: columnError ? '' : columnDebounced,
      setSearch: (search: string) => setSearch(column, search),
    }),
    [columnSearch, columnDebounced, setSearch, column, columnError],
  )
}
