import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { TimeWidget } from 'components/form/time-widget'
import { FC, ReactNode, useCallback, useRef, useState } from 'react'
import GridCell from '../table/cells/GridCell'
import { Display } from '../table/cells/Display'
import classNames from 'classnames'
import { useSettings } from '@hooks/useSettings'
import { useInhibitKeyPressOverride } from '@hooks/useInhibitKeyPressOverride'
import { MealCheckbox } from './components/MealCheckbox'

interface TimeWidgetContainerProps {
  children: ReactNode
  hidden: boolean
  onClose: () => void
}

const TimeWidgetContainer: FC<TimeWidgetContainerProps> = ({
  children,
  hidden,
  onClose,
}) => {
  const ref = useRef(null)
  useOnClickOutside(ref, onClose)

  if (hidden) return null

  return (
    <div
      className={[
        'absolute right-0 top-7 w-[360px] drop-shadow-lg z-20',
        'will-change-[filter]', // Fix for shadow persisting after element is removed from DOM (hidden) on Safari
      ].join(' ')}
      ref={ref}
    >
      {children}
    </div>
  )
}

const getInitialIsMeal = (
  obj:
    | Pick<TimeAndDurationVariantProps, 'isMeal' | 'isMealByDefault'>
    | Record<string, never>,
) => {
  if (!('isMeal' in obj)) return false

  return obj.isMeal || obj.isMealByDefault
}

interface BaseProps {
  autoFocusedTimeWidgetAdjuster: 'start' | 'end'
  date: DateTime
  editable: boolean
  endAtSecondsSinceMidnight: number | null
  fieldPrefix?: string
  formattedContents: string
  onHoverLeave: () => void
  onHoverOver: () => void
  rowIndex: number
  startAtSecondsSinceMidnight: number | null
  testId?: string
  timeZone: string
}

type TimeVariantProps = BaseProps & {
  onSubmit: (
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
  ) => void
  variant: 'time'
}

type TimeAndDurationVariantProps = BaseProps & {
  isMeal: boolean
  isMealByDefault: boolean
  onSubmit: (
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
    isMeal: boolean,
  ) => void
  showIsMealCheckbox: boolean
  variant: 'time-and-duration'
}

export type WorkDayCellProps = TimeVariantProps | TimeAndDurationVariantProps

export const WorkDayCell: FC<WorkDayCellProps> = ({
  autoFocusedTimeWidgetAdjuster,
  date,
  editable,
  endAtSecondsSinceMidnight,
  fieldPrefix,
  formattedContents,
  onHoverOver,
  onHoverLeave,
  onSubmit,
  rowIndex,
  startAtSecondsSinceMidnight,
  testId,
  timeZone,
  variant,
  ...props
}) => {
  const [timeWidgetHidden, setTimeWidgetHidden] = useState(true)
  const useSettingsQueryResult = useSettings()
  const { registerMenuClosed, registerMenuOpened } =
    useInhibitKeyPressOverride()
  const isInitiallyMeal = getInitialIsMeal(props)
  const [isMeal, setIsMeal] = useState(isInitiallyMeal)

  const close = useCallback(() => {
    registerMenuClosed('time-widget')
    setTimeWidgetHidden(true)
  }, [setTimeWidgetHidden, registerMenuClosed])

  const open = () => {
    registerMenuOpened('time-widget')
    setTimeWidgetHidden(false)
  }

  return (
    <GridCell
      date={date}
      editable={editable}
      rowIndex={rowIndex}
      onMouseMove={onHoverOver}
      onMouseLeave={onHoverLeave}
      onClick={() => editable && open()}
      classes={classNames('relative', { 'cursor-pointer': editable })}
      testId={testId}
    >
      <TimeWidgetContainer
        hidden={timeWidgetHidden}
        onClose={() => {
          close()
          setIsMeal(isInitiallyMeal)
        }}
      >
        <TimeWidget
          autoFocusedAdjuster={autoFocusedTimeWidgetAdjuster}
          dateEditing={date}
          displayMode={
            useSettingsQueryResult.data?.timeFormat === '24_hour' ? '24' : '12'
          }
          endAtSecondsSinceMidnight={endAtSecondsSinceMidnight}
          fieldPrefix={fieldPrefix}
          onSubmit={(
            startAtSecondsSinceMidnight,
            endAtSecondsSinceMidnight,
          ) => {
            if (variant === 'time') {
              onSubmit(startAtSecondsSinceMidnight, endAtSecondsSinceMidnight)
            } else {
              onSubmit(
                startAtSecondsSinceMidnight,
                endAtSecondsSinceMidnight,
                isMeal,
              )
            }
            close()
          }}
          renderTopRight={() => {
            if (variant !== 'time-and-duration') return null
            if (!('showIsMealCheckbox' in props)) return null
            if (!props.showIsMealCheckbox) return null

            return <MealCheckbox isMeal={isMeal} onChange={setIsMeal} />
          }}
          startAtSecondsSinceMidnight={startAtSecondsSinceMidnight}
          timeZone={timeZone}
          variant={variant}
        />
      </TimeWidgetContainer>
      <Display contents={formattedContents} />
    </GridCell>
  )
}
