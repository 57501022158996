import { useSaveSettings, useSettings } from '@hooks/useSettings'
import { FC, useMemo, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import { toast } from 'lib/toasts'
import { useTranslation } from 'react-i18next'

interface SettingsOutletContext {
  changeValues: (settings: Partial<Settings>) => void
  dirty: boolean
  pending: boolean
  values: Partial<Settings>
  save: () => void
}

export const Settings: FC = () => {
  const [dirty, setDirty] = useState(false)
  const [formValues, setFormValues] = useState<Partial<Settings>>({})
  const { t } = useTranslation()

  const useSettingsQueryResult = useSettings()
  const saveSettingsMutation = useSaveSettings()

  const changeValues = (values: Partial<Settings>) => {
    setFormValues((oldValues) => ({ ...oldValues, ...values }))
    setDirty(true)
  }

  const values = useMemo(() => {
    const settings = useSettingsQueryResult.data ?? {}
    return { ...settings, ...formValues }
  }, [useSettingsQueryResult.data, formValues])

  const save = async () => {
    try {
      await saveSettingsMutation.mutateAsync(values)
      setDirty(false)
      setFormValues({})
      toast({
        content: t('features.settings.successfullySaveSettings'),
        title: t('features.settings.saveSuccessful'),
        variant: 'success',
      })
    } catch {
      toast({
        content: t('features.settings.thereWasAnErrorWhenSavingSettings'),
        title: t('features.settings.saveUnsuccessful'),
        variant: 'error',
      })
    }
  }

  const outletContext: SettingsOutletContext = {
    dirty,
    changeValues,
    pending:
      useSettingsQueryResult.isFetching || saveSettingsMutation.isLoading,
    save: () => void save(),
    values,
  }

  return <Outlet context={outletContext} />
}

export const useSettingsOutletContext = () => {
  return useOutletContext<SettingsOutletContext>()
}
