import { FC, HtmlHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const Box: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div
    className={twMerge(
      ['flex flex-col bg-white border rounded border-neutral-300'],
      className,
    )}
    {...props}
  />
)
