import { searchTasksUnscoped, searchTasks } from '@lib/api'
import { searchTasksKeys } from '@lib/keys'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const useSearchTasksUnscoped = (
  options: SearchTasksUnscopedOptions,
  { enabled }: { enabled: boolean },
) => {
  return useQuery(
    searchTasksKeys.unscoped(options),
    () => searchTasksUnscoped(options),
    { enabled },
  )
}

export const useSearchTasks = ({
  enabled = true,
  ...options
}: SearchTaskOptions & { enabled?: boolean }) => {
  return useInfiniteQuery({
    queryKey: searchTasksKeys.search(options),
    queryFn: ({ pageParam }) =>
      searchTasks({ ...options, after: pageParam as string | undefined }),
    getNextPageParam: (lastPage) => lastPage.meta?.nextPage,
    getPreviousPageParam: (lastPage) => lastPage.meta?.previousPage,
    enabled,
  })
}
