import { FC } from 'react'
import { ReportTypeMap } from '../../../types'
import { twMerge } from 'tailwind-merge'
import { Group } from './Group'

type ReportTypeProps<RT extends keyof ReportTypeMap> = {
  reportType: RT
  columnGroups: {
    id: ReportTypeMap[RT]['groupId']
    columns: ReportTypeMap[RT]['columnId'][]
  }[]
  onColumnSelectDeselect: (columnId: ReportTypeMap[RT]['columnId']) => void
}

type Props = {
  className?: string
  selected: boolean
  droppable: boolean
  draggingEnabled: boolean
} & ReportTypeProps<keyof ReportTypeMap>

export const Columns: FC<Props> = ({
  columnGroups,
  onColumnSelectDeselect,
  className,
  selected,
  droppable,
  draggingEnabled,
  reportType,
}) => {
  return (
    <div className={twMerge('text-neutral-900', className)}>
      {columnGroups.map(({ id, columns }, groupIndex) => (
        <Group
          key={groupIndex}
          groupId={id}
          columns={columns}
          onColumnSelectDeselect={onColumnSelectDeselect}
          selected={selected}
          droppable={droppable}
          draggingEnabled={draggingEnabled}
          reportType={reportType}
        />
      ))}
    </div>
  )
}
