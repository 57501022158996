import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const TextArea: FC<
  DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
> = ({ className, ...props }) => (
  <textarea
    {...props}
    className={twMerge(
      'w-full p-2 text-sm border-solid rounded border-[1px] border-neutral-400',
      className,
    )}
  />
)
