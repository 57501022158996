function SummaryRow(props: { children?: React.ReactNode; testId?: string }) {
  return (
    <div
      className="grid grid-areas-entries grid-cols-entries"
      data-testid={props.testId}
    >
      {props.children}
    </div>
  )
}

export default SummaryRow
