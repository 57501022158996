import { FC } from 'react'
import { NavItem, NavItemSkeleton } from '../../components/NavItem'
import { Column } from './Column'
import {
  ColumnFilter,
  Column as ColumnType,
  TaskSearchResultItemWithType,
} from './types'
import { pathForItem } from './utils/pathForItem'

interface Props {
  enableSearchRequest?: boolean
  filter: ColumnFilter
  hidden: boolean
  label: string
  onItemClick: (
    item: TaskSearchResultItemWithType,
    viewingAll?: boolean,
  ) => void
  onSkip?: () => void
  onTotalChange?: (total: number) => void
  showing: Exclude<ColumnType, 'root'>
  viewingAll?: boolean
}

export const NavColumn: FC<Props> = ({
  enableSearchRequest,
  filter,
  hidden,
  label,
  onItemClick,
  onSkip,
  onTotalChange,
  showing: type,
  viewingAll = false,
}) => (
  <Column
    enableSearchRequest={enableSearchRequest}
    filter={filter}
    hidden={hidden}
    hideLabel={false}
    label={label}
    onSkip={onSkip}
    onTotalChange={onTotalChange}
    renderItem={(item, clearSearch) => (
      <NavItem
        key={`${item.id ?? ''}-${item.label}`}
        label={item.label}
        onClick={() => {
          onItemClick({ ...item, type }, viewingAll)
          if (viewingAll) clearSearch()
        }}
        path={viewingAll ? pathForItem(item) : undefined}
        showImage={false}
        skip={false}
      />
    )}
    skeletonItem={<NavItemSkeleton />}
    expectedItemHeight={40}
    showing={type}
  />
)
