import { QueryFunctionContext } from '@tanstack/react-query'
import { yyyyMMdd } from '@utils/dates'
import { axiosClient } from 'lib/axios'
import { DateTime } from 'luxon'
import { trim } from '@utils/trim'
import { SavedReport, ReportTypeMap } from '@features/reporting/types'
import {
  savedReportFilterDeserializer,
  SavedReportWithSerializedDateTimes,
} from '@features/reporting/utils/savedReportFilterDeserializer'
import { toDotSnakeCase } from '@utils/toDotSnakeCase'
import { filterApiSerializer } from '@features/reporting/utils/filterApiSerializer'

export const defaultQueryFunction = async <T>({
  queryKey,
}: QueryFunctionContext): Promise<T> => {
  const apiRoute = queryKey.join('')
  return (await axiosClient.get<T>(apiRoute)).data
}

export const searchTimeCards = async (
  userId: number,
  start: DateTime,
  end: DateTime,
): Promise<TimeCard[]> => {
  const response = await axiosClient.get<TimeCard[]>(
    `/users/${userId}/time_card_searches`,
    { params: { startDate: start, endDate: end } },
  )

  return response.data
}

export const searchAdminTimeCards = async (start: DateTime, end: DateTime) => {
  const response = await axiosClient.get<AdminTimeCard[]>(
    '/admin/time_card_searches',
    { params: { startDate: start, endDate: end } },
  )

  return response.data
}

export const updateTimeCard = async (options: {
  userId: number
  id: number
  startAt: number | null
  endAt: number | null
}) => {
  const partialCard = { startAt: options.startAt, endAt: options.endAt }
  return axiosClient.put(
    `/users/${options.userId}/time_cards/${options.id}`,
    partialCard,
  )
}

export const postBreak = (options: {
  timeCardId: number
  startAt: number | null
  endAt: number | null
  isMeal: boolean
}) => {
  return axiosClient.post(`/time_cards/${options.timeCardId}/breaks`, {
    startAt: options.startAt,
    endAt: options.endAt,
    isMeal: options.isMeal,
  })
}

export const updateBreak = ({
  breakId,
  startAt,
  endAt,
  isMeal,
}: {
  breakId: number
  startAt: number | null
  endAt: number | null
  isMeal: boolean
}) => {
  return axiosClient.put(`/breaks/${breakId}`, { startAt, endAt, isMeal })
}

export const deleteBreak = (breakId: number) => {
  return axiosClient.delete(`/breaks/${breakId}`)
}

export const postTimeCard = (userId: number, date: DateTime) => {
  const card = { date: yyyyMMdd(date) }
  return axiosClient.post(`/users/${userId}/time_cards`, card)
}

export const getEntriesByTimeCardId = async (timeCardId: number) => {
  const response = await axiosClient.get<Omit<Entry, 'date'>[]>(
    `/time_cards/${timeCardId}/entries`,
  )

  return response.data
}

export const searchEntries = async (
  userId: number,
  start: DateTime,
  end: DateTime,
) => {
  const response = await axiosClient.get<Entry[]>(
    `/users/${userId}/time_log_entry_searches`,
    { params: { startDate: start, endDate: end } },
  )
  return response.data
}

interface upsertOrDeleteEntryArgs {
  entry: Entry
  stateHandler?: React.Dispatch<React.SetStateAction<number>>
}

const deleteEntry = (entry: Entry) => {
  return axiosClient.delete(`/time_cards/${entry.timeCard.id}/entries/delete`, {
    params: {
      taskId: entry.task.id,
    },
  })
}

const upsertEntry = async (entry: Entry) => {
  return axiosClient.put<Entry>(
    `/time_cards/${entry.timeCard.id}/entries/upsert`,
    {
      duration: entry.duration,
      taskId: entry.task.id,
    },
  )
}

export const upsertOrDeleteEntry = ({ entry }: upsertOrDeleteEntryArgs) => {
  if (entry.duration === 0) {
    return deleteEntry(entry)
  } else {
    return upsertEntry(entry)
  }
}

export const deleteTimeCard = async (options: {
  userId: number
  timeCardId: number
}) => {
  return axiosClient.delete(
    `/users/${options.userId}/time_cards/${options.timeCardId}`,
  )
}

export const submitTimeCard = async (options: {
  userId: number
  timeCardId: number
}) => {
  return axiosClient.post(
    `/users/${options.userId}/time_cards/${options.timeCardId}/submit`,
  )
}

export const unsubmitTimeCard = async (options: {
  userId: number
  timeCardId: number
}) => {
  return axiosClient.post(
    `/users/${options.userId}/time_cards/${options.timeCardId}/unsubmit`,
  )
}

export const getAssignedTasks = async (userId: number) => {
  const response = await axiosClient.get<Task[]>(
    `/users/${userId}/tasks/assigned`,
  )
  return response.data
}

export const getSelectedTasks = async (
  userId: number,
  startDate: DateTime,
  endDate: DateTime,
) => {
  const response = await axiosClient.get<Task[]>(
    `/users/${userId}/task_selection_searches`,
    { params: { startDate, endDate } },
  )
  return response.data
}

export const selectTask = (
  userId: number,
  startDate: DateTime,
  taskId: number,
) => {
  return axiosClient.post(`/users/${userId}/tasks/${taskId}/select`, null, {
    params: { startDate },
  })
}

export const deselectTask = (
  userId: number,
  startDate: DateTime,
  taskId: number,
) => {
  return axiosClient.delete(`/users/${userId}/tasks/${taskId}/deselect`, {
    params: { startDate },
  })
}

export const getNonProjectTasks = async () => {
  const response = await axiosClient.get<NonProjectTask[]>('/non_project_tasks')
  return response.data
}

const normalizeQueryString = (q: string | undefined) => {
  if (q === undefined) return undefined
  const trimmed = trim(q)
  if (trimmed === '') return undefined

  return trimmed
}

export const searchTasksUnscoped = async ({
  q,
}: SearchTasksUnscopedOptions): Promise<UnscopedTaskSearchResult> => {
  return (
    await axiosClient.get<UnscopedTaskSearchResult>('/shotgrid/multisearch', {
      params: { q: normalizeQueryString(q) },
    })
  ).data
}

export const searchTasks = async (
  options: SearchTaskOptions & { after?: string },
): Promise<Paginated<TaskSearchResultItem>> => {
  return (
    await axiosClient.get<Paginated<TaskSearchResultItem>>('/shotgrid/search', {
      params: { ...options, q: normalizeQueryString(options.q) },
    })
  ).data
}

export const getMe = async () => {
  return (await axiosClient.get<Me>('/users/me')).data
}

export const login = (googleJwt: string) => {
  return axiosClient.post('/login', { token: googleJwt })
}

export const logout = () => {
  return axiosClient.post('/logout')
}

// NOTE: This returns both Leave and Time Off and should eventually be renamed
export const searchAbsences = async (
  userId: number,
  startDate: DateTime,
  endDate: DateTime,
  signal?: AbortSignal,
) => {
  const response = await axiosClient.get<Absence[]>(
    `/users/${userId}/absence_searches`,
    { params: { startDate, endDate }, signal },
  )
  return response.data
}

export const getSettings = async (userId: number) => {
  const response = await axiosClient.get<Settings>(`/users/${userId}/settings`)
  return response.data
}

export const saveSettings = (userId: number, settings: Settings) => {
  return axiosClient.put(`/users/${userId}/settings`, { settings })
}

export const submitAllTimeCards = async (userId: number) => {
  return (
    await axiosClient.post<{ totalSubmitted: number }>(
      `/users/${userId}/time_cards/submit_all`,
    )
  ).data
}

export const getProjects = async () => {
  const response = await axiosClient.get<Project[]>('/projects')
  return response.data
}

export const getAdminProjects = async () => {
  const response = await axiosClient.get<AdminProject[]>('/admin/projects')
  return response.data
}

export const refreshWorkdayProjects = async () => {
  return axiosClient.post('/admin/projects/refresh_workday')
}

export const searchHolidays = async (
  holidayCalendarId: string,
  startDate: DateTime,
  endDate: DateTime,
) => {
  const response = await axiosClient.get<Holiday[]>(
    `/holiday_calendars/${holidayCalendarId}/holiday_searches`,
    { params: { startDate, endDate } },
  )
  return response.data
}

export const getEmployee = async (userId: number, date: DateTime) => {
  const employee = await axiosClient.get<WorkerWithSchedule>(
    `/users/${userId}/employee`,
    { params: { date } },
  )
  return employee.data
}

export const searchEmployees = async (start: DateTime, end: DateTime) => {
  const employee = await axiosClient.get<TWorker[]>('/employee_searches', {
    params: { startDate: start, endDate: end },
  })
  return employee.data
}

export interface SearchUsersOptions {
  q?: string
  after?: number
}

export const getUsers = async () => {
  const response = await axiosClient.get<User[]>('/users')
  return response.data
}

export const getApprovalDomans = async () => {
  const response = await axiosClient.get<ApprovalDomain[]>('/approval_domains')
  return response.data
}

export const searchEmployeesInApprovalDomain = async (
  approvalDomainId: ApprovalDomain['id'],
  start: DateTime,
  end: DateTime,
) => {
  const response = await axiosClient.get<TWorker[]>(
    `/approval_domains/${approvalDomainId}/employee_searches`,
    { params: { startDate: start, endDate: end } },
  )
  return response.data
}

export const searchApprovals = async (
  start: DateTime,
  end: DateTime,
): Promise<Approval[]> => {
  const response = await axiosClient.get<Approval[]>('/approval_searches', {
    params: { startDate: start, endDate: end },
  })
  return response.data
}

export const approveApprovals = async (approvalIds: number[]) => {
  return axiosClient.post('/approvals/approve', { approvalIds })
}

export const unapproveApprovals = async (approvalIds: number[]) => {
  return axiosClient.post('/approvals/unapprove', { approvalIds })
}

export const getApprovalGroups = async () => {
  const response = await axiosClient.get<ApprovalGroup[]>('/approval_groups')
  return response.data
}

export const getAdminApprovalGroups = async () => {
  const response = await axiosClient.get<AdminApprovalGroup[]>(
    '/admin/approval_groups',
  )
  return response.data
}

export const updateApprovalGroup = async (
  id: number,
  name: string,
  approverUserIds: number[],
) => {
  return axiosClient.put(`/admin/approval_groups/${id}`, {
    name,
    approverUserIds,
  })
}

export const createApprovalGroup = async (
  name: string,
  approverUserIds: number[],
) => {
  return axiosClient.post('/admin/approval_groups', { name, approverUserIds })
}

export const getEmployees = async () => {
  const response = await axiosClient.get<BasicWorker[]>('/employees')
  return response.data
}

export const updateEmployeeApprovalGroup = async (
  workdayWorkerId: number,
  approvalGroupId: number,
) => {
  return axiosClient.put(`/employees/${workdayWorkerId}/approval_group`, {
    approvalGroupId,
  })
}

export const toggleApprovalGroupAutoApproval = async (id: number) => {
  return axiosClient.post(`/admin/approval_groups/${id}/toggle_auto_approval`)
}

export const toggleProjectAutoApproval = async (id: number) => {
  return axiosClient.post(`/admin/projects/${id}/toggle_auto_approval`)
}

export const updateProjectApprovers = async (
  id: number,
  approverUserIds: number[],
) => {
  return axiosClient.post(`/admin/projects/${id}/update_approvers`, {
    approverUserIds,
  })
}

export const deleteNote = async (employeeId: number, date: DateTime) => {
  return axiosClient.delete(
    `/employees/${employeeId}/time_logging_notes/${date.toISODate()}`,
  )
}

export const getNotes = async (
  employeeId: number,
  start: DateTime,
  end: DateTime,
) => {
  const response = await axiosClient.get<{ date: DateTime; body: string }[]>(
    `/employees/${employeeId}/time_logging_notes_searches`,
    /* eslint-disable camelcase */
    { params: { start_date: start.toISODate(), end_date: end.toISODate() } },
    /* eslint-enable camelcase */
  )

  return response.data
}

export const putNote = async (
  employeeId: number,
  date: DateTime,
  note: string,
) => {
  return axiosClient.put(
    `/employees/${employeeId}/time_logging_notes/${date.toISODate()}`,
    { body: note },
  )
}

export const getApprovalTaggedTime = async (approvalId: number) => {
  const response = await axiosClient.get<TaggedTime[]>(
    `/approvals/${approvalId}/tagged_time`,
  )
  return response.data
}

export const getTimeCardTaggedTime = async (timeCardId: number) => {
  const response = await axiosClient.get<TaggedTime[]>(
    `/time_cards/${timeCardId}/tagged_time`,
  )
  return response.data
}

export const getWorkdayTimeBlockErrors = async (timeCardId: number) => {
  const response = await axiosClient.get<WorkdayTimeBlockError[]>(
    `/admin/time_cards/${timeCardId}/workday_time_block_errors`,
  )
  return response.data
}

export const getEmployeeDayEventLog = async (
  employeeId: number,
  date: DateTime,
) => {
  const response = await axiosClient.get<EmployeeDayEventLogEntry[]>(
    `/employees/${employeeId}/day_event_log?date=${date.toISODate()}`,
  )
  return response.data
}

export const getApprovalEventLog = async (approvalId: number) => {
  const response = await axiosClient.get<ApprovalEventLogEntry[]>(
    `/approvals/${approvalId}/event_log`,
  )
  return response.data
}

export type DateFilter<RT extends keyof ReportTypeMap> = {
  column: ReportTypeMap[RT]['pageColumn']
  type: 'date'
  startDate?: DateTime
  endDate?: DateTime
}

/* commenting out until it's used
interface DurationRangeFilter {
  column: string
  type: 'duration_range'
  from?: number // seconds
  to?: number // seconds
}
*/

export type SearchFilter<RT extends keyof ReportTypeMap> = {
  column: ReportTypeMap[RT]['pageColumn']
  type: 'search_query'
  query: string
}

export type ValueFilter<RT extends keyof ReportTypeMap> = {
  column: ReportTypeMap[RT]['pageColumn']
  type: 'value'
  selected: (number | string)[]
}

export type NumericRangeFilter<RT extends keyof ReportTypeMap> = {
  column: ReportTypeMap[RT]['pageColumn']
  type: 'numeric_range'
  from?: number
  to?: number
}

export type ReportFilter<RT extends keyof ReportTypeMap> = (
  | DateFilter<RT>
  | SearchFilter<RT>
  | ValueFilter<RT>
  | NumericRangeFilter<RT>
)[]

export type GetReportPageOptions<RT extends keyof ReportTypeMap> = {
  page?: number
  pageSize?: number
  filters?: ReportFilter<RT>
  sortColumn?: ReportTypeMap[RT]['pageColumn']
  sortDirection?: 'asc' | 'desc'
}

export const getReportPage = async <RT extends keyof ReportTypeMap>(
  reportType: RT,
  options: GetReportPageOptions<RT>,
): Promise<ReportTypeMap[RT]['page']> => {
  // Client uses camelCased column accessor keys, but API expects snake
  const sortColumn = options.sortColumn
    ? toDotSnakeCase(options.sortColumn)
    : undefined

  const filters = options.filters
    ? JSON.stringify(filterApiSerializer(options.filters))
    : undefined

  const response = await axiosClient.get<ReportTypeMap[RT]['page']>(
    `/reports/${reportType}s/data`,
    {
      params: {
        ...options,
        sortColumn,
        filters,
      },
    },
  )

  return response.data
}

export const getPipelineSteps = async () => {
  const response = await axiosClient.get<Step[]>('/shotgrid/pipeline_steps')
  return response.data
}

export const getDepartments = async () => {
  const response = await axiosClient.get<Department[]>('/departments')
  return response.data
}

export const getShotgridEntitySchemas = async () => {
  const response = await axiosClient.get<ShotgridEntitySchema[]>(
    '/shotgrid/entity_schemas',
  )
  return response.data
}

export const getFacilities = async () => {
  const response = await axiosClient.get<Facility[]>('/facilities')
  return response.data
}

export const createCorrectedTimeCard = async ({
  userId,
  date,
}: {
  userId: number
  date: DateTime
}) => {
  return axiosClient.post(`/users/${userId}/corrected_time_cards`, { date })
}

export const deleteCorrectedTimeCard = async ({
  userId,
  timeCardId,
}: {
  userId: number
  timeCardId: number
}) => {
  return axiosClient.delete(
    `/users/${userId}/corrected_time_cards/${timeCardId}`,
  )
}

export const updateCorrectedTimeCard = async (options: {
  userId: number
  id: number
  startAt: number | null
  endAt: number | null
}) => {
  const partialCard = { startAt: options.startAt, endAt: options.endAt }
  return axiosClient.put(
    `/users/${options.userId}/corrected_time_cards/${options.id}`,
    partialCard,
  )
}

export const saveCorrectedTimeCard = async ({
  userId,
  timeCardId,
}: {
  userId: number
  timeCardId: number
}) => {
  return axiosClient.post(
    `/users/${userId}/corrected_time_cards/${timeCardId}/save `,
  )
}

export const searchDayLocks = async (
  userId: number,
  start: DateTime,
  end: DateTime,
): Promise<DayLock[]> => {
  const response = await axiosClient.get<DayLock[]>(
    `/users/${userId}/day_lock_searches`,
    { params: { startDate: start, endDate: end } },
  )

  return response.data
}

export const searchAdminLocks = async (start: DateTime, end: DateTime) => {
  const response = await axiosClient.get<DayLock[]>('/admin/lock_searches', {
    params: { startDate: start, endDate: end },
  })
  return response.data
}

export interface DayLockPayload {
  workdayWorkerId: number
  date: DateTime
}

export const upsertDayLocks = async (
  days: DayLockPayload[],
  type: DayLockType,
) => {
  return axiosClient.put('/admin/locks', {
    days,
    type,
  })
}

export const deleteDayLocks = async (days: DayLockPayload[]) => {
  return axiosClient.delete('/admin/locks', {
    data: {
      days,
    },
  })
}

export const sendTimeCardsToPayroll = async (timeCardIds: number[]) => {
  return axiosClient.post('/admin/time_cards/send_to_payroll', {
    timeCardIds,
  })
}

export const getSavedReports = async () => {
  const { data } = await axiosClient.get<
    SavedReportWithSerializedDateTimes<keyof ReportTypeMap>[]
  >('/saved_reports')

  return data.map(savedReportFilterDeserializer)
}

export const getSavedReport = async (id: number) => {
  const { data } = await axiosClient.get<
    SavedReportWithSerializedDateTimes<keyof ReportTypeMap>
  >(`/saved_reports/${id}`)

  return savedReportFilterDeserializer(data)
}

export const createSavedReport = async ({
  name,
  description,
  columns,
  filters,
  sortColumn,
  sortDirection,
  isPublic,
  type,
}: Pick<
  SavedReport,
  | 'name'
  | 'description'
  | 'columns'
  | 'filters'
  | 'sortColumn'
  | 'sortDirection'
  | 'isPublic'
  | 'type'
>) => {
  const { data, ...response } = await axiosClient.post<
    SavedReportWithSerializedDateTimes<keyof ReportTypeMap>
  >('/saved_reports', {
    name,
    description,
    columns,
    filters,
    sortColumn,
    sortDirection,
    isPublic,
    type,
  })

  return { ...response, data: savedReportFilterDeserializer(data) }
}

export const updatedSavedReport = async ({
  id,
  name,
  description,
  columns,
  filters,
  sortColumn,
  sortDirection,
  isPublic,
}: Pick<
  SavedReport,
  | 'id'
  | 'name'
  | 'description'
  | 'columns'
  | 'filters'
  | 'sortColumn'
  | 'sortDirection'
  | 'isPublic'
>) => {
  const { data, ...response } = await axiosClient.put<
    SavedReportWithSerializedDateTimes<keyof ReportTypeMap>
  >(`/saved_reports/${id}`, {
    name,
    description,
    columns,
    filters,
    sortColumn,
    sortDirection,
    isPublic,
  })

  return { ...response, data: savedReportFilterDeserializer(data) }
}

export const deleteSavedReport = async (id: number) => {
  return await axiosClient.delete(`/saved_reports/${id}`)
}

interface FacilityTimeCardStatusTotals {
  facilityId: number
  timeCardStatusTotals: StatusMap<number>
  timeCardIssues: {
    hasTimeLoggedToUnlinkedProject: number
    hasTimeLoggedToInactiveProject: number
    workdaySendError: number
  }
}

export const getFacilityTimeCardStatusTotals = async (
  start: DateTime,
  end: DateTime,
): Promise<FacilityTimeCardStatusTotals[]> => {
  const response = await axiosClient.get<FacilityTimeCardStatusTotals[]>(
    '/admin/facility_time_card_status_totals',
    {
      params: { startDate: start, endDate: end },
    },
  )

  return response.data
}

export const searchPayPeriods = async (
  start: DateTime,
  end: DateTime,
): Promise<PayPeriod[]> => {
  const response = await axiosClient.get<PayPeriod[]>('/pay_period_searches', {
    params: { startDate: start, endDate: end },
  })

  return response.data
}
