import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DayDefaultsRow } from './DayDefaultsRow'

interface Props {
  value: Settings['defaultWorkWeek']
  onChange: (value: Settings['defaultWorkWeek']) => void
}

export const DefaultWorkWeek: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const days: DayOfWeek[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  return (
    <table className="border-separate border-spacing-2">
      <thead>
        <tr className="text-neutral-500 text-xs">
          <th></th>
          <th className="font-medium">{t('common.startTime')}</th>
          <th className="font-medium">{t('common.endTime')}</th>
          <th className="font-medium">
            {t('features.settings.timeLogging.breakStart')}
          </th>
          <th className="font-medium">
            {t('features.settings.timeLogging.breakEnd')}
          </th>
        </tr>
      </thead>
      <tbody>
        {days.map((day) => (
          <DayDefaultsRow
            key={day}
            day={day}
            value={value[day]}
            onChange={(dayValues) => onChange({ ...value, [day]: dayValues })}
          />
        ))}
      </tbody>
    </table>
  )
}
