interface TableProps {
  children?: React.ReactNode
  className?: string
}

export function Table(props: TableProps) {
  const className = props.className || ''
  return (
    <div
      className={`grid auto-rows-fr grid-areas-entries grid-cols-entries ${className}`}
    >
      {props.children}
    </div>
  )
}
