import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { AutoTextSize } from 'auto-text-size'

interface Props {
  numberLabel: number
  timeCard: TimeCard
  className: string
}

export const Header: FC<Props> = ({ timeCard, numberLabel, className }) => {
  const { t } = useTranslation()

  const hasIcon = timeCard.submitted

  return (
    <div className={twMerge('h-8 px-2 flex items-center', className)}>
      {timeCard.submitted && (
        <FontAwesomeIcon icon={faCircleCheck} className="absolute" />
      )}

      <div
        style={{ width: 'calc(100% - 1rem - 8px)' }}
        className={twMerge(hasIcon ? 'ml-auto' : 'mx-auto')}
      >
        <AutoTextSize
          mode="oneline"
          maxFontSizePx={12}
          className={twMerge(
            'font-semibold uppercase w-full',
            !hasIcon && 'text-center',
          )}
          as="h3"
        >
          {t('features.timeLogging.timeCardWithNumber', {
            number: numberLabel,
          })}
        </AutoTextSize>
      </div>
    </div>
  )
}
