import { getSettings, saveSettings } from '@lib/api'
import { settingsKeys } from '@lib/keys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from './useAuth'

const defaultWorkDay = {
  startTime: '09:00',
  endTime: '18:00',
  breakStartTime: '12:30',
  breakEndTime: '13:30',
}

const defaultSettings: Settings = {
  timeFormat: '12_hour',
  copyTaskSelectionsEachWeek: true,
  languageCode: null,
  defaultWorkWeek: {
    sunday: null,
    monday: defaultWorkDay,
    tuesday: defaultWorkDay,
    wednesday: defaultWorkDay,
    thursday: defaultWorkDay,
    friday: defaultWorkDay,
    saturday: null,
  },
  timeLoggingDelegateIds: [],
  approvalDelegateIds: [],
}

export const useSettings = () => {
  const userId = useAuth().user?.id

  if (userId === undefined)
    throw new Error(
      'Unreable.  useSettings can only be used within the portion of the app that is authenticated',
    )

  return useQuery(settingsKeys.global(userId), () => getSettings(userId))
}

export const useSaveSettings = () => {
  const userId = useAuth().user?.id
  const queryClient = useQueryClient()

  return useMutation(
    (settings: Partial<Settings>) => {
      if (userId === undefined)
        throw new Error(
          'Unreachable.  useSaveSettings can only be used with the portion of the app that is authenticated',
        )

      return saveSettings(userId, { ...defaultSettings, ...settings })
    },
    {
      onSuccess: ({ data }) => {
        if (userId !== undefined) {
          queryClient.setQueryData(settingsKeys.global(userId), data)
        }
      },
    },
  )
}
