import { FC, useRef, memo, useMemo, useCallback, useEffect } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  AccessorColumnDef,
  IdentifiedColumnDef,
  AccessorFnColumnDef,
} from '@tanstack/react-table'
import {
  ContextMenu,
  ImperativeHandle as ContextMenuImperativeHandle,
} from 'components/menus'
import { faCalendarClock } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { DayCellValue, TotalResult } from './types'
import { twMerge } from 'tailwind-merge'
import { Status } from 'types'
import {
  DayHeaderCell,
  EmployeeCell,
  ScrollableTable,
  Th,
  TimeStampCell,
  Tr,
} from 'components/tables'
import { DayCell } from './table/DayCell'
import { WorkerApprovalsAndApprovalDomain } from '../types'
import { SearchQuery, ValuePicker } from 'components/tables/column-filters'
import { Row } from './table/Row'
import { groupApprovalsByDomainAndWorker } from '../utils/groupApprovalsByDomainAndWorker'
import { Spinner } from 'components/loaders'
import {
  useKeyboardForGrid,
  NULL_CELL,
  isSameCell,
} from '@hooks/useKeyboardForGrid'

interface Props {
  clearSelections: () => void
  workersApprovalsAndApprovalDomainsAfterFiltering: WorkerApprovalsAndApprovalDomain[]
  workersApprovalsAndApprovalDomainsBeforeFiltering: WorkerApprovalsAndApprovalDomain[]
  deselectApproval: (approvalId: number | number[]) => void
  filteredApprovalDomainIds: Set<string>
  filteredDepartmentIds: Set<string>
  filteredEmployeeName: string
  filteredFacilityIds: Set<number>
  hiddenDays: Set<number>
  hiddenStatuses: Set<Status>
  onApprovalDomainFilterChange: (values: Set<string>) => void
  onDepartmentFilterChange: (values: Set<string>) => void
  onEmployeeNameFilterChange: (name: string) => void
  onFacilityFilterChange: (values: Set<number>) => void
  onHideDays: (weekday: number | number[]) => void
  onShowDays: (weekday: number | number[]) => void
  selectApproval: (approvalId: number | number[]) => void
  selectedApprovalIds: Set<number>
  selectedApprovals: Approval[]
  toggleApproval: (approvalId: number | number[]) => void
  weekRange: DateTime[]
  loading: boolean
  sidebarOpened: boolean
  onFocusedApprovalChange: (
    workerAndApproval:
      | {
          worker: TWorker
          approval: Approval
        }
      | { worker: undefined; approval: undefined },
  ) => void
}

const columnHelper = createColumnHelper<WorkerApprovalsAndApprovalDomain>()

export const Table: FC<Props> = memo(
  ({
    workersApprovalsAndApprovalDomainsAfterFiltering: dataAfterFiltering,
    workersApprovalsAndApprovalDomainsBeforeFiltering: dataBeforeFiltering,
    deselectApproval,
    filteredApprovalDomainIds,
    filteredDepartmentIds,
    filteredEmployeeName,
    filteredFacilityIds,
    hiddenDays,
    onApprovalDomainFilterChange,
    onDepartmentFilterChange,
    onEmployeeNameFilterChange,
    onFacilityFilterChange,
    onHideDays,
    onShowDays,
    selectApproval,
    selectedApprovalIds,
    selectedApprovals,
    toggleApproval,
    weekRange,
    loading,
    sidebarOpened,
    onFocusedApprovalChange,
  }) => {
    const { t } = useTranslation()
    const contextMenuRef = useRef<ContextMenuImperativeHandle>(null)

    const selectedApprovalsByWorkerAndDomain = useMemo(
      () => groupApprovalsByDomainAndWorker(selectedApprovals),
      [selectedApprovals],
    )

    const openContextMenu = useCallback(
      (pos: MousePosition, cb: () => void) => {
        contextMenuRef.current?.open({
          pos,
          item: {
            label: t('common.viewDetails'),
            icon: faCalendarClock,
            onClick: cb,
          },
        })
      },
      [t],
    )

    const employeeCountAfterFiltering = useMemo(() => {
      return new Set(dataAfterFiltering.map((d) => d.worker.workdayWorkerId))
        .size
    }, [dataAfterFiltering])

    const sortedApprovalDomainsBeforeFiltering = useMemo(
      () =>
        [
          ...new Map(
            dataBeforeFiltering.reduce<[string, ApprovalDomain][]>(
              (acc, { approvalDomain }) => {
                if (approvalDomain === undefined) return acc

                return [...acc, [approvalDomain.id, approvalDomain]]
              },
              [],
            ),
          ).values(),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      [dataBeforeFiltering],
    )

    const approvalDomainsAfterFiltering = useMemo(
      () => [
        ...new Map(
          dataAfterFiltering.reduce<[string, ApprovalDomain][]>(
            (acc, { approvalDomain }) => {
              if (!approvalDomain) return acc

              return [...acc, [approvalDomain.id, approvalDomain]]
            },
            [],
          ),
        ).values(),
      ],
      [dataAfterFiltering],
    )

    const sortedDepartmentsBeforeFiltering = useMemo(
      () =>
        [
          ...new Map(
            dataBeforeFiltering.map(({ worker }) => [
              worker.department.id,
              worker.department,
            ]),
          ).values(),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      [dataBeforeFiltering],
    )

    const departmentsAfterFiltering = useMemo(
      () => [
        ...new Map(
          dataAfterFiltering.map(({ worker }) => [
            worker.department.id,
            worker.department,
          ]),
        ).values(),
      ],
      [dataAfterFiltering],
    )

    const sortedFacilitiesBeforeFiltering = useMemo(
      () =>
        [
          ...new Map(
            dataBeforeFiltering.map(({ worker }) => [
              worker.facility.id,
              worker.facility,
            ]),
          ).values(),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      [dataBeforeFiltering],
    )

    const facilitiesAfterFiltering = useMemo(
      () => [
        ...new Map(
          dataAfterFiltering.map(({ worker }) => [
            worker.facility.id,
            worker.facility,
          ]),
        ).values(),
      ],
      [dataAfterFiltering],
    )

    // Totals domain seconds logged and domain seconds tagged
    // across all filtered approvals by date
    // Returned object is keyed by ISO date string
    const totalsByDate = useMemo(() => {
      return dataAfterFiltering.reduce<{
        [date: string]: { total: number; tagged: number }
      }>((acc, { approvals }) => {
        for (const approval of approvals) {
          if (!acc[approval.timeCard.date.toISODate()]) {
            acc[approval.timeCard.date.toISODate()] = {
              total: 0,
              tagged: 0,
            }
          }
          acc[approval.timeCard.date.toISODate()].total +=
            approval.totalDomainSecondsLogged
          acc[approval.timeCard.date.toISODate()].tagged +=
            approval.totalDomainSecondsTagged
        }

        return acc
      }, {})
    }, [dataAfterFiltering])

    const columns = [
      columnHelper.accessor('worker.fullName', {
        id: 'employeeName',
        header: t('common.employeeName'),
        cell: ({ row }) => {
          const approvalIds = row.original.approvals.map(
            (approval) => approval.id,
          )
          const allSelected =
            approvalIds.length > 0 &&
            approvalIds.every((id) => selectedApprovalIds.has(id))
          const someSelected = approvalIds.some((id) =>
            selectedApprovalIds.has(id),
          )

          return (
            <EmployeeCell
              checked={allSelected}
              contigentWorkerType={row.original.worker.contingentWorkerType}
              disabled={approvalIds.length === 0}
              indeterminate={someSelected}
              name={row.original.worker.fullName}
              onChange={() => {
                const operation = allSelected
                  ? deselectApproval
                  : selectApproval
                operation(approvalIds)
              }}
              payType={row.original.worker.payType}
              title={row.original.worker.jobTitle}
            />
          )
        },
        footer: () => {
          const count = employeeCountAfterFiltering

          return (
            <div className="px-3 italic ml-7">
              {t('common.employeesWithCount', { count })}
            </div>
          )
        },
      } as IdentifiedColumnDef<WorkerApprovalsAndApprovalDomain, string>),
      {
        id: 'approvalDomain',
        accessorFn: (row) => row.approvalDomain?.name ?? '',
        header: t('features.approvals.approvalDomain'),
        cell: ({ row, column }) => (
          <span className="px-3">{row.getValue(column.id)}</span>
        ),
        footer: () => {
          return (
            <div className="px-3 italic">
              {t('features.approvals.approvalDomainsWithCount', {
                count: approvalDomainsAfterFiltering.length,
              })}
            </div>
          )
        },
      } as AccessorFnColumnDef<WorkerApprovalsAndApprovalDomain, string>,
      columnHelper.accessor('worker.department.name', {
        id: 'department',
        header: t('common.department'),
        cell: ({ row, column }) => (
          <span className="px-3">{row.getValue(column.id)}</span>
        ),
        footer: () => {
          return (
            <div className="px-3 italic">
              {t('features.approvals.departmentsWithCount', {
                count: departmentsAfterFiltering.length,
              })}
            </div>
          )
        },
      } as IdentifiedColumnDef<WorkerApprovalsAndApprovalDomain, string>),
      columnHelper.accessor('worker.facility.name', {
        id: 'facility',
        header: t('common.facility'),
        cell: ({ row, column }) => (
          <span className="px-3">{row.getValue(column.id)}</span>
        ),
        footer: () => {
          return (
            <div className="px-3 italic">
              {t('common.facilityCount', {
                count: facilitiesAfterFiltering.length,
              })}
            </div>
          )
        },
      } as IdentifiedColumnDef<WorkerApprovalsAndApprovalDomain, string>),
      ...weekRange.map<
        AccessorColumnDef<WorkerApprovalsAndApprovalDomain, DayCellValue>
      >((date) => ({
        id: `date-${date.toISO()}`,
        enableSorting: false,
        header: ({ table, column }) => {
          const approvalIds = table
            .getRowModel()
            .rows.reduce<number[]>((acc, row) => {
              const { approval } = row.getValue<DayCellValue>(column.id)
              return approval ? [...acc, approval.id] : acc
            }, [])

          const allSelected =
            approvalIds.length > 0 &&
            approvalIds.every((id) => selectedApprovalIds.has(id))

          return (
            <DayHeaderCell
              allSelected={() => allSelected}
              date={date}
              hiddenDays={hiddenDays}
              onHide={(weekday) => onHideDays(weekday)}
              onSelectAll={() => selectApproval(approvalIds)}
              onShow={(weekday) => onShowDays(weekday)}
              onUnSelectAll={() => deselectApproval(approvalIds)}
            />
          )
        },
        accessorFn: (row: WorkerApprovalsAndApprovalDomain): DayCellValue => {
          const approval = row.approvals.find((approval) =>
            approval.timeCard.date.hasSame(date, 'day'),
          )
          const worker = row.worker
          return { approval, date, worker }
        },
        cell: ({ getValue }) => {
          const { approval, date, worker } = getValue()

          return (
            <DayCell
              approval={approval}
              date={date}
              holidayCalendarId={worker.holidayCalendarId ?? undefined}
              userId={worker.user.id}
            />
          )
        },
        footer: () => {
          return (
            // totals are cached in totalsByDate instead of summing
            // column accessors to avoid needing to recalc
            // on every table re-render
            <TimeStampCell
              seconds={totalsByDate[date.toISODate()]?.total ?? 0}
              subSeconds={totalsByDate[date.toISODate()]?.tagged ?? 0}
              bolden={true}
            />
          )
        },
      })),
      {
        id: 'totalSeconds',
        header: t('common.total'),
        sortingFn: (
          { getValue: getValueA },
          { getValue: getValueB },
          columnId,
        ) => {
          return getValueA<TotalResult>(columnId).total >
            getValueB<TotalResult>(columnId).total
            ? 1
            : -1
        },
        accessorFn: (row) =>
          row.approvals.reduce<TotalResult>(
            (total, approval) => {
              return {
                total: total.total + approval.totalDomainSecondsLogged,
                tagged: total.tagged + approval.totalDomainSecondsTagged,
              }
            },
            { total: 0, tagged: 0 },
          ),
        cell: ({ getValue, row }) => {
          /**
           * Because react-table is caching each row's data and only recomputing
           * when the row changes (which happens when `data` changes), getValue()
           * is returning an "old" value when `visibleStatuses` changes.  Hence
           * the `delete` statement below.
           *
           * Admittedly, reacing into a library's internals is a very smelly thing
           * to do, but it was decided to be fine in thise case:
           * https://github.com/storied-software/scanline-time-logger/pull/762#issuecomment-1730234875
           *
           * There's an open, related issue regarding cached row values
           * here: https://github.com/TanStack/table/issues/4485#issuecomment-162686729
           *
           * In the event that react-table is updated to include an ability to
           * clear the cache, prevent row caching entirely, or modify `data` each
           * `visibleStatuses` changes without breaking the table, this fix likely
           * won't be needed.
           *
           * - AF 9/22/23
           **/
          delete row._valuesCache['totalSeconds']

          const { total, tagged } = getValue()

          return (
            <TimeStampCell
              className="bg-neutral-100"
              seconds={total}
              subSeconds={tagged}
              bolden={true}
            />
          )
        },
        footer: ({ table, column }) => {
          const { total, tagged } = table
            .getRowModel()
            .rows.reduce<TotalResult>(
              (acc, row) => {
                const rowTotal = row.getValue<TotalResult>(column.id)

                return {
                  total: acc.total + rowTotal.total,
                  tagged: acc.tagged + rowTotal.tagged,
                }
              },
              { total: 0, tagged: 0 },
            )
          return (
            <TimeStampCell seconds={total} subSeconds={tagged} bolden={true} />
          )
        },
      } as AccessorColumnDef<WorkerApprovalsAndApprovalDomain, TotalResult>,
    ]

    const table = useReactTable({
      data: dataAfterFiltering,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    })

    const allApprovalIds = useMemo(() => {
      return dataAfterFiltering.flatMap(({ approvals }) => {
        return approvals.map((approval) => approval.id)
      })
    }, [dataAfterFiltering])

    const allEmployeesSelected =
      selectedApprovalIds.size > 0 &&
      selectedApprovalIds.size === allApprovalIds.length
    const someEmployeesSelected =
      !allEmployeesSelected && selectedApprovalIds.size > 0

    const {
      focusCell,
      focusedCell,
      clearFocus: clearFocusedCell,
    } = useKeyboardForGrid(
      dataAfterFiltering.length,
      weekRange.length,
      useMemo(
        () => ({
          enabled: sidebarOpened,
          isFocusableCell: (cell) => {
            const row = table.getRowModel().rows[cell.y]
            const approval = row.original.approvals.find((a) =>
              a.timeCard.date.hasSame(weekRange[cell.x], 'day'),
            )

            return approval !== undefined
          },
        }),
        [sidebarOpened, table, weekRange],
      ),
    )

    const getCellFromApproval = useCallback(
      (approval: Approval): Cell => {
        const y = table
          .getRowModel()
          .rows.findIndex((row) =>
            row.original.approvals.find((a) => a.id === approval.id),
          )

        const x = weekRange.findIndex((d) =>
          d.hasSame(approval.timeCard.date, 'day'),
        )

        if (y < 0 || x < 0) return NULL_CELL

        return { x, y }
      },
      [table, weekRange],
    )

    const onApprovalCellClick = useCallback(
      (approval: Approval) => toggleApproval(approval.id),
      [toggleApproval],
    )

    const onApprovalCellAltClick = useCallback(
      (approval: Approval) => {
        focusCell(getCellFromApproval(approval))
      },
      [focusCell, getCellFromApproval],
    )

    const onApprovalCellRightClick = useCallback(
      (pos: MousePosition, { approval }: { approval: Approval }) => {
        openContextMenu(pos, () => {
          focusCell(getCellFromApproval(approval))
        })
      },
      [openContextMenu, focusCell, getCellFromApproval],
    )

    const focusedWorkerAndApproval = useMemo(() => {
      if (isSameCell(focusedCell, NULL_CELL)) return {}

      const focusedDate = weekRange[focusedCell.x]
      const focusedRow = table.getRowModel().rows[focusedCell.y]

      // If row no longer exists (eg a change caused the # of rows to decrease),
      // just return an empty obj
      if (focusedRow === undefined) return {}

      const { worker, approvals } = focusedRow.original
      const approval = approvals.find((a) =>
        a.timeCard.date.hasSame(focusedDate, 'day'),
      )

      // If approval no longer exists
      // (eg a change caused there to no longer be an approval at the focused cell coords),
      // just return an empty obj
      if (approval === undefined) return {}

      return { worker, approval }
    }, [table, focusedCell, weekRange])

    // Invoke the onFocusedApprovalChange callback whenever the focused cell changes
    useEffect(
      () => onFocusedApprovalChange(focusedWorkerAndApproval),
      [focusedWorkerAndApproval, onFocusedApprovalChange],
    )

    // Clear the focused cell when the sidebar is closed
    useEffect(() => {
      if (!sidebarOpened) clearFocusedCell()
    }, [sidebarOpened, clearFocusedCell])

    return (
      <>
        <ScrollableTable
          header={table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    wrapperClassName={twMerge(
                      header.id === 'employeeName' && 'w-60 2xl:w-72',
                    )}
                    className="whitespace-nowrap"
                    key={header.id}
                    clickable={header.column.getCanSort()}
                    label={flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    onSelect={() => {
                      const operation = allEmployeesSelected
                        ? deselectApproval
                        : selectApproval
                      operation(allApprovalIds)
                    }}
                    sortDir={header.column.getIsSorted()}
                    selectable={header.id === 'employeeName'}
                    selected={
                      someEmployeesSelected
                        ? 'indeterminate'
                        : allEmployeesSelected
                    }
                  >
                    {header.id === 'employeeName' && (
                      <SearchQuery
                        columnNameTranslationKey="common.employeeName"
                        onChange={(value) =>
                          onEmployeeNameFilterChange(value ?? '')
                        }
                        query={filteredEmployeeName}
                      />
                    )}
                    {header.id === 'approvalDomain' && (
                      <ValuePicker
                        accessor={(approvalDomain) => approvalDomain.id}
                        columnNameTranslationKey="features.approvals.approvalDomain"
                        onChange={onApprovalDomainFilterChange}
                        renderLabel={(approvalDomain) => approvalDomain.name}
                        selected={filteredApprovalDomainIds}
                        values={sortedApprovalDomainsBeforeFiltering}
                        searchable={true}
                        groups={[
                          {
                            id: 'approval_group',
                            label: t('common.approvalGroup'),
                          },
                          { id: 'project', label: t('common.project') },
                        ]}
                        getGroupIdForValue={(approvalDomain) =>
                          approvalDomain.type
                        }
                      />
                    )}
                    {header.id === 'department' && (
                      <ValuePicker
                        accessor={(department) => department.id}
                        columnNameTranslationKey="common.department"
                        onChange={onDepartmentFilterChange}
                        renderLabel={(department) => department.name}
                        selected={filteredDepartmentIds}
                        values={sortedDepartmentsBeforeFiltering}
                        searchable={true}
                      />
                    )}
                    {header.id === 'facility' && (
                      <ValuePicker
                        accessor={(facility) => facility.id}
                        columnNameTranslationKey="common.facilityName"
                        onChange={onFacilityFilterChange}
                        values={sortedFacilitiesBeforeFiltering}
                        renderLabel={(facility) => facility.name}
                        selected={filteredFacilityIds}
                      />
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
          body={
            loading ? (
              <tr>
                <td colSpan={columns.length} className="p-6 text-center">
                  <Spinner className="text-2xl" />
                </td>
              </tr>
            ) : (
              table
                .getRowModel()
                .rows.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    onApprovalCellClick={onApprovalCellClick}
                    onApprovalCellAltClick={onApprovalCellAltClick}
                    onApprovalCellRightClick={onApprovalCellRightClick}
                    selectedApprovals={
                      row.original.approvalDomain
                        ? selectedApprovalsByWorkerAndDomain[
                            row.original.worker.workdayWorkerId
                          ]?.[row.original.approvalDomain?.id]?.approvals ?? []
                        : []
                    }
                    sidebarFocusedApprovalId={
                      sidebarOpened
                        ? focusedWorkerAndApproval.approval?.id
                        : undefined
                    }
                  />
                ))
            )
          }
          footer={table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <td
                  key={header.id}
                  className="bottom-0 font-normal text-left border-t border-r bg-neutral-100 border-neutral-300"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </td>
              ))}
            </Tr>
          ))}
          isPending={false}
          numOfColumns={12}
        />
        <ContextMenu variant="imperative" ref={contextMenuRef} />
      </>
    )
  },
)

Table.displayName = 'Table'
