import { useMemo } from 'react'
import {
  NumericValuesFilterValue,
  StringValuesFilterValue,
  ReportTypeMap,
} from '../types'
import { useTranslation, TFunction } from 'react-i18next'
import { renderNumericRange } from '@utils/renderNumericRange'
import { secondsToHHMM } from '@utils/time'
import {
  isDateRangeFilter,
  isValueFilter,
  isStatusFilter,
  isNumericRangeFilter,
  isSearchQuery,
} from '../utils/filterTypes'
import { useProjects } from '@hooks/useProjects'
import { Spinner } from 'components/loaders'
import { UseQueryResult } from '@tanstack/react-query'
import { usePipelineSteps } from '@hooks/usePipelineSteps'
import { useUsers } from '@hooks/useUsers'
import { useApprovalGroups } from '@hooks/useApprovalGroups'
import { useFacilities } from '@hooks/useFacilities'
import { useLocalizedDateRange } from '@hooks/useDateTimeLocalization'
import { useDepartments } from '@hooks/useDepartments'
import { translationForAuditLogEditField } from '../utils/translationForAuditLogEditField'
import { translationForAuditLogTargetType } from '../utils/translationForAuditLogTargetType'

type Props<RT extends keyof ReportTypeMap> = {
  filters: ReportTypeMap[RT]['filters']
  reportType: RT
}

const EntityValues = ({ values }: { values: string[] }) => {
  return (
    <>
      {values.map((value) => (
        <div
          key={value}
          className="text-ellipsis whitespace-nowrap overflow-hidden"
        >
          {value}
        </div>
      ))}
    </>
  )
}

function FetchedEntityValues<T extends { id: number | string }>({
  ids,
  useQuery,
  labelKey,
}: {
  ids: (number | string)[]
  useQuery: () => UseQueryResult<T[], unknown>
  labelKey: keyof T
}) {
  const { data } = useQuery()

  const selected = useMemo(
    () => data?.filter((e) => ids.includes(e.id)),
    [data, ids],
  )

  if (selected === undefined) return <Spinner />

  return (
    <EntityValues values={selected.map((entity) => String(entity[labelKey]))} />
  )
}

const renderValues = <
  RT extends keyof ReportTypeMap,
  FK extends KeysMatching<
    ReportTypeMap[RT]['filters'],
    StringValuesFilterValue | NumericValuesFilterValue
  >,
>(
  filterKey: FK,
  value: ReportTypeMap[RT]['filters'][FK],
  t: TFunction<'translation', undefined>,
) => {
  switch (filterKey) {
    case 'project.id': {
      // Unfortunatelly need to cast to the appropriate filter value
      // because TypeScript cannot figure it out based on the filter key
      // case statement.
      const ids = (value as ReportTypeMap['time_log']['filters']['project.id'])
        ?.values
      return (
        ids && (
          <FetchedEntityValues
            ids={ids}
            useQuery={useProjects}
            labelKey="name"
          />
        )
      )
    }
    case 'pipelineStep.id': {
      const ids = (
        value as ReportTypeMap['time_log']['filters']['pipelineStep.id']
      )?.values
      return (
        ids && (
          <FetchedEntityValues
            ids={ids}
            useQuery={usePipelineSteps}
            labelKey="code"
          />
        )
      )
    }
    case 'user.id':
    case 'actorUser.id':
    case 'targetUser.id': {
      const ids = (
        value as
          | ReportTypeMap['time_log']['filters']['user.id']
          | ReportTypeMap['audit_log']['filters']['actorUser.id']
          | ReportTypeMap['audit_log']['filters']['targetUser.id']
      )?.values
      return (
        ids && (
          <FetchedEntityValues ids={ids} useQuery={useUsers} labelKey="name" />
        )
      )
    }
    case 'approvalGroup.id': {
      const ids = (
        value as ReportTypeMap['time_log']['filters']['approvalGroup.id']
      )?.values
      return (
        ids && (
          <FetchedEntityValues
            ids={ids}
            useQuery={useApprovalGroups}
            labelKey="name"
          />
        )
      )
    }
    case 'facility.id': {
      const ids = (value as ReportTypeMap['time_log']['filters']['facility.id'])
        ?.values
      return (
        ids && (
          <FetchedEntityValues
            ids={ids}
            useQuery={useFacilities}
            labelKey="name"
          />
        )
      )
    }
    case 'workday.department.id': {
      const ids = (
        value as ReportTypeMap['time_log']['filters']['workday.department.id']
      )?.values
      return (
        ids && (
          <FetchedEntityValues
            ids={ids}
            useQuery={useDepartments}
            labelKey="name"
          />
        )
      )
    }
    case 'eventType': {
      const types = (
        value as ReportTypeMap['audit_log']['filters']['eventType']
      )?.values
      return (
        types && (
          <EntityValues
            values={types.map((type) =>
              t(`features.reporting.auditLog.auditLogEventTypes.${type}`),
            )}
          />
        )
      )
    }
    case 'fieldEdited.name': {
      const types = (
        value as ReportTypeMap['audit_log']['filters']['fieldEdited.name']
      )?.values
      return (
        types && (
          <EntityValues
            values={types.map((field) =>
              t(translationForAuditLogEditField(field)),
            )}
          />
        )
      )
    }
    case 'target.type': {
      const types = (
        value as ReportTypeMap['audit_log']['filters']['target.type']
      )?.values
      return (
        types && (
          <EntityValues
            values={types.map((type) =>
              t(translationForAuditLogTargetType(type)),
            )}
          />
        )
      )
    }
    case 'type': {
      const types = (value as ReportTypeMap['time_log']['filters']['type'])
        ?.values
      return (
        types && (
          <EntityValues
            values={types.map((type) =>
              t(`features.reporting.timeLog.types.${type}`),
            )}
          />
        )
      )
    }
    default: {
      const nonFetchedValue = value as
        | StringValuesFilterValue
        | NumericValuesFilterValue
        | undefined

      return (
        nonFetchedValue?.values && (
          <EntityValues values={nonFetchedValue.values.map(String)} />
        )
      )
    }
  }
}

const columnTranslationKey = <RT extends keyof ReportTypeMap>(
  columnId: ReportTypeMap[RT]['columnId'],
  reportType: RT,
): TranslationKey => {
  switch (reportType) {
    case 'time_log':
      return `features.reporting.timeLog.filterColumns.${
        columnId as keyof ReportTypeMap['time_log']['filters']
      }`
    case 'audit_log':
      return `features.reporting.auditLog.filterColumns.${
        columnId as keyof ReportTypeMap['audit_log']['filters']
      }`
  }

  throw new Error('Invalid report type')
}

export const FilterDetails = <RT extends keyof ReportTypeMap>({
  filters,
  reportType,
}: Props<keyof ReportTypeMap>) => {
  const { t } = useTranslation()
  const renderDateRange = useLocalizedDateRange()

  function renderFilterValues<K extends keyof ReportTypeMap[RT]['filters']>(
    filterKey: K,
    value: ReportTypeMap[RT]['filters'][K],
  ) {
    if (!value) return null

    // Search query
    if (isSearchQuery(value))
      return t('common.containsSearchQuery', { query: value.query })

    // Date range
    if (isDateRangeFilter(value)) {
      if (value.relativeDateRange) {
        return t(`relativeDateRangePicker.values.${value.relativeDateRange}`)
      } else {
        return renderDateRange(value.start, value.end, {
          month: 'short',
          day: 'numeric',
        })
      }
    }

    // Value filter
    if (isValueFilter(value) && value.values) {
      return renderValues(
        filterKey as KeysMatching<
          typeof filters,
          StringValuesFilterValue | NumericValuesFilterValue
        >,
        value as ReportTypeMap[RT]['filters'][KeysMatching<
          typeof filters,
          StringValuesFilterValue | NumericValuesFilterValue
        >],
        t,
      )
    }

    // Status filter (basically a value filter)
    if (isStatusFilter(value))
      return value.statuses.map((status) => (
        <div key={status}>{t(`common.statuses.${status}`)}</div>
      ))

    // Numeric range
    // (all numeric range columns are seconds, so we can assume they should be formatted
    // as such here, but if in the future we added non-second numeric range filtered columns,
    // we'd want to check the filter key here to determine how to format the values)
    if (isNumericRangeFilter(value))
      return renderNumericRange(
        value.numericRangeFrom,
        value.numericRangeTo,
        t,
        secondsToHHMM,
      )
  }

  const sortedFilters = useMemo(
    () =>
      Object.entries(filters).sort(([keyA], [keyB]) =>
        t(columnTranslationKey(keyA as keyof typeof filters, reportType))
          .toString()
          .localeCompare(
            t(
              columnTranslationKey(keyB as keyof typeof filters, reportType),
            ).toString(),
          ),
      ),
    [filters, t, reportType],
  )

  return (
    <div className="w-64 max-h-80 overflow-auto border-b border-neutral-200 pt-2">
      {sortedFilters.map(([filterKey, values]) => (
        <div
          key={filterKey}
          className="border-b border-neutral-200 px-6 py-3 last-of-type:border-b-0"
        >
          <div className="uppercase text-xs text-neutral-600 font-medium mb-1">
            {t(
              columnTranslationKey(
                filterKey as keyof typeof filters,
                reportType,
              ),
            ).toString()}
          </div>

          <div className="text-neutral-900 flex flex-col gap-1">
            {renderFilterValues(
              filterKey as keyof typeof filters,
              values as ReportTypeMap[RT]['filters'][keyof typeof filters],
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
