import { FC, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const Radio: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  className: overrideClassNames,
  ...props
}) => {
  const className = twMerge([
    'inline-block flex items-center border p-[1px] rounded-full border-neutral-400 max-w-min',
    overrideClassNames,
  ])

  return (
    <div className={className}>
      <input
        {...props}
        type="radio"
        className="border-none rounded-full cursor-pointer form-checkbox checked:bg-primary-600 checked:bg-none disabled:cursor-not-allowed indeterminate:bg-none indeterminate:bg-transparent"
        checked={props.checked ?? false}
      />
    </div>
  )
}
