import { FC, ReactNode } from 'react'
import CellText from '../../table/cells/CellText'
import SideCell from '../../table/cells/SideCell'

export const LeftHeader: FC<{
  children?: ReactNode
  highlight: boolean
  labels: string[]
  rowIndex: number
}> = ({ children, highlight, labels, rowIndex }) => {
  return (
    <SideCell rowIndex={rowIndex} highlight={highlight} className="group">
      {labels.map((label, index) => (
        <div
          className="w-1/3 text-xs leading-none 3xl:text-sm last-of-type:font-semibold"
          key={`left-header-cell-text-${index}`}
        >
          <CellText text={label} />
        </div>
      ))}
      {children}
    </SideCell>
  )
}
