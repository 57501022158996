import { ReactNode } from 'react'
import { cellClasses } from '@utils/styles'
import { toLowerCaseDay } from '@utils/dates'

interface GridCellProps {
  children?: ReactNode
  date: DateTime
  rowIndex: number
  classes?: string
  editable?: boolean
  onMouseMove?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onClick?: React.MouseEventHandler
  onDoubleClick?: React.MouseEventHandler
  testId?: string
}

function EditableCell(props: {
  children?: React.ReactNode
  editable?: boolean
}) {
  const editable = props.editable ? true : false
  const editableClass = editable ? 'bg-neutral-150' : ''
  return (
    <div
      className={`flex items-center justify-end grow h-[30px] ${editableClass}`}
    >
      {props.children}
    </div>
  )
}

function GridCell(props: GridCellProps) {
  const column = `col-start-${toLowerCaseDay(props.date)}`
  const classes = props.classes ? props.classes : ''
  const style = {
    gridRowStart: props.rowIndex + 1,
  }

  return (
    <div
      className={`${cellClasses} ${column} ${classes}`}
      data-testid={props.testId}
      style={style}
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <EditableCell editable={props.editable}>{props.children}</EditableCell>
    </div>
  )
}

export default GridCell
