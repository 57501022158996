import { FC, useState, useMemo } from 'react'
import { Sidebar } from 'components/sidebar'
import { TabPicker, Props as TabsProps } from './components/TabPicker'
import { MyTasks } from './tabs/MyTasks'
import NonProject from './tabs/NonProject'
import { useEntries } from '@hooks/useEntries'
import {
  useSelectedTasks,
  useSelectTask,
  useDeselectTask,
} from '@features/time-logging/hooks/useTasks'
import { useFormatStartAndEnd } from '@utils/dates'
import { useTranslation } from 'react-i18next'
import { useEmployeeName } from '@features/time-logging/hooks/useEmployeeName'
import { useWeekDates } from '@hooks/useWeekDates'
import { AllTasks } from './tabs/all-tasks'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'

const getTaskIdsFromEntries = (
  entries: Entry[] | undefined = [],
): Set<number> => {
  const taskIds = new Set<number>()
  entries.forEach((entry) => {
    /**
     * As of this writing, entries are 'deleted' by reducing the duration to 0.
     *
     * - AF 11/28/22
     */
    if (entry.duration > 0) taskIds.add(entry.task.id)
  })
  return taskIds
}

interface Props {
  hidden: boolean
  onClose: () => void
}

export const TaskPickerSidebar: FC<Props> = ({ hidden, onClose }) => {
  const userId = useUserId()
  const entriesQuery = useEntries({ userId })
  const { start, end } = useWeekDates()
  const selectedTasksQuery = useSelectedTasks()
  const { t } = useTranslation()
  const toLocaleString = useDateTimeToLocaleString()
  const formatStartAndEnd = useFormatStartAndEnd(toLocaleString)

  const { isSelf, name } = useEmployeeName()

  const title = isSelf
    ? t('features.timeLogging.myWeeklyTasks')
    : t('features.timeLogging.usersWeeklyTasks', { name })

  const [selectedTab, setSelectedTab] =
    useState<TabsProps['activeTab']>('my-tasks')

  const selectTaskMutation = useSelectTask()
  const deselectTask = useDeselectTask()

  const handleTaskClick = (id: number, checked: boolean) => {
    const mutation = checked ? selectTaskMutation : deselectTask

    mutation.mutate(id)
  }

  const disabledTaskIds = useMemo(() => {
    return getTaskIdsFromEntries(entriesQuery.data)
  }, [entriesQuery.data])

  const selectedTaskIds = useMemo(() => {
    const ids = new Set<number>()
    const selectedTasks = selectedTasksQuery.data ?? []

    selectedTasks.forEach((task) => {
      ids.add(task.id)
    })

    return ids
  }, [selectedTasksQuery.data])

  return (
    <Sidebar
      hidden={hidden}
      onClose={onClose}
      subTitle={formatStartAndEnd(start, end)}
      title={title}
    >
      <TabPicker activeTab={selectedTab} onTabClick={setSelectedTab} />
      <div className="flex-col h-full pt-4 overflow-auto">
        <MyTasks
          disabledTaskIds={disabledTaskIds}
          hidden={selectedTab !== 'my-tasks'}
          onTaskClick={handleTaskClick}
          selectedTaskIds={selectedTaskIds}
        />
        <AllTasks
          disabledTaskIds={disabledTaskIds}
          hidden={selectedTab !== 'all-tasks'}
          onTaskClick={handleTaskClick}
          selectedTaskIds={selectedTaskIds}
        />
        <NonProject
          disabledTaskIds={disabledTaskIds}
          hidden={selectedTab !== 'non-project'}
          onTaskClick={handleTaskClick}
          selectedTaskIds={selectedTaskIds}
        />
      </div>
    </Sidebar>
  )
}
