import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  italicizeTitle?: boolean
  renderRightSlot?: () => ReactNode
  renderTitleSlot?: () => ReactNode
  title?: string
}

export const NewHeader: FC<Props> = ({
  italicizeTitle = false,
  renderRightSlot = () => null,
  renderTitleSlot = () => null,
  title,
}) => {
  return (
    <div className="flex flex-row flex-no-wrap items-center justify-between px-8 pt-4 pb-6 bg-neutral-100 border border-neutral-200">
      <div className="flex flex-row items-center gap-2">
        {title && (
          <h1
            className={twMerge(
              'text-2xl font-medium leading-6 text-neutral-900 mr-2',
              italicizeTitle && 'italic',
            )}
          >
            {title}
          </h1>
        )}
        {renderTitleSlot()}
      </div>
      <div className="flex flex-row items-center gap-3">
        {renderRightSlot()}
      </div>
    </div>
  )
}
