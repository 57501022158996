import { TimeLogColumnGroupId, ReportTypeMap } from '../../../types'
import { FC, useId, useMemo } from 'react'
import { Columns } from './Columns'
import { useDroppable } from '@dnd-kit/core'
import { groupColumns } from '@features/reporting/utils/groupColumns'
import { useTranslation } from 'react-i18next'
import { labelKeyByColumnGroupId } from '../../../lib/labelKeyByColumnGroupId'
import { filterColumnGroupsBySearchQuery } from '../../../utils/filterColumnGroupsBySearchQuery'
import { twMerge } from 'tailwind-merge'

type ReportTypeProps<RT extends keyof ReportTypeMap> = {
  reportType: RT
  columnIds: ReportTypeMap[RT]['columnId'][]
  onColumnAdded: (columnId: ReportTypeMap[RT]['columnId']) => void
}

type Props = {
  searchQuery: string
  draggingEnabled: boolean
} & ReportTypeProps<keyof ReportTypeMap>

export const DeselectedList: FC<Props> = ({
  columnIds,
  onColumnAdded,
  searchQuery,
  draggingEnabled,
  reportType,
}) => {
  const { t } = useTranslation()
  const id = useId()

  const data = {
    type: 'deselected-list',
  }

  const { setNodeRef: droppableRef } = useDroppable({ id, data })

  // Group columns and then sort groups alphabetically
  const sortedGroupedColumns = useMemo(() => {
    const groups = groupColumns(columnIds)

    return Object.entries(groups)
      .map(([id, columns]) => ({
        id: id as TimeLogColumnGroupId,
        columns,
      }))
      .sort(({ id: idA }, { id: idB }) =>
        t(labelKeyByColumnGroupId({ reportType, groupId: idA })).localeCompare(
          t(labelKeyByColumnGroupId({ reportType, groupId: idB })),
        ),
      )
  }, [columnIds, t, reportType])

  const filteredGroupedColumns = useMemo(() => {
    return filterColumnGroupsBySearchQuery({
      groups: sortedGroupedColumns,
      searchQuery,
      t,
      reportType,
    })
  }, [sortedGroupedColumns, searchQuery, t, reportType])

  return (
    <div ref={droppableRef}>
      <Columns
        className={twMerge(
          'py-8',
          filteredGroupedColumns.length > 0 && 'border-t',
        )}
        columnGroups={filteredGroupedColumns}
        onColumnSelectDeselect={onColumnAdded}
        selected={false}
        droppable={false}
        draggingEnabled={draggingEnabled}
        reportType={reportType}
      />
    </div>
  )
}
