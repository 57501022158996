import { FC } from 'react'
import { Header } from '../components/Header'
import { useEmployees } from '../hooks/useEmployees'
import { Table } from '../components/Table'
import { Spinner } from 'components/loaders'

export const EmployeesAdmin: FC = () => {
  const { data: employees } = useEmployees()

  return (
    <div className="flex flex-col flex-grow">
      <Header />

      {employees ? <Table employees={employees} /> : <Spinner />}
    </div>
  )
}
