import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { FC, useRef, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: React.ReactNode
  className?: string
  hidden: boolean
  onClose: () => void
  subTitle?: string
  title?: string
  mode?: 'overlay' | 'block'

  // NOTE: All permutations of available blockBreakpoint values and
  //       class names they're used with must be adedd to Tailwind safelist
  blockBreakpoint?: '2xl' | '3xl'
}

export const Sidebar: FC<Props> = ({
  children,
  className,
  hidden,
  onClose,
  subTitle,
  title,
  mode = 'overlay',
  blockBreakpoint,
}) => {
  const containerRef = useRef(null)

  const handleClickOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      // Only close sidebar on outside clicks in overlay mode
      if (mode !== 'overlay') return

      // Don't close sidebars on right or middle clicks
      if ('buttons' in e && (e.buttons === 2 || e.buttons === 3)) return

      onClose()
    },
    [onClose, mode],
  )

  useOnClickOutside(containerRef, handleClickOutside)

  const overlayClasses = 'fixed top-12 right-0 drop-shadow-lg z-40'

  const blockClasses = [
    'border-l',
    'border-neutral-300',
    'static',
    'drop-shadow-none',
  ]

  return (
    <section
      ref={containerRef}
      className={twMerge(
        'flex flex-col w-[360px] min-w-[360px] bg-white h-[calc(100vh-3rem)]',
        overlayClasses,
        hidden && 'hidden',

        // When blockBreakpoint is specified,
        // block mode is only enabled at the blockBreakpoint breakpoint,
        // when beneath the blockBreakpoint breakpoint, even when block mode is enabled,
        // it will still be an overlay
        mode === 'block' &&
          // NOTE: All permutations of available blockBreakpoint values and class names must be adedd to Tailwind safelist
          blockClasses.map((c) =>
            blockBreakpoint ? `${blockBreakpoint}:${c}` : c,
          ),
        className,
      )}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className="block mt-4 ml-auto mr-4 cursor-pointer text-neutral-600"
        onClick={onClose}
      />
      <div className="px-8 pt-1.5 text-neutral-900">
        {subTitle && <h2 className="text-sm">{subTitle}</h2>}
        {title && <h1 className="text-lg font-semibold">{title}</h1>}
      </div>
      {children}
    </section>
  )
}
