import { useAuth } from '@hooks/useAuth'
import { useUsers } from '@hooks/useUsers'
import { TFunction, useTranslation } from 'react-i18next'
import { FC } from 'react'
import { EntityPicker } from '@features/entity-picker'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons'

const UserItem: FC<{
  name: string
  email: string
  onClick: () => void
  removable?: boolean
  disabled?: boolean
}> = ({ name, email, onClick, removable = false, disabled = false }) => {
  const { t } = useTranslation()

  return (
    <button
      title={name}
      className={twMerge(
        'flex flex-row my-1 border-l-2 border-l-transparent pl-1',
        removable && 'hover:border-l-error-500',
        disabled && 'opacity-50 cursor-not-allowed',
        !disabled && 'hover:border-l-primary-500',
      )}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <span className="w-full text-sm text-left text-hyperlink-blue">
          {name}
        </span>
        <span className="w-full text-xs text-left text-neutral-500">
          {email}
        </span>
      </div>
      {removable && (
        <span className="self-center ml-auto text-xs font-medium text-neutral-500">
          <FontAwesomeIcon icon={faTrashCan} className="mr-1" />
          {t('common.remove')}
        </span>
      )}
    </button>
  )
}

const filter =
  (includeSelf: boolean, signedInUser: Me | null) =>
  (user: User, search: string) => {
    return (
      (includeSelf === true || user.id !== signedInUser?.id) &&
      [user.name, user.email].find((str) =>
        str.toLowerCase().trim().includes(search.toLowerCase()),
      ) !== undefined
    )
  }

const isSelected = (user: User, selectedIds: Set<number>) => {
  return selectedIds.has(user.id)
}

const renderNoItemSelectedFactory = (t: TFunction<'translation', undefined>) =>
  function renderNoItemSelected() {
    return (
      <span className="text-xs text-neutral-500">
        {t('features.userSelection.noUsersSelected')}
      </span>
    )
  }

interface Props {
  className?: string
  includeSelf?: boolean
  label: string
  placeholder: string
  onSelect: (user: User) => void
  onRemove: (user: User) => void
  selectedUserIds: Set<number>
}

export const UserSelection: FC<Props> = ({
  className,
  includeSelf = true,
  label,
  placeholder,
  onSelect,
  onRemove,
  selectedUserIds,
}) => {
  const { t } = useTranslation()
  const { user: signedInUser } = useAuth()

  return (
    <EntityPicker
      className={className}
      filter={filter(includeSelf, signedInUser)}
      label={label}
      placeholder={placeholder}
      isSelected={isSelected}
      renderNoItemSelected={renderNoItemSelectedFactory(t)}
      renderSearchItem={(user, clickHandler) => (
        <UserItem
          name={user.name}
          email={user.email}
          onClick={() => {
            if (selectedUserIds.has(user.id)) return
            onSelect(user)
            clickHandler()
          }}
          disabled={selectedUserIds.has(user.id)}
        />
      )}
      renderSelectedItem={(user) => (
        <UserItem
          key={user.id}
          name={user.name}
          email={user.email}
          onClick={() => onRemove(user)}
          removable={true}
        />
      )}
      selectedIds={selectedUserIds}
      useQuery={useUsers}
    />
  )
}
