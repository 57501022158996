import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import {
  Table,
  EntriesGrid,
  TaskNameColumn,
  TaskTotalColumn,
} from '@features/time-logging/components'
import project from 'assets/project.svg'

interface Props {
  project: Task['project']
  headerHoverHandler: HeaderHoverHandler
  focusedCell: Cell
  rowStart: number
  tasks: Task[]
  onCellSaveViaEnter: () => void
  onCellFocus: (cell: Cell) => void
  readOnly: boolean
}

function AllocationTable(props: Props) {
  const [hoverY, setHoverY] = useState<number>(0)
  const [expanded, setExpanded] = useState<boolean>(true)

  return (
    <div className="mt-2">
      <h3
        className="inline-flex items-center mb-2 text-xs font-medium tracking-wide uppercase cursor-pointer text-neutral-500 leading-4"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          props.project.isAdminProject ? (
            <div className="w-8 h-8 mr-2 rounded-full bg-neutral-100" />
          ) : (
            <img src={project} className="w-8 h-8 mr-2" />
          )
        ) : (
          <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
        )}

        {props.project.name}

        {expanded || <div className="ml-1">({props.tasks.length})</div>}
      </h3>

      {expanded && (
        <Table key={`project_table_${props.project.id}`}>
          <TaskNameColumn tasks={props.tasks} hoverY={hoverY} />
          <EntriesGrid
            project={props.project}
            tasks={props.tasks}
            headerHoverHandler={props.headerHoverHandler}
            yHoverHandler={setHoverY}
            focusedCell={props.focusedCell}
            rowStart={props.rowStart}
            onSaveViaEnter={props.onCellSaveViaEnter}
            onCellFocus={props.onCellFocus}
            readOnly={props.readOnly}
          />
          <TaskTotalColumn tasks={props.tasks} />
        </Table>
      )}
    </div>
  )
}

export default AllocationTable
