import { useMemo, useCallback } from 'react'
import { useKeyboardForGrid } from '@hooks/useKeyboardForGrid'
import AllocationTable from './AllocationTable'
import { useTimeCards } from '@hooks/useTimeCards'
import {
  TotalByDateRow,
  Toggle,
  Section,
} from '@features/time-logging/components'
import { useSelectedTasks } from '@features/time-logging/hooks/useTasks'
import clipboard from 'assets/clipboard.svg'
import { Button } from 'components/buttons/Button'
import { faClipboardCheck } from '@fortawesome/pro-solid-svg-icons'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

const adminProjectId = 0

const groupTaskByProjectId = (tasks: Task[]): Record<number, Task[]> => {
  return tasks.reduce((acc, task) => {
    const groupedProjectId = task.project.isAdminProject
      ? adminProjectId
      : task.project.id
    return {
      ...acc,
      // Group tasks by project ID,
      // consolidating all admin project into special
      // client-only non-project project (adminProject)
      // Sorted alphabetically by display label (linked entity name, pipeline step, and task name)
      [groupedProjectId]: [...(acc[groupedProjectId] ?? []), task].sort(
        (a, b) =>
          a.displayLabel.join(' ').localeCompare(b.displayLabel.join(' ')),
      ),
    }
  }, {} as ReturnType<typeof groupTaskByProjectId>)
}

interface Props {
  dateRange: DateTime[]
  headerHoverHandler: (date: DateTime | null) => void
  onTaskButtonClick: () => void
  readOnly: boolean
}

export function AllocationTables(props: Props) {
  const { t } = useTranslation()

  const adminProject = useMemo(
    () => ({
      id: adminProjectId,
      name: t('features.timeLogging.nonProject'),
      isAdminProject: true,
    }),
    [t],
  )

  const selectedTasksQuery = useSelectedTasks()
  const userId = useUserId()
  const timeCardsQuery = useTimeCards({ userId })

  const loading = useMemo(
    () =>
      selectedTasksQuery.data === undefined ||
      timeCardsQuery.data === undefined,
    [selectedTasksQuery, timeCardsQuery],
  )

  const tasks = useMemo(
    () => selectedTasksQuery.data ?? [],
    [selectedTasksQuery.data],
  )

  const anyTasksSelected = useMemo(() => tasks.length > 0, [tasks])

  const tasksByProjectId = useMemo(() => groupTaskByProjectId(tasks), [tasks])

  const selectedProjects = useMemo<Task['project'][]>(
    () =>
      Object.keys(tasksByProjectId).map<Task['project']>((projectIdString) => {
        const projectId = Number(projectIdString)

        return projectId === adminProject.id
          ? adminProject
          : tasksByProjectId[projectId][0].project
      }),
    [tasksByProjectId, adminProject],
  )

  const rows = tasks.length
  const columns = props.dateRange.length

  const activeColumns = useMemo(() => {
    const timeCards = timeCardsQuery.data || []
    return props.dateRange.map((d) => {
      if (
        timeCards.find(
          (c) => c.id !== 0 && c.date.hasSame(d, 'day') && d <= DateTime.now(),
        )
      ) {
        return d
      }
    })
  }, [props.dateRange, timeCardsQuery.data])

  const isFocusableCell = useCallback(
    (cell: Cell) => activeColumns[cell.x] !== undefined,
    [activeColumns],
  )

  const { focusCell, focusedCell, moveDown } = useKeyboardForGrid(
    rows,
    columns,
    useMemo(
      () => ({
        enableTabNavigation: true,
        isFocusableCell,
      }),
      [isFocusableCell],
    ),
  )

  // Alphabetical order, admin/non-project project last
  const sortedProjects = useMemo(
    () =>
      [...selectedProjects].sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (b.isAdminProject) return -1
        if (a.isAdminProject) return 1
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1

        return 0
      }),
    [selectedProjects],
  )

  const rowStart = (index: number) => {
    return sortedProjects.reduce((res, project, tableIndex) => {
      return tableIndex < index
        ? res + tasksByProjectId[project.id].length
        : res
    }, 0)
  }

  return (
    <Section loading={loading} testId="allocation">
      <Toggle name={t('features.timeLogging.allocation')} initiallyOpen={true}>
        {anyTasksSelected ? (
          sortedProjects.map((project, index) => (
            <AllocationTable
              key={`Project${project.id}`}
              project={project}
              focusedCell={focusedCell}
              headerHoverHandler={props.headerHoverHandler}
              rowStart={rowStart(index)}
              tasks={tasksByProjectId[project.id]}
              onCellSaveViaEnter={moveDown}
              onCellFocus={focusCell}
              readOnly={props.readOnly}
            />
          ))
        ) : (
          <div className="mt-10 text-neutral-900 w-80">
            <img src={clipboard} alt="Clipboard" className="mb-6" />
            <div>{t('features.timeLogging.startByAddingTasks')}</div>
            <div className="my-2 text-sm">
              {t(
                'features.timeLogging.clickTheSelectTasksButtonToBeginSelectingTasks',
              )}
            </div>
            <Button
              variant="outlined"
              className="mt-6 text-primary-600 border-primary-600 border-[1.5px]"
              icon={faClipboardCheck}
              onClick={props.onTaskButtonClick}
            >
              {t('features.timeLogging.selectTasksHeading')}
            </Button>
          </div>
        )}
      </Toggle>
      {anyTasksSelected && <TotalByDateRow dateRange={props.dateRange} />}
    </Section>
  )
}
