import { secondsToHHMM } from '@utils/time'

interface Props {
  gridClass: string
  total: number
  skipBorder?: boolean
}

function SummaryCell(props: Props) {
  const skippableBorder = props.skipBorder ? '' : 'border-r'
  const toggleBold = props.total > 0 ? 'font-bold' : ''
  const paddingRight =
    props.gridClass === 'grid-in-totalByTask' ? 'pr-0' : 'pr-3'

  return (
    <div
      className={`${props.gridClass} h-10 mt-2 ${paddingRight} ${skippableBorder} border-dashed border-neutral-300 flex items-center justify-end`}
    >
      <div className={`text-sm text-neutral-900 ${toggleBold}`}>
        {secondsToHHMM(props.total) || '-'}
      </div>
    </div>
  )
}

export default SummaryCell
