import { useSearchTasksUnscoped } from '@hooks/useSearchTasks'
import { FC } from 'react'
import { SearchField } from 'components/form/search-field'
import { useSearchState } from '../hooks/useSearchState'
import { Projects } from './Projects'
import { UniversalSearchResults } from './UniversalSearchResults'
import { Column, TaskSearchResultItemWithType } from '../types'
import { trim } from '@utils/trim'
import { useTranslation } from 'react-i18next'

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: (id: number, checked: boolean) => void
  onProjectClick: (item: TaskSearchResultItemWithType) => void
  onUnscopedResultItemClick: (item: TaskSearchResultItemWithType) => void
  onViewAllClick: (column: Exclude<Column, 'root' | 'search'>) => void
  selectedTaskIds: Set<number>
}

export const RootColumn: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  onProjectClick,
  onUnscopedResultItemClick,
  onViewAllClick,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const { search, debounced, setSearch, error } = useSearchState('root')

  const trimmedDebounced = trim(debounced)

  const searchEverythingResult = useSearchTasksUnscoped(
    { q: trimmedDebounced },
    { enabled: error === undefined && trimmedDebounced !== '' },
  )

  if (hidden) return null

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row">
        <SearchField
          autoFocus={true}
          error={error}
          value={search}
          onChange={setSearch}
          placeholder={t('features.timeLogging.searchEverything')}
          className="w-full mx-9"
        />
      </div>
      <Projects
        hidden={trimmedDebounced !== ''}
        onProjectClick={onProjectClick}
        search={trimmedDebounced}
      />
      <UniversalSearchResults
        disabledTaskIds={disabledTaskIds}
        hidden={debounced.trim() === ''}
        onItemClick={onUnscopedResultItemClick}
        onTaskClick={onTaskClick}
        onViewAllClick={onViewAllClick}
        results={searchEverythingResult.data}
        selectedTaskIds={selectedTaskIds}
        showLoading={searchEverythingResult.isLoading}
      />
    </div>
  )
}
