import { getEmployee, searchEmployees } from '@lib/api'
import { employeeKeys } from '@lib/keys'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useWeekDates } from './useWeekDates'

interface Options {
  startDateOverride?: DateTime
  userId: number
  enabled?: boolean
}

export const useEmployee = (options: Options) => {
  const { start: startDate } = useWeekDates()

  const start = options.startDateOverride ?? startDate

  return useQuery(
    employeeKeys.get(options.userId, start),
    () => getEmployee(options.userId, start),
    { enabled: options.enabled ?? true },
  )
}

export const useEmployees = (start: DateTime, end: DateTime) => {
  return useQuery(employeeKeys.search(start, end), () =>
    searchEmployees(start, end),
  )
}
