import { FC, useCallback, ReactNode } from 'react'
import { Skeleton } from '../Skeleton'
import { Button } from 'components/buttons'
import { twMerge } from 'tailwind-merge'
import { toast } from '@lib/toasts'
import {
  useSubmitTimeCard,
  useUnsubmitTimeCard,
} from '@features/time-logging/hooks/useTimeCardManagement'
import { Spinner } from 'components/loaders'
import {
  faCloudUpload,
  faLock,
  faCalendarPen,
} from '@fortawesome/pro-regular-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEmployee } from '@hooks/useEmployee'
import { useModal } from '@hooks/useModal'
import { NoteForm } from '../../header/NoteForm'
import { useTranslation } from 'react-i18next'
import { totalWorkedInSeconds as calculateTotalWorkedInSeconds } from '@utils/timeCards'
import { toWorkdayScheduleDay } from '@utils/dates'
import { useAbsencesForDate } from '@hooks/useAbsences'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useSaveCorrection } from '@features/time-logging/hooks/useCorrections'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { isLeave } from '@utils/absence'

interface Props {
  timeCard: TimeCard
  isValid: boolean
  inFuture: boolean
  className: string
  lock?: DayLock
}

const Status = ({
  icon,
  children,
}: {
  icon: IconDefinition
  children: ReactNode
}) => {
  return (
    <div className="flex items-center text-sm gap-2 text-neutral-900">
      <FontAwesomeIcon icon={icon} />
      {children}
    </div>
  )
}

export const Footer: FC<Props> = ({
  timeCard,
  isValid,
  inFuture,
  className,
  lock,
}) => {
  const { t } = useTranslation()
  const submitMutation = useSubmitTimeCard()
  const unsubmitMutation = useUnsubmitTimeCard()
  const userId = useUserId()
  const employeeQuery = useEmployee({ userId })
  const modal = useModal()
  const absence = useAbsencesForDate(timeCard.date, { userId })
  const saveCorrectionMutation = useSaveCorrection()

  const submit = useCallback(async () => {
    try {
      await submitMutation.mutateAsync(timeCard.id)

      toast({
        title: t('features.timeLogging.timeCardSubmitted'),
        content: t('features.timeLogging.timeCardSuccessfullySubmitted'),
        variant: 'success',
      })
    } catch {
      toast({
        title: t('features.timeLogging.errorSubmittingTimeCard'),
        content: t('features.timeLogging.timeCardCouldNotBeSubmitted'),
        variant: 'error',
      })
    }
  }, [timeCard, submitMutation, t])

  const submitHandler = useCallback(() => {
    if (employeeQuery.data?.payType !== 'hourly') return void submit()

    const scheduledHours =
      employeeQuery.data.workSchedule[toWorkdayScheduleDay(timeCard.date)] ?? 0
    const scheduledInSeconds = parseFloat(scheduledHours) * 60 ** 2
    const totalWorkedInSeconds = calculateTotalWorkedInSeconds(timeCard)
    const timeOffInSeconds = absence.reduce(
      (sum, absence) =>
        // NOTE: We can ignore leave here because TCs may not be submitted on days with leave
        isLeave(absence) ? sum : sum + absence.duration,
      0,
    )

    if (scheduledInSeconds > totalWorkedInSeconds + timeOffInSeconds) {
      const id = modal.form({
        content: (
          <NoteForm
            date={timeCard.date}
            onCancel={() => modal.close(id)}
            onSave={() => {
              void submit()
              modal.close(id)
            }}
            variant="submit-confirmation"
            saveButtonText={t('common.submit')}
          />
        ),
        title: t('features.timeLogging.confirmSubmission'),
      })
    } else {
      void submit()
    }
  }, [submit, employeeQuery.data, modal, timeCard, t, absence])

  const unsubmitHandler = useCallback(async () => {
    try {
      await unsubmitMutation.mutateAsync(timeCard.id)

      toast({
        title: t('features.timeLogging.timeCardUnsubmitted'),
        content: t('features.timeLogging.timeCardSuccessfullyUnsubmitted'),
        variant: 'default',
      })
    } catch {
      toast({
        title: t('features.timeLogging.errorUnsubmittingTimeCard'),
        content: t('features.timeLogging.timeCardCouldNotBeUnsubmitted'),
        variant: 'error',
      })
    }
  }, [timeCard, unsubmitMutation, t])

  const saveCorrectionHandler = useCallback(() => {
    modal.confirm({
      content: (
        <div className="flex flex-col gap-4 text-left">
          <p>{t('features.timeLogging.correctionsCannotBeDeleted')}</p>
          <div className="bg-warning-100 text-warning-800 flex gap-2 items-center border border-warning-200 p-2.5">
            <FontAwesomeIcon icon={faTriangleExclamation} />

            <div>
              {t('features.timeLogging.correctionsAreNotSentToWorkday')}
            </div>
          </div>
        </div>
      ),
      onConfirm: () => saveCorrectionMutation.mutate(timeCard.id),
      title: t('features.timeLogging.saveCorrections'),
    })
  }, [timeCard, saveCorrectionMutation, modal, t])

  const item = useCallback(() => {
    if (inFuture) return <Skeleton />

    if (timeCard.correction) {
      if (timeCard.correctionState === 'draft') {
        return (
          <Button
            disabled={!isValid || saveCorrectionMutation.isLoading}
            className="text-sm grow px-2.5"
            onClick={() => void saveCorrectionHandler()}
            autoTextSize={true}
            maxAutoFontSizePx={14}
          >
            {saveCorrectionMutation.isLoading ? (
              <Spinner className="text-white" />
            ) : (
              t('common.save')
            )}
          </Button>
        )
      }

      return (
        <Status icon={faCalendarPen}>
          {t('features.timeLogging.corrected')}
        </Status>
      )
    }

    if (timeCard.sentToPayrollAt) {
      return (
        <Status icon={faCloudUpload}>
          {t('features.timeLogging.timeCardStates.sent')}
        </Status>
      )
    }

    if (timeCard.submitted) {
      // The existence of a DayLock implies an Unsubmission Lock
      if (lock) {
        return (
          <Status icon={faLock}>
            {t('features.timeLogging.timeCardStates.locked')}
          </Status>
        )
      }

      return (
        <Button
          className="text-sm bg-white border-[1.5px] grow w-full px-2.5"
          disabled={unsubmitMutation.isLoading}
          onClick={() => void unsubmitHandler()}
          variant="outlined"
          autoTextSize={true}
          maxAutoFontSizePx={14}
        >
          {unsubmitMutation.isLoading ? (
            <Spinner className="text-neutral-900" />
          ) : (
            t('features.timeLogging.timeCardActions.unsubmit')
          )}
        </Button>
      )
    } else {
      return (
        <Button
          disabled={!isValid || submitMutation.isLoading}
          className="text-sm grow px-2.5"
          onClick={() => void submitHandler()}
          autoTextSize={true}
          maxAutoFontSizePx={14}
        >
          {submitMutation.isLoading ? (
            <Spinner className="text-white" />
          ) : (
            t('features.timeLogging.timeCardActions.submit')
          )}
        </Button>
      )
    }
  }, [
    inFuture,
    timeCard,
    lock,
    isValid,
    submitHandler,
    submitMutation,
    unsubmitHandler,
    unsubmitMutation,
    saveCorrectionHandler,
    saveCorrectionMutation,
    t,
  ])

  return (
    <div
      className={twMerge(
        'flex items-center justify-center h-12 px-2.5 xxl:px-5',
        className,
      )}
    >
      {item()}
    </div>
  )
}
