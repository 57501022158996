import { secondsToTimestamp } from '@utils/time'
import { FC, ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { useLanguage } from '@hooks/useLanguage'

interface Props {
  annotation?: ReactNode
  bolden?: boolean
  className?: string
  seconds: number
  subSeconds?: number
  zeroMode?: 'timestamp' | 'dash'
}

export const TimeStampCell: FC<Props> = ({
  annotation,
  bolden = false,
  className,
  seconds,
  subSeconds = 0,
  zeroMode = 'timestamp',
}) => {
  const language = useLanguage()

  const value = useMemo(() => {
    if (zeroMode === 'dash' && seconds === 0) {
      return '-'
    }

    return secondsToTimestamp(seconds, { language })
  }, [zeroMode, seconds, language])

  return (
    <div
      className={twMerge(
        'relative flex flex-col h-full px-4 py-3 min-h-[60px]',
        bolden && 'font-semibold',
        className,
      )}
    >
      {annotation && (
        <div className="absolute flex flex-row top-1 right-1 gap-1">
          {annotation}
        </div>
      )}
      <span className="self-end my-auto text-sm">{value}</span>
      {subSeconds > 0 && (
        <span className="absolute text-xs text-orange-800 bottom-2 right-4">
          {secondsToTimestamp(subSeconds, { language })}
        </span>
      )}
    </div>
  )
}
