import { FC, useState } from 'react'
import classNames from 'classnames'
import { Column, ColumnFilter, TaskSearchResultItemWithType } from '../types'
import { NavColumn } from '../NavColumn'
import { useTranslation } from 'react-i18next'

const Tab: FC<{ active: boolean; label: string; onClick: () => void }> = ({
  active,
  label,
  onClick,
}) => (
  <button
    className={classNames('p-2 text-xs font-semibold uppercase', {
      'border-b border-primary-600 text-primary-600': active,
      'font-normal text-neutral-500': !active,
    })}
    onClick={onClick}
  >
    {label}
  </button>
)

type Tab = Extract<Column, 'sequence' | 'entity_type'>

interface Props {
  activeTab: Tab
  filter?: ColumnFilter
  hidden: boolean
  onItemClick: (
    item: TaskSearchResultItemWithType,
    viewingAll?: boolean,
  ) => void
  onTabClick: (tab: Tab) => void
  viewingAllTab?: Tab
}

export const SequencesAndEntityTypesColumns: FC<Props> = ({
  activeTab,
  filter = {},
  hidden,
  onItemClick,
  onTabClick,
  viewingAllTab,
}) => {
  const { t } = useTranslation()
  const [totalSequences, setTotalSequences] = useState(0)
  const [totalEntityTypes, setTotalEntityTypes] = useState(0)

  return (
    <>
      {!hidden && !viewingAllTab && (
        <div className="flex flex-row mb-4 border-b border-neutral-300 mx-9">
          <Tab
            active={activeTab === 'sequence'}
            label={`${t(
              'features.timeLogging.taskSelection.sequences',
            )} (${totalSequences})`}
            onClick={() => onTabClick('sequence')}
          />
          <Tab
            active={activeTab === 'entity_type'}
            label={`${t(
              'features.timeLogging.taskSelection.allEntities',
            )} (${totalEntityTypes})`}
            onClick={() => onTabClick('entity_type')}
          />
        </div>
      )}
      <NavColumn
        enableSearchRequest={!hidden}
        filter={filter}
        hidden={hidden || activeTab !== 'sequence'}
        label={t('features.timeLogging.taskSelection.sequences')}
        onItemClick={onItemClick}
        onTotalChange={setTotalSequences}
        viewingAll={viewingAllTab === 'sequence'}
        showing="sequence"
      />
      <NavColumn
        enableSearchRequest={!hidden}
        filter={filter}
        hidden={hidden || activeTab !== 'entity_type'}
        label={t('features.timeLogging.taskSelection.entityTypes')}
        onItemClick={onItemClick}
        onTotalChange={setTotalEntityTypes}
        viewingAll={viewingAllTab === 'entity_type'}
        showing="entity_type"
      />
    </>
  )
}
