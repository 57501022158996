import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const Input: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ className, ...props }) => (
  <input
    {...props}
    className={twMerge(
      'w-full p-2 text-sm border-solid rounded border-[1px] border-neutral-400',
      className,
    )}
  />
)
