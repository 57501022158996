import { FC, useMemo, useState } from 'react'
import { SearchField } from 'components/form/search-field'
import { Item, Props as ItemProps } from '../components/Item'
import { enrichTasks, includes, itemForTask } from '../helpers'
import { useNonProjectTasks } from '@features/time-logging/hooks/useTasks'
import { Spinner } from 'components/loaders'
import { useTranslation } from 'react-i18next'

type TasksByStepCode<T extends NonProjectTask> = {
  [key: NonNullable<NonProjectTask['step']>['id']]: T[]
  noStepCode: T[]
}

const groupTasksByStepId = <T extends NonProjectTask>(
  tasks: T[],
): TasksByStepCode<T> => {
  return tasks.reduce<TasksByStepCode<T>>(
    (acc, task) => {
      const key: keyof TasksByStepCode<T> = task.step?.id ?? 'noStepCode'
      const tasksWithCode = acc[key] ?? []
      return { ...acc, [key]: [...tasksWithCode, task] }
    },
    { noStepCode: [] },
  )
}

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onTaskClick: ItemProps['onItemClick']
  selectedTaskIds: Set<number>
}

const NonProject: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  selectedTaskIds,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const nonProjectTasksQuery = useNonProjectTasks({ enabled: !hidden })

  const groups = useMemo(() => {
    if (nonProjectTasksQuery.data === undefined) return []

    const includesSearch = includes(search.toLowerCase().trim())

    const filteredTasks = nonProjectTasksQuery.data.filter((task) => {
      return (
        includesSearch(task.name) ||
        includesSearch(task.id.toString()) ||
        includesSearch(task.step?.code ?? null)
      )
    })

    const enrichedTasks = enrichTasks(
      filteredTasks,
      disabledTaskIds,
      selectedTaskIds,
    )

    const { noStepCode: tasksWithNoStep, ...tasksByStepId } =
      groupTasksByStepId(enrichedTasks)

    return Object.values(tasksByStepId)
      .map((tasks) => {
        return [
          tasks[0].step?.code ?? '', // This smells because step.code will always be defined
          tasks.sort((taskA, taskB) => (taskA.name > taskB.name ? 1 : -1)),
        ] as const
      })
      .sort(([codeA], [codeB]) => (codeA > codeB ? 1 : -1))
      .concat(
        tasksWithNoStep.length > 0
          ? [
              [
                t('features.timeLogging.noPipelineStep'),
                tasksWithNoStep.sort((taskA, taskB) =>
                  taskA.name > taskB.name ? 1 : -1,
                ),
              ],
            ]
          : [],
      )
  }, [nonProjectTasksQuery.data, search, selectedTaskIds, disabledTaskIds, t])

  if (hidden) return null

  if (nonProjectTasksQuery.isLoading === undefined) {
    return (
      <div className="px-4 text-center">
        <Spinner className="text-3xl" />
      </div>
    )
  }

  return (
    <div className="px-9">
      <SearchField
        autoFocus={true}
        value={search}
        onChange={setSearch}
        placeholder={t('features.timeLogging.searchNonProjectTasks')}
      />
      <div className="pt-4">
        {groups.map(([stepCode, tasks]) => (
          <div key={stepCode} className="mb-4">
            <h3 className="text-xs font-medium text-neutral-500">{stepCode}</h3>
            {tasks.map((task) => (
              <Item
                key={task.id}
                item={itemForTask(task)}
                onItemClick={onTaskClick}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default NonProject
