import {
  useAddBreak,
  useDeleteBreak,
  useUpdateBreak,
} from '@features/time-logging/hooks/useBreaks'
import { useUpdateTimeCard } from '@features/time-logging/hooks/useTimeCardManagement'
import { useTimeCards } from '@hooks/useTimeCards'
import { padDigit } from '@utils/padDigit'
import { range as generateRange } from '@utils/range'
import { col } from '@utils/styles'
import { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { Section } from '../section'
import SummaryCell from '../table/cells/SummaryCell'
import GridColumn from '../table/GridColumn'
import SideColumn from '../table/SideColumn'
import SummaryRow from '../table/SummaryRow'
import { Table } from '../table/Table'
import { Toggle } from '../toggle'
import { WorkDayCell } from './WorkDayCell'
import { BreakControl } from './components/BreakControl'
import { LeftHeader } from './components/LeftHeader'
import { sortIntervals, filterOutUndefinedIntervals } from 'utils/intervals'
import { CardColumn } from './types'
import { formatTime, formatDuration } from './utils/format'
import { useSettings } from '@hooks/useSettings'
import { totalWorkedInSeconds } from '@utils/timeCards'
import { useWeekDates } from '@hooks/useWeekDates'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useUpdateCorrection } from '@features/time-logging/hooks/useCorrections'

interface Props {
  onCellHover: (date: DateTime | null) => void
  readOnly: boolean
}

export const WorkDayTable: FC<Props> = ({ onCellHover, readOnly }) => {
  const { t } = useTranslation()
  const { range } = useWeekDates()
  const [rowHovered, setRowHovered] = useState(0)
  const [numOfBreakRows, setNumOfBreakRows] = useState(1)
  const settingsQueryResult = useSettings()

  const clock = settingsQueryResult.data?.timeFormat === '24_hour' ? '24' : '12'

  const userId = useUserId()
  const timeCardsQuery = useTimeCards({ userId })
  const timeCardsLoading = useMemo(
    () => timeCardsQuery.data === undefined,
    [timeCardsQuery],
  )
  const timeCards = useMemo(
    () => timeCardsQuery.data ?? [],
    [timeCardsQuery.data],
  )

  const timeCardMutation = useUpdateTimeCard()
  const correctedTimeCardMutation = useUpdateCorrection()
  const breakMutation = useUpdateBreak()
  const addBreakMutation = useAddBreak()
  const deleteBreakMutation = useDeleteBreak()

  const cardColumns: CardColumn[] = useMemo(
    () =>
      range.map((date) => {
        const card = timeCards?.find((card) => card.date.hasSame(date, 'day'))

        return card
          ? {
              id: card.id,
              date,
              startAt: card.startAt,
              endAt: card.endAt,
              breaks: sortIntervals(filterOutUndefinedIntervals(card.breaks)),
              total: totalWorkedInSeconds(card),
              timeZone: card.timeZone,
              submitted: card.submitted,
              correction: card.correction,
              capturesMealBreaks: card.capturesMealBreaks,
            }
          : {
              id: 0,
              date,
              startAt: null,
              endAt: null,
              breaks: [],
              total: 0,
              timeZone: 'America/Vancouver', // TODO - this smells
              submitted: true,
              correction: false,
              capturesMealBreaks: false,
            }
      }),
    [range, timeCards],
  )

  useEffect(() => {
    const maxNumberOfBreaksPerCard = cardColumns.reduce(
      (maxBreaks, cardColumn) => {
        const numOfBreaksWithinCard = cardColumn.breaks.length ?? 0
        return maxBreaks < numOfBreaksWithinCard
          ? numOfBreaksWithinCard
          : maxBreaks
      },
      1,
    )

    // If the max number of breaks on any given day exceeds the current
    // number of displayed breaks, display enough breaks to acommodate.
    // Note that we should never reduce numOfBreakRows to match maxNumberOfBreaksPerCard,
    // as the user may have intentionally added a break row and it may just
    // currently not have any breaks because they are in the process of adding breaks
    // to time cards.
    if(maxNumberOfBreaksPerCard > numOfBreakRows) {
      setNumOfBreakRows(maxNumberOfBreaksPerCard)
    }
  }, [cardColumns, numOfBreakRows])

  const breakNumbers = generateRange(1, numOfBreakRows)

  const numOfRows = numOfBreakRows + 2 // start and end

  const handleTimeSubmit = (
    cardColumn: CardColumn,
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
  ) => {
    if (!cardColumn.id) return

    const mutation = cardColumn.correction
      ? correctedTimeCardMutation
      : timeCardMutation

    mutation.mutate({
      id: cardColumn.id,
      startAt: startAtSecondsSinceMidnight,
      endAt: endAtSecondsSinceMidnight,
    })
  }

  const handleBreakSubmit = (
    breakId: number | undefined,
    cardId: number | undefined,
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
    isMeal: boolean,
  ) => {
    if (cardId === undefined) return

    const startAt = startAtSecondsSinceMidnight
    const endAt = endAtSecondsSinceMidnight

    if (breakId === undefined && startAt === null && endAt === null) return
    if (breakId === undefined)
      return addBreakMutation.mutate({
        timeCardId: cardId,
        startAt,
        endAt,
        isMeal,
      })
    if (startAt === null && endAt === null)
      return deleteBreakMutation.mutate(breakId)

    breakMutation.mutate({ breakId, startAt, endAt, isMeal })
  }

  const cardColumnIsEditable = useCallback(
    (card: CardColumn): boolean => {
      if (card.id === undefined || card.id === 0) return false

      return !readOnly && card.date <= DateTime.now() && !card.submitted
    },
    [readOnly],
  )

  return (
    <Section loading={timeCardsLoading} testId="work-day">
      <Toggle name={t('features.timeLogging.workDay')} initiallyOpen={true}>
        <Table>
          <>
            <SideColumn side="left">
              <LeftHeader
                highlight={rowHovered === 1}
                labels={[t('features.timeLogging.start')]}
                rowIndex={1}
              />
              {breakNumbers.map((breakNumber) => (
                <LeftHeader
                  highlight={rowHovered == breakNumber + 1}
                  key={`break-${breakNumber}`}
                  labels={[
                    t('features.timeLogging.break'),
                    padDigit(breakNumber),
                  ]}
                  rowIndex={breakNumber + 1}
                >
                  <BreakControl
                    breakLabel={padDigit(breakNumber)}
                    onBreakAdd={() => setNumOfBreakRows((num) => num + 1)}
                    onBreakRemove={() => setNumOfBreakRows((num) => num - 1)}
                    removeDisabled={cardColumns.some((card) => {
                      if (breakNumber === 1) return true

                      return card.breaks.at(breakNumber - 1)
                    })}
                    addDisabled={cardColumns.every(
                      (card) => card.breaks.at(breakNumber - 1) === undefined,
                    )}
                  />
                </LeftHeader>
              ))}
              <LeftHeader
                highlight={rowHovered === numOfRows}
                labels={[t('features.timeLogging.end')]}
                rowIndex={numOfRows}
              />
            </SideColumn>
            {cardColumns.map((cardColumn) => (
              <GridColumn
                date={cardColumn.date}
                key={`work-day-column-${cardColumn.date.toString()}`}
              >
                {/* TODO - handle rowIndex discrepancy with LeftHeader */}
                <WorkDayCell
                  rowIndex={0}
                  date={cardColumn.date}
                  editable={cardColumnIsEditable(cardColumn)}
                  onHoverOver={() => {
                    onCellHover(cardColumn.date)
                    setRowHovered(1)
                  }}
                  onHoverLeave={() => {
                    onCellHover(null)
                    setRowHovered(0)
                  }}
                  formattedContents={formatTime(
                    cardColumn.startAt,
                    cardColumn.date,
                    cardColumn.timeZone,
                    { clock },
                  )}
                  variant="time"
                  startAtSecondsSinceMidnight={cardColumn.startAt}
                  endAtSecondsSinceMidnight={cardColumn.endAt}
                  onSubmit={(
                    startAtSecondsSinceMidnight,
                    endAtSecondsSinceMidnight,
                  ) => {
                    handleTimeSubmit(
                      cardColumn,
                      startAtSecondsSinceMidnight,
                      endAtSecondsSinceMidnight,
                    )
                  }}
                  autoFocusedTimeWidgetAdjuster="start"
                  timeZone={cardColumn.timeZone}
                  testId="work-day-start"
                />
                {breakNumbers.map((breakNumber, breakNumberIndex) => {
                  // Grabbing break based on break number since breaks are sorted
                  const outTime = cardColumn.breaks.at(breakNumber - 1)

                  return (
                    <WorkDayCell
                      autoFocusedTimeWidgetAdjuster="start"
                      date={cardColumn.date}
                      editable={cardColumnIsEditable(cardColumn)}
                      endAtSecondsSinceMidnight={outTime?.endAt ?? null}
                      formattedContents={formatDuration(
                        outTime,
                        cardColumn.date,
                        cardColumn.timeZone,
                        { clock },
                      )}
                      key={`work-day-cell-break-${cardColumn.date.toString()}-${
                        outTime?.id ?? 0
                      }-${breakNumberIndex}`}
                      isMeal={Boolean(outTime?.isMeal)}
                      isMealByDefault={
                        outTime === undefined &&
                        breakNumberIndex === 0 &&
                        cardColumn.breaks.every((b) => !b.isMeal)
                      }
                      onHoverLeave={() => {
                        onCellHover(null)
                        setRowHovered(0)
                      }}
                      onHoverOver={() => {
                        onCellHover(cardColumn.date)
                        setRowHovered(breakNumber + 1)
                      }}
                      rowIndex={breakNumber}
                      showIsMealCheckbox={cardColumn.capturesMealBreaks}
                      startAtSecondsSinceMidnight={outTime?.startAt ?? null}
                      onSubmit={(
                        startAtSecondsSinceMidnight,
                        endAtSecondsSinceMidnight,
                        isMeal,
                      ) =>
                        handleBreakSubmit(
                          outTime?.id,
                          cardColumn.id,
                          startAtSecondsSinceMidnight,
                          endAtSecondsSinceMidnight,
                          cardColumn.capturesMealBreaks && isMeal,
                        )
                      }
                      timeZone={cardColumn.timeZone}
                      variant="time-and-duration"
                    />
                  )
                })}
                <WorkDayCell
                  rowIndex={numOfRows - 1}
                  date={cardColumn.date}
                  editable={cardColumnIsEditable(cardColumn)}
                  onHoverOver={() => {
                    onCellHover(cardColumn.date)
                    setRowHovered(numOfRows)
                  }}
                  onHoverLeave={() => {
                    onCellHover(null)
                    setRowHovered(0)
                  }}
                  formattedContents={formatTime(
                    cardColumn.endAt,
                    cardColumn.date,
                    cardColumn.timeZone,
                    { clock },
                  )}
                  variant="time"
                  startAtSecondsSinceMidnight={cardColumn.startAt}
                  endAtSecondsSinceMidnight={cardColumn.endAt}
                  onSubmit={(
                    startAtSecondsSinceMidnight,
                    endAtSecondsSinceMidnight,
                  ) =>
                    handleTimeSubmit(
                      cardColumn,
                      startAtSecondsSinceMidnight,
                      endAtSecondsSinceMidnight,
                    )
                  }
                  autoFocusedTimeWidgetAdjuster="end"
                  timeZone={cardColumn.timeZone}
                  testId="work-day-end"
                />
              </GridColumn>
            ))}
          </>
        </Table>
      </Toggle>
      <SummaryRow testId="work-day-summary">
        {cardColumns.map((card, index) => (
          <SummaryCell
            key={`work-day-summary-${card.date.toString()}`}
            gridClass={col(card.date)}
            total={card.total}
            skipBorder={index === cardColumns.length - 1}
          />
        ))}
      </SummaryRow>
    </Section>
  )
}
