import { FC, useEffect, useRef } from 'react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { LogoControl } from '../LogoControl'
import { useSet } from '@hooks/useSet'
import { Section as SectionType } from './types'
import { Section } from './components/Section'
import { ParentItem } from './components/ParentItem'
import { ChildItem } from './components/ChildItem'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { isParentNavItem } from './utils/isParentNavItem'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

const Seperator: FC<{ className?: string }> = ({ className }) => (
  <div className={twMerge('mx-2 border-b border-neutral-600', className)} />
)

export interface Props {
  hidden: boolean
  onClose: () => void
  onContactSupport: () => void
  onNavigate: (path: string) => void
  sections: SectionType[]
}

export const Sidebar: FC<Props> = ({
  hidden,
  onClose,
  onContactSupport,
  onNavigate,
  sections,
}) => {
  const { t } = useTranslation()

  const {
    set: expandedParents,
    toggle: toggleExpandedParent,
    add: expandParent,
  } = useSet<string>()

  const ref = useRef(null)

  useOnClickOutside(ref, onClose)

  useEffect(() => {
    sections.forEach((section) => {
      section.navItems.forEach((parent) => {
        if (!('navItems' in parent)) return

        const parentHasActiveChildren = parent.navItems.some((i) => i.active)
        if (parentHasActiveChildren) expandParent(parent.label)
      })
    })
  }, [sections, expandParent])

  if (hidden) return null

  return (
    <nav
      ref={ref}
      className="fixed left-0 flex flex-col w-[260px] h-screen px-2 overflow-auto bg-neutral-800 z-50 drop-shadow-[1px_40px_5px_#0F1B2A]"
    >
      <LogoControl
        icon={faTimes}
        onIconClick={onClose}
        className="h-12 ml-6"
        testId="sidebar-close"
      />
      {/* Pull seperator up 1px to align with top nav bottom border */}
      <Seperator className="mt-[-1px]" />
      {sections.map((section, index) => {
        const isLastSection = sections.length - 1 === index
        return (
          <div key={index}>
            <Section heading={section.label} key={section.label}>
              {section.navItems.map((item) => {
                return isParentNavItem(item) ? (
                  <ParentItem
                    expanded={expandedParents.has(item.label)}
                    label={item.label}
                    onClick={() => toggleExpandedParent(item.label)}
                    icon={item.icon}
                    key={item.label}
                  >
                    {item.navItems.map((item) => (
                      <ChildItem
                        active={item.active}
                        key={item.path}
                        label={item.label}
                        path={item.path}
                        onClick={onNavigate}
                        nested={true}
                      />
                    ))}
                  </ParentItem>
                ) : (
                  <ChildItem
                    active={item.active}
                    key={item.path}
                    label={item.label}
                    path={item.path}
                    icon={item.icon}
                    onClick={onNavigate}
                  />
                )
              })}
            </Section>
            {isLastSection || <Seperator />}
          </div>
        )
      })}
      <button
        className="px-2 mt-auto mb-8 text-sm font-medium text-left text-white text-nuetral-300"
        onClick={onContactSupport}
      >
        <FontAwesomeIcon className="mr-2 text-neutral-400" icon={faEnvelope} />
        {t('components.navigation.sidebar.contactSupport')}
      </button>
    </nav>
  )
}
