import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  icon?: IconDefinition
  srOnlyLabel?: string

  // If true, the button will be a circle regardless of the theme's rounded-primary
  circle?: boolean
}

export const IconButton: FC<Props> = ({
  onClick,
  className,
  icon = faEllipsisVertical,
  iconProps,
  srOnlyLabel,
  circle = false,
  ...props
}) => (
  <button
    {...props}
    onClick={onClick}
    className={twMerge(
      'w-6 h-6 hover:bg-neutral-200 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-transparent',
      className,
      circle ? 'rounded-full' : 'rounded-primary',
    )}
  >
    {srOnlyLabel && <label className="sr-only">{srOnlyLabel}</label>}
    <FontAwesomeIcon {...iconProps} icon={icon} />
  </button>
)
