import {
  faCalendarStar,
  faCloudCheck,
  faTreePalm,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode, useId } from 'react'
import { Tooltip } from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: ReactNode
  className?: string
  leftAnnotations?: ReactNode
  rightAnnotations?: ReactNode
  showLeaveIcon?: boolean
  showIssuesIcon?: boolean
  showHolidayIcon?: boolean
  showSentIcon?: boolean
  tooltipContent?: ReactNode
}

export const CellAnnotation: FC<Props> = ({
  children,
  className,
  leftAnnotations,
  rightAnnotations,
  showLeaveIcon = false,
  showIssuesIcon = false,
  showHolidayIcon = false,
  showSentIcon = false,
  tooltipContent,
}) => {
  const tooltipId = useId()

  return (
    <div
      className={twMerge('relative flex flex-col h-full', className)}
      data-tooltip-id={tooltipId}
    >
      {children}
      <div className="absolute flex flex-row justify-between top-1 left-1 right-1 gap-1">
        {leftAnnotations}
        <div className="flex ml-auto gap-1">
          {showLeaveIcon && (
            <FontAwesomeIcon
              icon={faTreePalm}
              size="sm"
              fixedWidth={true}
              className="text-neutral-400"
            />
          )}
          {showHolidayIcon && (
            <FontAwesomeIcon
              icon={faCalendarStar}
              size="sm"
              fixedWidth={true}
              className="text-neutral-400"
            />
          )}
          {showIssuesIcon && (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="sm"
              className="text-error-500"
              fixedWidth={true}
            />
          )}
          {showSentIcon && (
            <FontAwesomeIcon icon={faCloudCheck} size="sm" fixedWidth={true} />
          )}
          {rightAnnotations}
        </div>
      </div>
      {tooltipContent && (
        <Tooltip
          id={tooltipId}
          place="bottom"
          delayShow={1000}
          closeOnEsc={true}
          className="z-10"
        >
          {tooltipContent}
        </Tooltip>
      )}
    </div>
  )
}
