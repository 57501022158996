import { FC, ReactNode } from 'react'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { LogoControl } from './LogoControl'
import { twMerge } from 'tailwind-merge'
import { EnvLabel } from 'components/indicators/env-label/EnvLabel'
import { BrandLogo } from './BrandLogo'

interface Props {
  accountInfo: ReactNode
  firstName?: string
  lastName?: string
  onMenuClick: () => void
  onLogout?: () => void
  profileImageUrl?: string
}

export const TopNavBar: FC<Props> = ({ accountInfo, onMenuClick }) => {
  const nonProdDeployedEnv =
    import.meta.env.PROD && import.meta.env.VITE_INFRA_ENVIRONMENT !== 'prod'

  return (
    <div
      className={twMerge(
        'sticky top-0 z-50 flex items-center justify-between w-full h-12 px-8 border-b border-neutral-600 bg-neutral-800',
      )}
    >
      <div className="flex items-center flex-grow pr-4 gap-4">
        <LogoControl
          icon={faBars}
          onIconClick={onMenuClick}
          testId="sidebar-open"
        />

        {nonProdDeployedEnv && <EnvLabel />}
      </div>

      <div className="flex items-center gap-2">
        <BrandLogo className="max-h-12 max-w-36" />

        {accountInfo}
      </div>
    </div>
  )
}
