import { Tabs } from 'components/buttons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
  activeTab: 'my-tasks' | 'all-tasks' | 'non-project'
  onTabClick: (tab: Props['activeTab']) => void
}

export const TabPicker: FC<Props> = ({ activeTab, onTabClick }) => {
  const { t } = useTranslation()

  const tabs = [
    { id: 'my-tasks', label: t('features.timeLogging.myTasks') },
    { id: 'all-tasks', label: t('features.timeLogging.allTasks') },
    { id: 'non-project', label: t('features.timeLogging.nonProject') },
  ] as const

  return (
    <Tabs
      className="mt-6"
      tabs={tabs}
      activeTab={activeTab}
      onTabClick={onTabClick}
    />
  )
}
