interface TaskLabelMap {
  taskId: number
  projectId: number
  shotgridEntityName: string
  pipelineStepName: string
  name: string
}

const generateTaskLabels = (tasks: Task[]) => {
  const map = tasks.reduce<TaskLabelMap[]>((res, task) => {
    const shotgridEntityName = task.displayLabel[0]
    const pipelineStepName = task.displayLabel[1]
    const taskName = task.displayLabel[2]
    const otherTasksWithShotgridEntity = res.filter(
      (t) =>
        t.taskId !== task.id &&
        t.shotgridEntityName != '' &&
        t.projectId === task.project.id &&
        t.shotgridEntityName === shotgridEntityName,
    )

    let newShotgridEntityName = ''
    if (shotgridEntityName && otherTasksWithShotgridEntity.length === 0) {
      newShotgridEntityName = shotgridEntityName
    }

    const otherTasksWithPipeline = res.filter(
      (t) =>
        t.taskId !== task.id &&
        t.pipelineStepName != '' &&
        t.projectId === task.project.id &&
        t.pipelineStepName === pipelineStepName,
    )

    let newPipelineStepName = ''
    if (pipelineStepName && otherTasksWithPipeline.length === 0) {
      newPipelineStepName = pipelineStepName
    }

    res.push({
      taskId: task.id,
      projectId: task.project.id,
      shotgridEntityName: newShotgridEntityName,
      pipelineStepName: newPipelineStepName,
      name: taskName,
    })
    return res
  }, [])

  return map
}

export { generateTaskLabels }
