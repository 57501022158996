import { Button } from 'components/buttons'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  fields: JSX.Element
  isSaving: boolean
  onCancel: () => void
  onSave: () => void
  saveButtonText?: string
}

// This is designed to be used in conjunction with the Modal form variant
// (e.g. could be passed as its `content` prop)
export const ModalForm: FC<Props> = ({
  className,
  fields,
  isSaving,
  onCancel,
  onSave,
  saveButtonText,
}) => {
  const ref = useRef<HTMLFormElement>(null)
  const { t } = useTranslation()

  const save = () => {
    if (ref.current?.reportValidity()) {
      onSave()
    }
  }

  return (
    <form ref={ref} className={className}>
      <div className="flex flex-col p-6 pt-0 gap-4">{fields}</div>
      <div className="flex justify-end w-full px-6 py-4 border-t border-neutral-300">
        <Button
          className="mr-2"
          disabled={isSaving}
          loading={isSaving}
          onClick={onCancel}
          title={t('common.cancel')}
          variant="outlined"
        >
          {t('common.cancel')}
        </Button>
        <Button
          disabled={isSaving}
          onClick={save}
          loading={isSaving}
          title={t('common.save')}
        >
          {saveButtonText || t('common.save')}
        </Button>
      </div>
    </form>
  )
}
