import { FC, FormEvent, ReactNode, useState } from 'react'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'components/buttons/Button'
import { Props as AdjusterProps, Adjuster } from './components/Adjuster'
import {
  getClockFromElapsedSecondsFromMidnight,
  getElapsedSecondsFromMidnight,
  hoursFromSeconds,
  hoursMinutesToSeconds,
  minutesFromSeconds,
} from '@utils/time'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useDateTimeToLocaleString } from '@hooks/useDateTimeWithLocale'

const ClearButton: typeof Button = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      className="text-sm font-medium text-neutral-400"
      onClick={onClick}
    >
      {t('components.timeWidget.clearTime')}
    </button>
  )
}

const SubmitButton: typeof Button = ({ disabled }) => {
  const { t } = useTranslation()

  return (
    <Button
      disabled={disabled}
      className="min-w-[6rem] text-sm"
      icon={faChevronRight}
      tabIndex={4}
    >
      {t('components.timeWidget.apply')}
    </Button>
  )
}

const Title: FC<{ children: ReactNode; date: DateTime }> = ({
  children,
  date,
}) => {
  const localize = useDateTimeToLocaleString()({
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })

  return (
    <div className="flex flex-row justify-between px-4 text-sm font-semibold text-neutral-800">
      <span>{localize(date)}</span>
      {children}
    </div>
  )
}

interface Time {
  hour: number | null
  minute: number | null
}

type DefinedTime = { [property in keyof Time]: NonNullable<Time[property]> }

const nullTime: Time = { hour: null, minute: null }

const computeDuration = (start: Time, end: Time): Time => {
  if (!isTimeDefined(start)) return nullTime
  if (!isTimeDefined(end)) return nullTime

  const startMarkInMinutes = start.hour * 60 + start.minute
  const endMarkInMinutes = end.hour * 60 + end.minute

  if (endMarkInMinutes < startMarkInMinutes) return nullTime

  const durationInMinutes = endMarkInMinutes - startMarkInMinutes
  const hour = Math.floor(durationInMinutes / 60)
  const minute = durationInMinutes % 60

  return { hour, minute }
}

const getMinuteTotal = (time: DefinedTime): number => {
  return time.hour * 60 + time.minute
}

const computeTime = (
  time: DefinedTime,
  duration: DefinedTime,
  target: 'start' | 'end',
): Time => {
  const timeMarkInMinutes = getMinuteTotal(time)
  const durationInMinutes = getMinuteTotal(duration)

  const computedTimeInMinutes =
    target === 'start'
      ? timeMarkInMinutes - durationInMinutes
      : timeMarkInMinutes + durationInMinutes

  return {
    hour: Math.max(0, Math.floor(computedTimeInMinutes / 60)),
    minute: computedTimeInMinutes % 60,
  }
}

const isTimeDefined = (
  time: Time,
): time is { [property in keyof Time]: NonNullable<Time[property]> } => {
  if (time.hour === null) return false
  if (time.minute === null) return false
  if (isNaN(time.hour)) return false
  if (isNaN(time.minute)) return false

  return true
}

const isInvalidTime = (time: Time): boolean => {
  if (time.hour === null) return false
  if (time.minute === null) return false
  if (isNaN(time.hour)) return true
  if (isNaN(time.minute)) return true

  return false
}

interface Errors {
  start: string[]
  end: string[]
  duration: string[]
}

const convertSecondsFromMidnightIntoTime = (seconds: number | null): Time => {
  if (seconds === null) return nullTime

  return {
    hour: hoursFromSeconds(seconds),
    minute: minutesFromSeconds(seconds),
  }
}

export type TimeWidgetProps = {
  autoFocusedAdjuster: 'start' | 'end'
  dateEditing: DateTime
  displayMode?: '12' | '24'
  endAtSecondsSinceMidnight: number | null
  fieldPrefix?: string
  onSubmit: (
    startAtSecondsSinceMidnight: number | null,
    endAtSecondsSinceMidnight: number | null,
  ) => void
  renderTopRight?: () => ReactNode
  startAtSecondsSinceMidnight: number | null
  timeZone: string
  variant?: 'time' | 'time-and-duration'
}

/**
 * TODO - time widget tasks
 * - handle "short" input (e.g. 9h, 8 - as in 8:00 am)
 * - clean up error messaging/logic/display
 * - introduce tests to ensure edge cases are taken care of
 */
export const TimeWidget: FC<TimeWidgetProps> = ({
  autoFocusedAdjuster,
  dateEditing,
  displayMode = '12',
  endAtSecondsSinceMidnight,
  fieldPrefix,
  onSubmit: submit,
  renderTopRight = () => null,
  startAtSecondsSinceMidnight,
  timeZone,
  variant = 'time-and-duration',
}) => {
  const [startTimeSinceMidnight, setStartTimeSinceMidnight] = useState<Time>(
    convertSecondsFromMidnightIntoTime(startAtSecondsSinceMidnight),
  )
  const [endTimeSinceMidnight, setEndTimeSinceMidnight] = useState<Time>(
    convertSecondsFromMidnightIntoTime(endAtSecondsSinceMidnight),
  )
  const [duration, setDuration] = useState<Time>(
    computeDuration(startTimeSinceMidnight, endTimeSinceMidnight),
  )

  const getErrors = () => {
    const errors: Errors = {
      start: [],
      end: [],
      duration: [],
    }

    if (isInvalidTime(startTimeSinceMidnight)) errors.start.push('Invalid time')
    if (isInvalidTime(endTimeSinceMidnight)) errors.end.push('Invalid time')
    if (isInvalidTime(duration)) errors.duration.push('Invalid time')

    if (
      isTimeDefined(startTimeSinceMidnight) &&
      isTimeDefined(endTimeSinceMidnight)
    ) {
      const endTimeInMinutes = getMinuteTotal(endTimeSinceMidnight)
      const startTimeInMinutes = getMinuteTotal(startTimeSinceMidnight)

      if (endTimeInMinutes <= startTimeInMinutes)
        errors.end.push('End must be later than start')
    }

    return {
      errors,
      isInvalid: Object.values(errors).some((e: string[]) => e.length > 0),
    }
  }

  const { errors, isInvalid } = getErrors()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isInvalid) return

    const startAtSecondsSinceMidnight = hoursMinutesToSeconds(
      startTimeSinceMidnight.hour,
      startTimeSinceMidnight.minute,
    )
    const endAtSecondsSinceMidnight = hoursMinutesToSeconds(
      endTimeSinceMidnight.hour,
      endTimeSinceMidnight.minute,
    )

    submit(startAtSecondsSinceMidnight, endAtSecondsSinceMidnight)
  }

  const handleClear = () => {
    setStartTimeSinceMidnight(nullTime)
    setEndTimeSinceMidnight(nullTime)
    setDuration(nullTime)
  }

  const convertFromClockToElapsedTimeSinceMidnight = (
    hour: number | null,
    minute: number | null,
    { assumeMidnightIsEndOfDay } = { assumeMidnightIsEndOfDay: false },
  ) => {
    if (hour === null || isNaN(hour)) return { hour: null, minute: null }
    if (minute === null || isNaN(minute)) return { hour: null, minute: null }

    const elapsed = getElapsedSecondsFromMidnight(
      hour,
      minute,
      dateEditing,
      timeZone,
      { assumeMidnightIsEndOfDay },
    )

    const elapsedHour = hoursFromSeconds(elapsed)
    const elapsedMinute = minutesFromSeconds(elapsed)

    return { hour: elapsedHour, minute: elapsedMinute }
  }

  const handleStartTimeChange: AdjusterProps['onChange'] = (
    clockStartHour,
    clockStartMinute,
  ) => {
    const startTimeSinceMidnight = convertFromClockToElapsedTimeSinceMidnight(
      clockStartHour,
      clockStartMinute,
    )
    setStartTimeSinceMidnight(startTimeSinceMidnight)

    if (!isTimeDefined(startTimeSinceMidnight)) return

    if (isTimeDefined(duration) && !isTimeDefined(endTimeSinceMidnight)) {
      const newEndTime = computeTime(startTimeSinceMidnight, duration, 'end')
      setEndTimeSinceMidnight(newEndTime)
      return
    }

    setDuration(computeDuration(startTimeSinceMidnight, endTimeSinceMidnight))
  }

  const handleEndTimeChange: AdjusterProps['onChange'] = (
    clockEndHour,
    clockEndMinute,
  ) => {
    const endTimeSinceMidnight = convertFromClockToElapsedTimeSinceMidnight(
      clockEndHour,
      clockEndMinute,
      { assumeMidnightIsEndOfDay: true },
    )
    setEndTimeSinceMidnight(endTimeSinceMidnight)

    if (!isTimeDefined(endTimeSinceMidnight)) return

    if (isTimeDefined(duration) && !isTimeDefined(startTimeSinceMidnight)) {
      const newStartTime = computeTime(endTimeSinceMidnight, duration, 'start')
      setStartTimeSinceMidnight(newStartTime)
      return
    }

    setDuration(computeDuration(startTimeSinceMidnight, endTimeSinceMidnight))
  }

  const handleDurationChange: AdjusterProps['onChange'] = (
    durationHour,
    durationMinute,
  ) => {
    const changedDuration = { hour: durationHour, minute: durationMinute }
    setDuration(changedDuration)

    if (!isTimeDefined(changedDuration)) return

    if (
      isTimeDefined(changedDuration) &&
      isTimeDefined(startTimeSinceMidnight)
    ) {
      const newEndTime = computeTime(
        startTimeSinceMidnight,
        changedDuration,
        'end',
      )
      setEndTimeSinceMidnight(newEndTime)
      return
    }

    if (isTimeDefined(changedDuration) && isTimeDefined(endTimeSinceMidnight)) {
      const newStartTime = computeTime(
        endTimeSinceMidnight,
        changedDuration,
        'start',
      )
      setStartTimeSinceMidnight(newStartTime)
    }
  }

  const startClock = isTimeDefined(startTimeSinceMidnight)
    ? getClockFromElapsedSecondsFromMidnight(
        startTimeSinceMidnight.hour * 60 ** 2 +
          startTimeSinceMidnight.minute * 60,
        dateEditing,
        timeZone,
      )
    : { hour: null, minute: null }

  const endClock = isTimeDefined(endTimeSinceMidnight)
    ? getClockFromElapsedSecondsFromMidnight(
        endTimeSinceMidnight.hour * 60 ** 2 + endTimeSinceMidnight.minute * 60,
        dateEditing,
        timeZone,
      )
    : { hour: null, minute: null }

  return (
    <form
      className="flex flex-col pt-3 pb-2 bg-white rounded cursor-default"
      onSubmit={handleSubmit}
    >
      <Title date={dateEditing}>{renderTopRight()}</Title>
      <div className="flex flex-row p-4 border-b-2 border-neutral-200 space-x-4">
        <Adjuster
          adjusting="start"
          displayMode={displayMode}
          fieldPrefix={fieldPrefix}
          errors={errors.start}
          clockHour={startClock.hour}
          clockMinute={startClock.minute}
          onChange={handleStartTimeChange}
          autoFocus={autoFocusedAdjuster === 'start'}
          tabIndex={1}
        />
        <Adjuster
          assumePm={displayMode === '12'}
          adjusting="end"
          displayMode={displayMode}
          fieldPrefix={fieldPrefix}
          errors={errors.end}
          clockHour={endClock.hour}
          clockMinute={endClock.minute}
          onChange={handleEndTimeChange}
          autoFocus={autoFocusedAdjuster === 'end'}
          tabIndex={2}
        />
        <Adjuster
          adjusting="duration"
          displayMode="24"
          fieldPrefix={fieldPrefix}
          errors={errors.duration}
          hidden={variant === 'time'}
          clockHour={duration.hour}
          clockMinute={duration.minute}
          onChange={handleDurationChange}
          tabIndex={3}
        />
      </div>
      <div className="flex flex-row justify-between px-4 pt-2">
        <ClearButton onClick={handleClear} />
        <SubmitButton disabled={isInvalid} />
      </div>
    </form>
  )
}
