import { useSearchTasks } from '@hooks/useSearchTasks'
import { noop } from '@tanstack/react-table'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { Label } from '../../components/Label'
import { SearchField } from 'components/form/search-field'
import { useSearchState } from './hooks/useSearchState'
import { ColumnFilter, Column as ColumnType } from './types'

interface Props {
  enableSearchRequest?: boolean
  filter: ColumnFilter
  hidden: boolean
  hideLabel?: boolean
  label: string
  onSkip?: () => void
  onTotalChange?: (total: number) => void
  renderItem: (item: TaskSearchResultItem, cb: () => void) => ReactNode
  showing: Exclude<ColumnType, 'root'>
  skeletonItem: React.ReactNode
  expectedItemHeight: number
}

export const Column: FC<Props> = ({
  enableSearchRequest = false,
  filter,
  hidden,
  hideLabel = false,
  label,
  onTotalChange = noop,
  renderItem,
  showing,
  skeletonItem,
  expectedItemHeight,
}) => {
  const { search, debounced, setSearch, error } = useSearchState(showing)

  const searchResult = useSearchTasks({
    enabled: error === undefined && (!hidden || enableSearchRequest),
    q: debounced,
    resourceType: showing,
    ...filter,
  })

  const items = useMemo(
    () => searchResult.data?.pages.flatMap((page) => page.data) ?? [],
    [searchResult],
  )

  const totalItems = useMemo(
    () => searchResult.data?.pages.at(0)?.meta?.total ?? 0,
    [searchResult],
  )

  useEffect(() => onTotalChange(totalItems ?? 0), [totalItems, onTotalChange])

  if (hidden) return null

  return (
    <>
      <SearchField
        autoFocus={true}
        error={error}
        onChange={setSearch}
        placeholder={`Search ${label}...`}
        value={search}
        className="mx-9"
      />
      {!hideLabel && (
        <Label
          content={label}
          count={totalItems ?? 0}
          hideCount={totalItems === undefined}
          className="mt-4 mb-2 px-9"
        />
      )}
      <InfiniteScrollList
        items={items}
        loadMore={() => void searchResult.fetchNextPage()}
        isFetching={searchResult.isFetchingNextPage || searchResult.isLoading}
        isInitiallyLoading={searchResult.isLoading}
        renderItem={(item) => renderItem(item, () => setSearch(''))}
        expectedItemHeight={expectedItemHeight}
        totalItems={totalItems}
        skeletonItem={skeletonItem}
      />
    </>
  )
}
