import { FC } from 'react'
import { Header } from './Header'
import { Button } from 'components/buttons'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { useAdminProjects } from '@features/admin/hooks/useAdminProjects'
import { Table } from './Table'
import { Spinner } from 'components/loaders'
import { useRefreshWorkdayProjects } from '../hooks/useRefreshWorkdayProjects'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'

export const ProjectsAdmin: FC = () => {
  const { t } = useTranslation()
  const { data: projects } = useAdminProjects()
  const refreshWorkdayProjects = useRefreshWorkdayProjects()
  const modal = useModal()

  const refresh = () => {
    refreshWorkdayProjects.mutate(undefined, {
      onError: () => {
        modal.alert({
          title: t('common.error'),
          content: t(
            'features.projectsAdmin.anErrorWasEncounteredWhileRefreshingProjectsFromWorkday',
          ),
        })
      },
    })
  }

  return (
    <div className="flex flex-col flex-grow">
      <Header
        actionButton={
          <Button onClick={refresh} disabled={refreshWorkdayProjects.isLoading}>
            <FontAwesomeIcon
              icon={faRefresh}
              spin={refreshWorkdayProjects.isLoading}
            />
            {t('features.projectsAdmin.refresh')}
          </Button>
        }
      />

      {projects ? <Table projects={projects} /> : <Spinner />}
    </div>
  )
}
