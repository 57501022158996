import { ReactNode } from 'react'
import { ButtonInput, FilterRenderer } from '../components'
import { EntityPicker as EntityPickerFeature } from 'features/entity-picker'
import { UseQueryResult } from '@tanstack/react-query'
import { twMerge } from 'tailwind-merge'

type Props<T, R extends string | number> = {
  columnNameTranslationKey: TranslationKey
  filter: (entity: T, search: string) => boolean
  label?: string
  placeholder: string
  isSelected: (entity: T, selectedIds: Set<R>) => boolean
  renderNoItemSelected?: () => ReactNode
  renderSearchItem: (entity: T, clickHandler: () => void) => ReactNode
  renderSelectedItem: (entity: T) => ReactNode
  selectedIds: Set<R>
  className?: string
} & (
  | {
      useQuery: () => UseQueryResult<T[], unknown>
    }
  | {
      accessor: (v: T) => R
      values: T[]
    }
)

export const EntityPicker = <T, R extends string | number>({
  columnNameTranslationKey,
  filter,
  label,
  placeholder,
  isSelected,
  renderNoItemSelected,
  renderSearchItem,
  renderSelectedItem,
  selectedIds,
  className,
  ...props
}: Props<T, R>) => {
  return (
    <FilterRenderer
      columnNameTranslationKey={columnNameTranslationKey}
      renderDropdown={() => (
        <div className={twMerge('flex flex-col', className)}>
          <EntityPickerFeature
            filter={filter}
            label={label}
            placeholder={placeholder}
            isSelected={isSelected}
            renderNoItemSelected={renderNoItemSelected}
            renderSearchItem={renderSearchItem}
            renderSelectedItem={renderSelectedItem}
            selectedIds={selectedIds}
            {...props}
          />
        </div>
      )}
      renderTrigger={(clickHandler) => (
        <ButtonInput onClick={clickHandler} selectedCount={selectedIds.size} />
      )}
    />
  )
}
