import { useEntriesSumByTask } from '@features/time-logging/hooks/useEntrySums'
import { secondsToHHMM } from '@utils/time'

const dashedBorders = 'border-t border-dashed border-neutral-300'

interface Props {
  task: Task
  row: number
}

function TaskTotalCell(props: Props) {
  const total = useEntriesSumByTask(props.task.id)
  const toggleBold = total > 0 ? 'font-bold' : ''
  const style = {
    gridRowStart: props.row,
  }

  return (
    <div
      className={`flex items-center justify-end col-start-totalByTask ${dashedBorders} first:border-t-0`}
      style={style}
    >
      <div className={`text-sm text-neutral-900 ${toggleBold}`}>
        {secondsToHHMM(total) || '-'}
      </div>
    </div>
  )
}

export default TaskTotalCell
