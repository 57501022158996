import { Button, Props as ButtonProps } from './Button'
import { twMerge } from 'tailwind-merge'
import { FC } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'components/loaders'
import { useLanguage } from '@hooks/useLanguage'

type ButtonOptions = Omit<ButtonProps, 'variant'> & {
  selected?: boolean
  icon?: IconDefinition
  count?: number
  loading?: boolean
}

interface Props {
  buttons: ButtonOptions[]
  className?: string
}

export const ButtonGroup: FC<Props> = ({ buttons, className }) => {
  const language = useLanguage()

  return (
    <div className={twMerge('flex flex-row max-w-max', className)}>
      {buttons.map(
        (
          { children, className, count, icon, selected, loading, ...props },
          index,
          arr,
        ) => {
          const previousButtonIsSelected = arr[index - 1]?.selected ?? false
          const nextButtonIsSelected = arr[index + 1]?.selected ?? false

          return (
            <Button
              className={twMerge(
                'border-r border-l-0 first:border-l rounded-none last:rounded-r-full first:rounded-l-full px-2 first:pl-3 last:pr-3',
                !selected && nextButtonIsSelected && 'border-r-0',
                selected && !previousButtonIsSelected && 'border-l',
                className,
              )}
              key={index}
              variant={selected ? 'outlined-active' : 'outlined'}
              tooltipProps={{ delayShow: 1500 }}
              {...props}
            >
              {children}
              {icon && (
                <FontAwesomeIcon icon={icon} fixedWidth={true} size="sm" />
              )}
              {(loading || count !== undefined) && (
                <span
                  className={twMerge(
                    'px-1 py-0 font-bold border rounded-full bg-neutral-200 text-[10px] border-neutral-300',
                    selected && 'border-primary-300 bg-primary-200',
                  )}
                >
                  {loading ? <Spinner /> : count?.toLocaleString(language)}
                </span>
              )}
            </Button>
          )
        },
      )}
    </div>
  )
}
