import { FC } from 'react'
import { Field } from '../components/Field'
import { Layout } from '../components/Layout'
import { useSettingsOutletContext } from './Settings'
import { Selection } from '../components/Selection'
import { useTranslation } from 'react-i18next'
import { DefaultWorkWeek } from '../components/time-logging/DefaultWorkWeek'
import { UserSelection } from '@features/user-selection'

export const TimeLogging: FC = () => {
  const { changeValues, dirty, pending, save, values } =
    useSettingsOutletContext()

  const { t } = useTranslation()

  return (
    <Layout
      title={t('features.settings.timeLogging.timeLoggingPreferences')}
      subtitle={t('features.settings.customizeIndividualSettingsBelow')}
      onSaveButtonClick={save}
      saveButtonDisabled={!dirty || pending}
    >
      <Field
        className="mb-8"
        description={t(
          'features.settings.timeLogging.taskSelectionsWillBeAutomaticallyCopied',
        )}
        label={t('features.settings.timeLogging.copyTaskSelections')}
      >
        <Selection
          checked={values.copyTaskSelectionsEachWeek === true}
          disabled={pending}
          label={t(
            'features.settings.timeLogging.taskSelectionsWillCarryoverWeekToWeek',
          )}
          onChange={(checked) =>
            changeValues({ copyTaskSelectionsEachWeek: checked })
          }
          type="checkbox"
        />
      </Field>

      <Field
        description={t(
          'features.settings.timeLogging.configureDefaultTimeCards',
        )}
        label={t('features.settings.timeLogging.defaultWorkWeek')}
      >
        {values.defaultWorkWeek && (
          <DefaultWorkWeek
            value={values.defaultWorkWeek}
            onChange={(value) => changeValues({ defaultWorkWeek: value })}
          />
        )}
      </Field>

      <Field
        className="pt-8 border-t border-neutral-300 mb-60" // Bottom margin ensures we have enough room to expand Approval delegates picker
        description={t(
          'features.settings.timeLogging.useSearchFieldToDelegate',
        )}
        label={t('features.settings.timeLogging.delegation')}
      >
        <div className="flex flex-col min-w-[400px]">
          <UserSelection
            className="pb-8 border-b border-neutral-300"
            label={t('common.timeLogging')}
            placeholder={t('common.searchByName')}
            selectedUserIds={new Set(values.timeLoggingDelegateIds)}
            includeSelf={false}
            onSelect={(user) =>
              changeValues({
                timeLoggingDelegateIds: [
                  ...(values.timeLoggingDelegateIds || []),
                  user.id,
                ],
              })
            }
            onRemove={(user) =>
              changeValues({
                timeLoggingDelegateIds: values.timeLoggingDelegateIds?.filter(
                  (id) => id !== user.id,
                ),
              })
            }
          />
          <UserSelection
            className="pt-8"
            label={t('common.approvals')}
            placeholder={t('common.searchByName')}
            selectedUserIds={new Set(values.approvalDelegateIds)}
            includeSelf={false}
            onSelect={(user) =>
              changeValues({
                approvalDelegateIds: [
                  ...(values.approvalDelegateIds || []),
                  user.id,
                ],
              })
            }
            onRemove={(user) =>
              changeValues({
                approvalDelegateIds: values.approvalDelegateIds?.filter(
                  (id) => id !== user.id,
                ),
              })
            }
          />
        </div>
      </Field>
    </Layout>
  )
}
